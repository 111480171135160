<div id="widget" :data-bs-theme="resourceId">
	<!--	<header class="header-container header-image" v-if="resourceId"-->
	<!--			:style="{ 'background-image': 'url(/assets/img/custom/' + resourceId + '/header/menu.jpg)' }">-->
	<!--		<div class="header-content">-->
	<!--			<h1 class="light"><br>EBITDATA</h1>-->
	<!--		</div>-->
	<!--	</header>-->
	<div class="my-3 py-2"></div>

	<HeaderComponent text-title="Ebitdata" :subTitle="businessName" :image-restaurant="resourceId"/>

	<nav class="navbar navbar-custom">
		<div class="container-fluid">
			<a  href="https://wewelcom.io/" target="_blank"><img  src="https://dev.wewelcom.io/world/img/wewelcom/logo/logo.png" alt="" class="logo w-auto"></a>
		</div>
	</nav>
	<section class="section-not-rounded section-primary position-sticky" style="top: 56px;z-index: 1;">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col col-md-6">
					<div class="date-picker__container w-auto">
						<button class="btn btn-secondary btn-rounded" @click="subtractOneDay"><i class="fas fa-angle-left"></i>
						</button>
						<DatePicker :store-module="'ebitdata'"/>
						<button class="btn btn-secondary btn-rounded" @click="addOneDay"><i class="fas fa-angle-right"></i></button>
					</div>
				</div>
			</div>
		</div>
	</section>

	<div class="container">
		<section>
			<!--			<div class="btns-group_container row">-->
			<!--				<div class="col">-->
			<!--					<button class="btn btn-sm-square w-100 dropdown-toggle" type="button" data-bs-toggle="dropdown"-->
			<!--							aria-expanded="false">Todas las horas-->
			<!--					</button>-->
			<!--					<ul class="dropdown-menu">-->
			<!--						<li>-->
			<!--							<button class="active dropdown-item">Todas las horas</button>-->
			<!--						</li>-->
			<!--						<li>-->
			<!--							<button class="dropdown-item">Comida</button>-->
			<!--						</li>-->
			<!--						<li>-->
			<!--							<button class="dropdown-item">Cena</button>-->
			<!--						</li>-->
			<!--					</ul>-->
			<!--				</div>-->
			<!--			</div>-->

			<!--			<div class=" in-list-header-navbar">-->
			<!--				<button class="btn btn-filter" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"-->
			<!--						aria-controls="offcanvasExample">-->
			<!--					Ventas-->
			<!--				</button>-->
			<!--				<button class="btn btn-filter" type="button">-->
			<!--					Tiempos-->
			<!--				</button>-->
			<!--				<button class="btn btn-filter" type="button" data-bs-toggle="offcanvas" href="#offcanvas-walkin"-->
			<!--						role="button" aria-controls="offcanvas-walkin">-->
			<!--					Clientes-->
			<!--				</button>-->
			<!--			</div>-->
			<div class="row g-1">
				<div class="col-12">
					<h3>Reservas</h3>
				</div>
				<div v-for="type in reservationTypes" :key="type.key" class="col-4 col-md-3 col-lg">
					<div class="card-ebitdata" :class="type.class">
						<div class="card-ebitdata-title">{{ type.title }}</div>
						<div class="card-ebitdata-information">{{ this[type.key] && this[type.key].totalReservations ? this[type.key].totalReservations.length : 0 }}</div>
						<div class="card-ebitdata-sub-information">Pax {{ this[type.key] && this[type.key].totalPax ? this[type.key].totalPax : 0 }}</div>
					</div>
				</div>
			</div>
		</section>
	</div>

	<div class="container">
		<div class="row row-cols-1 row-cols-md-2">
			<!--	Datos Reales	-->
			<div class="col">
				<section class="ebitdata-chart">
					<v-chart :option="chartOptionsReservationRoomNameTicketMiddle" theme="weltheme" autoresize/>
				</section>
			</div>
			<!--	Datos Reales	-->
			<div class="col">
				<section class="ebitdata-chart">
					<v-chart :option="reservationStatusWeek" theme="weltheme" autoresize/>
				</section>
			</div>

<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionChannelNestPie" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart" style="height: 600px">-->
<!--							<v-chart :option="optionSalesEvoStacked" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						&lt;!&ndash;			<section class="secction-color-primary">&ndash;&gt;-->
<!--						&lt;!&ndash;				<div class="container">&ndash;&gt;-->
<!--						&lt;!&ndash;					<div class="date-picker__container m-0">&ndash;&gt;-->
<!--						&lt;!&ndash;						<button class="btn btn-sm-square" @click="subtractOneDay"><i class="fas fa-angle-left"></i>&ndash;&gt;-->
<!--						&lt;!&ndash;						</button>&ndash;&gt;-->
<!--						&lt;!&ndash;						<div id="input-date" class="date-picker__content">&ndash;&gt;-->
<!--						&lt;!&ndash;							<div>Semana en curso</div>&ndash;&gt;-->
<!--						&lt;!&ndash;						</div>&ndash;&gt;-->
<!--						&lt;!&ndash;						<button class="btn btn-sm-square" @click="addOneDay"><i class="fas fa-angle-right"></i></button>&ndash;&gt;-->
<!--						&lt;!&ndash;					</div>&ndash;&gt;-->
<!--						&lt;!&ndash;				</div>&ndash;&gt;-->
<!--						&lt;!&ndash;			</section>&ndash;&gt;-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="weeklyReservations" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionTicketGauge" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionMinAvgMax" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionChannelAvgTicketBar" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionCheckinHeatmap" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionContactPie" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionFrequencyEvo" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
<!--					<div class="col">-->
<!--						<section class="ebitdata-chart">-->
<!--							<v-chart :option="optionEvoQr" theme="weltheme" autoresize/>-->
<!--						</section>-->
<!--					</div>-->
		</div>
	</div>
	<FooterWelcom/>
</div>

<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="menu-modal-assessment">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">
				<i class="fa-solid fa-receipt"></i> {{ titleOffcanvas }}
			</div>

			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-3" id="body-menu-modal-assessment">
			<div v-if="isSentProcessPay">
				<p class="text-center">¡Tu opinión nos ayuda a crecer! Valora tu experiencia antes de pedir la cuenta.</p>

				<section v-if="showStep1" class="text-center">
					<div class="star-rating mb-2">
						<input type="radio" id="star5" name="rating" value="5" @change="handleRatingSelection">
						<label for="star5" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star4" name="rating" value="4" @change="handleRatingSelection">
						<label for="star4" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star3" name="rating" value="3" @change="handleRatingSelection">
						<label for="star3" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star2" name="rating" value="2" @change="handleRatingSelection">
						<label for="star2" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star1" name="rating" value="1" @change="handleRatingSelection">
						<label for="star1" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
					</div>
					<div class="rating-feedback"><small>(1 significa mal y 5 excelente)</small></div>
				</section>

				<section v-if="showStep2" key="step2" id="step2">
						<div class="container">
							<div class="row">
								<div class="col-10 fw-bold" data-bs-toggle="collapse" href="#collapse-method-pay" role="button" aria-expanded="false" aria-controls="collapseExample">
								1.	Selecciona tu método de pago
								</div>
								<div class="col-2">
									<div v-if="!isCollapseShowStep2" class="w-100" @click="resetAll">
										<span class="rating-feedback"><small>Volver</small></span>
									</div>
								</div>
							</div>
							<div class="collapse show mt-3" id="collapse-method-pay">
								<div class="row g-3">
									<div class="col-12">
										<button class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedLocalPayCash">
											<template v-if="isLoadingLocalPayCash">
												Procesando solicitud... <SpinnerIcon />
											</template>
											<template v-else>
												{{ textSelectedLocalPayCash }}
											</template>
										</button>
									</div>
									<div class="col-12">
										<button class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedLocalPayCard">
											<template v-if="isLoadingLocalPayCard">
												Procesando solicitud... <SpinnerIcon />
											</template>
											<template v-else>
												{{ textSelectedLocalPayCard }}
											</template>
										</button>
									</div>
									<div class="col-12">
										<button disabled class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedMobilePay" v-html="textSelectedMobilePay">
										</button>
									</div>
								</div>
							</div>
						</div>
					</section>

				<div v-if="showStep3" key="step3" id="step3">
						<section>
							<div >
								<div class="fw-bold" data-bs-toggle="collapse" href="#list-products-historic" role="button" aria-expanded="false" aria-controls="collapseExample">
									Lista de productos <span class="rating-feedback"><small>(mostrar más)</small></span>
								</div>

								<div class="vr-horizontal"></div>

								<div class="orders-history bg-transparent collapse" id="list-products-historic">
									<div class="products-list-content mt-3">
										<OrdersProductCard source="account" :order="order" v-for="order in historicProducts" :key="order.cartSessionOrderId"></OrdersProductCard>
									</div>
								</div>

								<div class="fw-bold d-flex justify-content-between" >
									Subtotal <span>35$</span>
								</div>
								<div class="fw-bold d-flex justify-content-between" >
									IVA <span>35$</span>
								</div>
								<div class="fw-bold d-flex justify-content-between" >
									Total <span>35$</span>
								</div>
							</div>
						</section>

						<section v-if="false">
							<div class="container">
								<div class="row g-3">
									<div class="col-12 fw-bold">
										¿Quieres añadir una propina?
									</div>

									<!-- Tarjeta 1 (5%) -->
									<div class="col-3">
										<div class="card h-100 mb-0" :class="{'active': selectedCard === 1}" @click="selectCard(1)">
											<div class="card-body p-1">
												<div>(5%)</div>
												<b>1.5$</b>
											</div>
										</div>
									</div>

									<!-- Tarjeta 2 (10%) -->
									<div class="col-3">
										<div class="card h-100 mb-0" :class="{'active': selectedCard === 2}" @click="selectCard(2)">
											<div class="card-body p-1">
												<div>(10%)</div>
												<b>3.0$</b>
											</div>
										</div>
									</div>

									<div class="col-3">
										<div class="card h-100 mb-0" :class="{'active': selectedCard === 3}" @click="selectCard(3)">
											<div class="card-body p-1">
												<div>(5%)</div>
												<b>1.5$</b>
											</div>
										</div>
									</div>

									<!-- Tarjeta editable -->
									<div class="col-3">
										<div
											class="card h-100 mb-0 text-black-50 p-1 d-flex justify-content-center align-items-center"
											contenteditable="true"
											@focus="clearPlaceholder"
											@blur="setPlaceholder"
											@input="saveEditableContent"
											ref="editableDiv"
											:class="{'active': selectedCard === 4}"
											@click="selectCard(4)"
										>
											{{ placeholderText }}
										</div>
									</div>
								</div>
							</div>
						</section>

						<section>
							<div class="container">
								<div class="row g-3">
									<div class="col-12 fw-bold">
										Tus datos personales
									</div>
									<div class="col-12">
										<div class="input-group">
											<span class="input-group-text" id="basic-addon1">@</span>
											<input type="text" class="form-control" placeholder="Ingresa tu email" aria-label="Email" aria-describedby="basic-addon1">
										</div>
									</div>

									<div class="col-12">
										<div id="signupTermsSection" class="form-check">
											<input id="signup-terms" class="form-check-input" type="checkbox" value="signup-terms">
											<label class="form-check-label" for="signup-terms">
												Acepto la <a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="link link-primary" href="#termsPrivacy">política de privacidad</a>
											</label>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
			</div>
			<section v-else>
				<div v-if="isActiveReservation" class="container">
					<p class="text-center text-md-start">
						<b>¡Gracias por tu visita!</b> En breve, un miembro de nuestro equipo se acercará para completar el pago.
					</p>


					<div class="mb-3" v-if="userReview && userReview.score > 4">
						<p class="text-center text-md-start">
							te invitamos a compartir tu opinión en Google.
						</p>

						<button @click="openGoogle" class="btn btn-primary w-100">
							<GoogleIcon/>
							Valorar en google
						</button>
					</div>

					<div class="text-center text-md-start">
						<b>¡Esperamos verte pronto nuevamente!</b>
					</div>
				</div>
				<div v-else class="container">
					<p class="text-center text-md-start">
						¡Gracias por valorar nuestro servicio!
						<span v-if="userReview && userReview.score > 4">Nos alegra saber que disfrutaste de tu experiencia.</span>
					</p>

					<div class="mb-3" v-if="userReview && userReview.score > 4">
						<p class="text-center text-md-start">
							te invitamos a compartir tu opinión en Google.
						</p>

						<button @click="openGoogle" class="btn btn-primary w-100">
							<GoogleIcon/>
							Valorar en google
						</button>
					</div>

					<div class="text-center text-md-start">
						<b>Hazte con tu WePass.</b> Disfruta sin esperas, pide desde tu móvil y personaliza la carta a tu manera. ¡Tu experiencia más rápida y cómoda está a un clic!
					</div>
				</div>
			</section>
		</div>
		<div class="offcanvas-footer" v-if="false">
			<button :class="{'disabled': !isActiveBtnAssessment}" class="btn btn-primary w-100" @click="handleClickBtnAssessment">
				<span v-html="texBtnAssessment"></span>
			</button>
		</div>
	</div>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import StarIcon from "@/core/icons/starIcon";
import GoogleIcon from "@/core/icons/googleIcon";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'menuOffcanvasAssessment',
	inject: {
		menuRepository: 'menuRepository',
	},
	props: {
		reservationInfo: {
			type: Object,
			required: true
		},
		historicProducts: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isSentProcessPay: true,

			comment: "",
			selectedRating:null,

			showStep1: true,
			showStep2: false,
			showStep3: false,

			isCollapseShowStep2: true,

			texBtnAssessment: 'Pedir la cuenta',

			textSelectedLocalPayCash: 'Pagar en efectivo',
			isLoadingLocalPayCash: false,

			textSelectedLocalPayCard: 'Pagar con tarjeta',
			isLoadingLocalPayCard: false,

			textSelectedMobilePay: 'Pagar online (no disponible)',
			isLoadingMobilePay: false,


			isActiveBtnAssessment: false,
			clickBtn: '',

			placeholderText: 'Dejar propina',
			isPlaceholderVisible: true,
			savedContent: '',

			selectedCard: null,
		};
	},
	components: {GoogleIcon, OrdersProductCard, StarIcon, SpinnerIcon},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['reviewGoogleMapsUrl']),
		isActiveReservation() {
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
		userReview() {
			return this.reservationInfo.userReviews?.find(review => this.user.id === review.userId);
		},
		titleOffcanvas(){
			return this.isActiveReservation ? 'Pedir la cuenta' : 'Enviar valoración'
		},
	},
	mounted() {
	},
	methods: {
		async handleRatingSelection(event) {
			this.selectedRating = event.target.value;

			console.log(this.selectedRating, this.reservationInfo.id, this.user.id);

			if(!this.isActiveReservation){
				const data = {reservationWid: this.reservationInfo.id, userId: this.user.id, score: this.selectedRating}
				await this.menuRepository.sendReview(data);
				this.isSentProcessPay = false
			}

			await this.stepsToFollow(2)
			await this.scrollToStep('step2')
		},
		async handleSendComment() {
			const data = {reviewId: this.userReview.reviewId, reviewText: this.comment}
			console.log(data, 'desde poner el comenario')
			await this.menuRepository.addTextToReview(data);
		},

		scrollToStep(id) {
			const step2Element = document.getElementById(id);
			const offcanvasBody = document.getElementById('body-menu-modal-assessment');

			if (step2Element && offcanvasBody) {
				const step2Position = step2Element.offsetTop;
				offcanvasBody.scrollTo({
					top: step2Position,
					behavior: 'smooth'
				});
			}
		},
		async handleAskAccount(paymentMethod) {
			const data = {reservationWid: this.reservationInfo.id, userId: this.user.id, score: this.selectedRating}

			await this.menuRepository.sendReview(data);

			if (this.isActiveReservation) {

				await store.dispatch('menu/requestAccount', {reservationInfo: this.reservationInfo, user: this.user, paymentMethod: paymentMethod})
				this.titleOffcanvas = 'Has pedido la cuenta'
				this.isSentProcessPay = false
			}
			this.titleOffcanvas = 'Valoración enviada'
		},
		async handleClickBtnAssessment() {
			if (this.clickBtn === 'selectedLocalPay') {
				await this.handleAskAccount(0)
			}

			if (this.clickBtn === 'selectedMobilePay') {
				await this.selectedMobilePay()
			}
		},

		stepsToFollow(step) {
			this[`showStep${step}`] = true;
		},

		async selectedLocalPayCash() {
			this.texBtnAssessment = 'Pedir la cuenta';
			this.isActiveBtnAssessment = true;

			this.clickBtn = 'selectedLocalPayCash';

			this.isLoadingLocalPayCash = true;
			await this.handleAskAccount(1)

			this.textSelectedLocalPayCash = 'Pago con tarjeta / efectivo';
			this.isLoadingLocalPayCash = false;
		},
		async selectedLocalPayCard() {
			this.texBtnAssessment = 'Pedir la cuenta';
			this.isActiveBtnAssessment = true;

			this.clickBtn = 'selectedLocalPayCard';

			this.isLoadingLocalPayCard = true;
			await this.handleAskAccount(2)

			this.textSelectedLocalPayCard = 'Pago con tarjeta / efectivo';
			this.isLoadingLocalPayCard = false;
		},
		async selectedMobilePay() {
			this.texBtnAssessment = 'Proceder con el pago';
			this.isActiveBtnAssessment = false;
			this.clickBtn = 'selectedMobilePay';
			const idElement = document.getElementById('collapse-method-pay')

			if (idElement.classList && idElement.classList.contains('show')) {
				idElement.classList.remove('show');
			}
			this.isCollapseShowStep2 = false;
			await this.stepsToFollow(3)
			await this.scrollToStep('step3')
		},

		clearPlaceholder() {
			if (this.isPlaceholderVisible) {
				this.$refs.editableDiv.innerText = '';
				this.isPlaceholderVisible = false;
				this.$refs.editableDiv.focus();
			}
		},
		setPlaceholder() {
			const content = this.$refs.editableDiv.innerText.trim();
			if (!content) {
				this.$refs.editableDiv.innerText = this.placeholderText;
				this.isPlaceholderVisible = true;
			}
		},
		saveEditableContent(event) {
			const content = event.target.innerText.trim();
			this.savedContent = content;
		},
		selectCard(cardIndex) {
			this.selectedCard = cardIndex;
		},
		openGoogle() {
			const data = {reviewId: this.userReview.reviewId, clickedSocial: 'googleReviewImg'}
			this.menuRepository.registerClickReview(data)
			window.open(this.reviewGoogleMapsUrl, '_blank');
		},
		resetAll() {
			const idElement = document.getElementById('collapse-method-pay')

			if (idElement.classList) {
				idElement.classList.add('show');
			}
			this.showStep1 = true
			this.showStep3 = false;
			this.isCollapseShowStep2 = true;
		}
	}
}
</script>

import {menuRepository} from "@/service/repository/menuRepository";
import {arraysAreEqualIgnoringOrder, normalizeChoices} from "@/utils";
import store from "@/store/index";
import {notificationRepository} from "@/service/repository/notificationRepository";

const state = {
	menuProducts: [],
	menus: [],
	productsCart: []
};

const getters = {
	menusVenue: state => {
		return state.menus.map(menuItem => {
			// Convertir menuCategories a un array si es un objeto
			const categories = Array.isArray(menuItem.menuCategories)
				? menuItem.menuCategories
				: [menuItem.menuCategories]; // Convertir a array si es un objeto

			return {
				...menuItem,
				menuCategories: categories.map(category => {
					// Convertir products a un array si es un objeto
					const products = Array.isArray(category.products)
						? category.products
						: category.products ? Object.values(category.products) : []; // Convertir a array si es un objeto

					return {
						...category,
						products: products.map(product => {
							// Convertir productOptions a un array si es un objeto
							const productOptionsArray = Array.isArray(product.productOptions)
								? product.productOptions
								: product.productOptions ? Object.values(product.productOptions) : []; // Convertir a array si es un objeto

							return {
								...product,
								productOptions: productOptionsArray.map(productOption => {
									// Si options es un array, filtrar las opciones
									const filteredOptions = Array.isArray(productOption.options)
										? productOption.options.filter(option => option.enabled >= 1) // Filtrar opciones
										: []; // Si options no es un array, devolvemos un array vacío

									return {
										...productOption,
										options: filteredOptions
									};
								})
							};
						})
					};
				})
			};
		});
	},
	menuProducts: state => {
		return state.menus.flatMap(menu => {
			// Convertir menuCategories a un array si es un objeto
			const categories = Array.isArray(menu.menuCategories)
				? menu.menuCategories
				: [menu.menuCategories];  // Envuelve el objeto en un array

			return categories.flatMap(category => {
				// Convertir products a un array si es un objeto
				const products = Array.isArray(category.products)
					? category.products
					: category.products ? Object.values(category.products) : [];  // Convierte el objeto a array si no es nulo

				// Filtrar los productos cuya propiedad isMenu sea "SI"
				return products.filter(product => product.isMenu === "SI");
			});
		});
	},
	productsCart: state => {
		return state.productsCart
	}
};

const mutations = {
	setMenuProducts(state, payload) {
		console.log("Quiero asignar los menuproducts", payload.menuProducts)
		state.menuProducts = payload.menuProducts;
	},
	setMenus(state, payload) {
		console.log("Quiero asignar los menus", payload.menus)
		state.menus = payload.menus;
	},
	setProductsCart(state, payload) {
		state.productsCart = payload.productsCart;
	},
	addProductToCart(state, payload) {
		let units = payload.units
		const normalizedOptions = normalizeChoices(payload.options);
		const isIncluded = state.productsCart.some(productCart =>
			productCart.sku === payload.product.sku &&
			arraysAreEqualIgnoringOrder(productCart.chosenOptions, normalizedOptions)
		);

		payload.product.isMenuIncluded = false;
		if (payload.product.isUnitary === 'SI') {
			units = payload.product.servingQtyMin;
		}

		if (!isIncluded) {
			units ? payload.product.cartItemCount = units : payload.product.cartItemCount = 1;
			const newProductsCart = [...state.productsCart, {
				...payload.product,
				chosenOptions: normalizedOptions,
				cartItemCount: units || 1
			}];
			state.productsCart = newProductsCart;
		} else {
			const existingProduct = state.productsCart.find(productCart =>
				productCart.sku === payload.product.sku &&
				arraysAreEqualIgnoringOrder(productCart.chosenOptions, normalizedOptions)
			);
			units = payload.product.unitaryIncrement;
			existingProduct.cartItemCount += units || 1;
		}
	},
	addUnit(state, payload) {
		const normalizedChosenOptions = normalizeChoices(payload.product.chosenOptions);
		console.log('Adding unit for product:', payload.product.sku, 'with options:', normalizedChosenOptions);

		const productCart = state.productsCart.find(productCart =>
			productCart.sku === payload.product.sku &&
			arraysAreEqualIgnoringOrder(normalizeChoices(productCart.chosenOptions), normalizedChosenOptions)
		);

		if (!productCart) {
			console.error('Product not found in cart:', payload.product);
			return;
		}

		if (productCart.isUnitary === 'SI') {
			productCart.cartItemCount += payload.product.unitaryIncrement || 1;
		} else {
			productCart.cartItemCount++;
		}
	},
	subtractUnit(state, payload) {
		const normalizedChosenOptions = normalizeChoices(payload.product.chosenOptions);

		const productCart = state.productsCart.find(productCart => {
			const normalizedProductCartChosenOptions = normalizeChoices(productCart.chosenOptions);
			const isMatching = productCart.sku === payload.product.sku &&
				arraysAreEqualIgnoringOrder(normalizedProductCartChosenOptions, normalizedChosenOptions);
			console.log('Comparing products for subtract:', productCart, payload.product, 'isMatching:', isMatching, 'normalizedProductCartChosenOptions:', normalizedProductCartChosenOptions, 'normalizedChosenOptions:', normalizedChosenOptions);
			return isMatching;
		});

		if (!productCart) {
			console.error('Product not found in cart:', payload.product);
			return;
		}

		const valueDecrement = payload.product.unitaryIncrement || 1;
		const element = document.getElementById(payload.product.sku);
		if (valueDecrement === 0) {
			element.classList.remove('addProduct');
		}

		if (productCart.cartItemCount === 1 || productCart.cartItemCount === productCart.servingQtyMin) {
			const newProductsCart = state.productsCart.filter(productCart =>
				productCart.sku !== payload.product.sku ||
				!arraysAreEqualIgnoringOrder(normalizeChoices(productCart.chosenOptions), normalizedChosenOptions));
			store.commit('menu/setProductsCart', {productsCart: newProductsCart})
		} else {
			productCart.cartItemCount -= valueDecrement;
		}
	},
};

const actions = {
	async loadMenus({commit}) {
		try {
			const menusProductResult = await menuRepository.getAvailableMenus();
			if (menusProductResult.result === 0) {
				console.log('menús del día desde la carga inicial', menusProductResult.menus);
				commit('setMenus', {menus: menusProductResult.menus});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async loadMenuProducts({commit}) {
		try {
			const menuProductResult = await menuRepository.getAvailableMenuProducts();
			if (menuProductResult.result === 0) {
				console.log('menús del día desde la carga inicial de copilot', menuProductResult.menuProducts);
				commit('setMenuProducts', {menuProducts: menuProductResult.menuProducts});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async updateMenuProduct(payload) {
		try {
			const updateMenuProductResult = await menuRepository.updateMenuProduct(payload);
			if (updateMenuProductResult.result === 0) {
				console.log('Menú actualizado correctamente');
			}
		} catch (error) {
			console.error("Error al actualizar un producto menú", error);
		}
	},

	async requestAccount({ commit }, payload){
		const reservationInfo = payload.reservationInfo
		const user = payload.user;
		const paymentMethod = payload.paymentMethod;

		console.log(user, reservationInfo, ' desde el menu module')
		const notification = {
			"status": 0,
			"type": 4,
			"subtype": 0,
			"target": user.name,
			"paymentMethod": paymentMethod
		};

		const data = {
			"pax": reservationInfo.pax,
			"reservationId": reservationInfo.id,
			"reservationName": user.name,
			"uid": user.hostCode,
		};


		const resultTicket = await notificationRepository.requestTicket({
			notification: notification,
			data: data
		})

		if (resultTicket.result === 0) {
			commit('setProductsCart', {productsCart: []})
		}

		return resultTicket;
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};

import {DateTime} from 'luxon';
import {reservationRepository} from "@/service/repository/reservationRepository";
import {copilotRepository} from "@/service/repository/copilotRepository";
import {groupItemsBySkuAndChoices, hideAllOffcanvas} from "@/utils";
import {menuRepository} from "@/service/repository/menuRepository";

const state = {
	selectedDate: DateTime.now().toISODate(),
	availableRoles: [],
	selectedRole: '0',
	reservations: [],
	notifications: [],
	accounts: [],
	assistantNotifications: [],
	isPlayNotificationSound: false,
	isLoadingGetReservations: false,
	isShowToastScanQr: null,
	isShowModalScanQr: null,
	toast: {
		showToast: false,
		toastMessage: "",
		idToast: ""
	},
	newNotification: null,
	commanderReservation: null,
	notificationMode: "0",
	pendingNotifications: [],
	tables: [],
	reservationId: null,
	hotTable: [],
	serviceEvents: [],
	selectedProductOrdersToServe: [],
	selectedProductOrdersToPrepared: []

};

const getters = {
	selectedProductOrdersToServe: state => {
		return state.selectedProductOrdersToServe;
	},
	selectedProductOrdersToPrepared: state => {
		return state.selectedProductOrdersToPrepared;
	},
	hotTable: state => {
		return state.hotTable;
	},
	selectedDate: state => {
		return state.selectedDate;
	},
	showTicketRequestNotifications: (state, getters) => {
		console.log("El rol seleccionado ", state.selectedRole);

		const selectedRoleObject = getters.getSelectedRole;

		console.log("Objeto del rol seleccionado", selectedRoleObject);

		// Assuming isKitchenSelectedRole is also a getter
		return !getters.isKitchenSelectedRole;// Esto no lo tengo tan seguro porque el del TPV imprime el ticket de una cuenta || !selectedRoleObject?.name.includes("TPV + Barra");
	},
	getSelectedRole: state => {
		if (!state || !state.availableRoles || state.availableRoles.length === 0) {
			return null;
		}
		return state.availableRoles.find(role => role.id === state.selectedRole);
	},
	isKitchenSelectedRole: state => {
		const kitchenRoleObject = state.availableRoles.find(role => role.name === 'Cocina');
		const kitchenRole = kitchenRoleObject ? kitchenRoleObject.id : null;
		return state.selectedRole === kitchenRole;
	},
	isOnlyRoom: state => {
		const onlyRoomRoleObject = state.availableRoles.find(role => role.name === '1 en Sala');
		const onlyRole = onlyRoomRoleObject ? onlyRoomRoleObject.id : null;
		return state.selectedRole === onlyRole;
	},
	pendingOrders: (state, getters) => {
		const statusCartSessionRoleTypes = getters.isKitchenSelectedRole ? [2] : [1, 3];
		const orders = state.reservations
			.flatMap(reservation => reservation.cartSession ? reservation.cartSession.productOrders : [])
			.filter(order => order && statusCartSessionRoleTypes.includes(order.cartSessionStatus))
			.map(order => {
				if (order.cartSessionStatus > 1) {
					return {
						...order,
						items: order.items.filter(item => item.type === 'Comida')
					};
				}
				return order;
			})
			.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime))

		// Group items by SKU and choices
		const groupedOrders = orders.map(order => ({
			...order,
			items: groupItemsBySkuAndChoices([order])
		}));

		console.log("PENDINGORDERS", groupedOrders)
		return groupedOrders;
	},
	allOrderProducts(state) {
		const allOrderProducts = state.reservations
			.flatMap(reservation => reservation.cartSession ? reservation.cartSession.productOrders : [])
			.filter(order => order)
			.flatMap(order => {
				return order.items.map(item => ({
					...item,
					createdDateTime: order.createdDateTime
				}));
			})
			.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime))


		return allOrderProducts;
	},
	availableRoles: state => {
		return state.availableRoles;
	},
	selectedRole: state => {
		return state.selectedRole;
	},
	reservations: state => {
		return state.reservations.slice().sort((a, b) => {
			return new Date(a.startDateTime) - new Date(b.startDateTime);
		});
	},
	notifications: state => {
		return state.notifications;
	},
	accounts: state => {
		return state.accounts;
	},
	assistantNotifications: state => {
		return state.assistantNotifications;
	},
	isPlayNotificationSound: state => {
		return state.isPlayNotificationSound;
	},
	isLoadingGetReservations: state => {
		return state.isLoadingGetReservations;
	},
	isShowToastScanQr: state => {
		return state.isShowToastScanQr;
	},
	toast: state => {
		return state.toast;
	},
	newNotification: state => {
		return state.newNotification;
	},
	menusVenue: state => {
		return state.venueMenus;
	},
	commanderReservation: state => {
		return state.commanderReservation;
	},
	notificationMode: state => {
		return state.notificationMode;
	},
	pendingNotifications: state => {
		return state.pendingNotifications;
	},
	tables: state => {
		return state.tables;
	},
	reservationId(state) {
		return state.reservationId;
	},
	reservationsWithProducts: (state) => {
		return state.reservations
			.filter(reservation => reservation.status > 0 )
			.map(reservation => ({
				...reservation,
				orderedItems: reservation.cartSession
					? reservation.cartSession.productOrders.flatMap(order =>
						order.items.map(item => ({
							...item,
							createdDateTime: order.createdDateTime,
							table: reservation.table,
						}))
					)
					: []
			}))
			.sort((a, b) => {
				const minDateA = a.orderedItems
					.filter(item => item.statusProductOrder < 100)
					.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
					.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible

				const minDateB = b.orderedItems
					.filter(item => item.statusProductOrder < 100)
					.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
					.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible

				return minDateA - minDateB;
			});
	},
	// reservationsWithProducts: (state) => {
	// 	return state.reservations
	// 		.filter(reservation =>
	// 			reservation.status > 0 &&
	// 			reservation.cartSession &&
	// 			reservation.cartSession.productOrders.some(order => order.cartSessionStatus === 1) // Filtrar por cartSessionStatus
	// 		)
	// 		.map(reservation => ({
	// 			...reservation,
	// 			orderedItems: reservation.cartSession
	// 				? reservation.cartSession.productOrders
	// 					.filter(order => order.cartSessionStatus === 1) // Solo incluir productOrders con cartSessionStatus === 1
	// 					.flatMap(order =>
	// 						order.items.map(item => ({
	// 							...item,
	// 							createdDateTime: order.createdDateTime
	// 						}))
	// 					)
	// 				: []
	// 		}))
	// 		.sort((a, b) => {
	// 			const minDateA = a.orderedItems
	// 				.filter(item => item.statusProductOrder < 100)
	// 				.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
	// 				.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible
	//
	// 			const minDateB = b.orderedItems
	// 				.filter(item => item.statusProductOrder < 100)
	// 				.map(item => new Date(item.createdDateTime.replace(' ', 'T')))
	// 				.reduce((min, current) => current < min ? current : min, new Date(8640000000000000)); // Máxima fecha posible
	//
	// 			return minDateA - minDateB;
	// 		});
	// }
	serviceEvents: state => {
		return state.serviceEvents
	},
	serviceEventPendingService(state) {
		return state.serviceEvents
			.filter(serviceEvent => serviceEvent.serviceEventStatus !== 100)
			.map(serviceEvent => {
				const selectedRoleObject = state.availableRoles.find(role => role.id === state.selectedRole);

				const servesDrinks = selectedRoleObject?.notificationTypes
					? selectedRoleObject.notificationTypes.some(notificationType => notificationType.name === "order_ready_drinks")
					: false;

				const servesFood = selectedRoleObject?.notificationTypes
					? selectedRoleObject.notificationTypes.some(notificationType => notificationType.name === "order_ready_food")
					: false;

				console.log(	selectedRoleObject )
				const itemsFiltered = serviceEvent.items.filter(item =>
					state.selectedRole === "0" ||
					//TODO: Pendiente, esto es sala en cocina
					selectedRoleObject.name.includes('Cocina') ||
					item.requirementProductCopilotRoles
						.some(role => servesFood && role.name === "Cocina" || servesDrinks && role.name === "Barra"));

				return {
					...serviceEvent,
					items: itemsFiltered
				};
			})
			.filter(serviceEvent => serviceEvent.items.length > 0);

	},
	view(state) {
		const role = state.availableRoles.find(role => role.id === state.selectedRole);
		hideAllOffcanvas();
		return role && role.copilotView && role.copilotView.name ? role.copilotView.name : null;
	}
};

const mutations = {
	addProductOrderToHotTable(state, payload) {
		state.hotTable.push(payload.productOrder)
	},
	deleteProductOrderToHotTable(state, payload) {
		console.log('vamos a eliminar de la hotTable',payload, state.hotTable)
		state.hotTable = state.hotTable.filter(productOrder => productOrder.productOrderId !== payload.productOrderId)
		console.log('La hottable despues', state.hotTable)
	},
	clearHotTable(state) {
		console.log('eliminando la hotTbale')
		state.hotTable = []
	},
	setSelectedDate(state, payload) {
		state.selectedDate = payload.selectedDate;
	},
	setAvailableRoles(state, payload) {
		state.availableRoles = payload.availableRoles;
	},
	setSelectedRole(state, payload) {
		state.selectedRole = payload.selectedRole;
	},
	setReservations(state, payload) {
		state.reservations = payload.reservations;
		state.isLoadingGetReservations = false;
	},
	setServiceEvents(state, payload) {
		state.serviceEvents = payload.serviceEvents;
	},
	setNotifications(state, payload) {
		if (state.notifications.length === 0 && payload.notifications.length > 0) {
			state.isPlayNotificationSound = true;
		}
		state.notifications = payload.notifications;
	},
	setIsPlayNotificationSound(state, payload) {
		state.isPlayNotificationSound = payload.isPlayNotificationSound;
	},
	setIsLoadingGetReservations(state, payload) {
		state.isLoadingGetReservations = payload.isLoadingGetReservations;
	},
	setAccounts(state, payload) {
		if (state.accounts.length === 0 && payload.accounts.length > 0) {
			state.isPlayNotificationSound = true;
		}
		state.accounts = payload.accounts;
	},
	setAssistantNotifications(state, payload) {
		state.assistantNotifications = payload.assistantNotifications;
	},
	setIsShowToastScanQr(state, payload) {
		state.isShowToastScanQr = payload.isShowToastScanQr;
	},
	setToast(state, payload) {
		state.toast = payload.toast;
	},
	setNewNotification(state, payload) {
		state.isPlayNotificationSound = true;
		state.newNotification = payload.newNotification;
	},
	setVenueMenus(state, payload) {
		state.venueMenus = payload.venueMenus;
	},
	setCommanderReservation(state, payload) {
		console.log('Desde el modulo seteando: ', payload)
		state.commanderReservation = payload.commanderReservation;
		console.log('Ya seteado: ', state.commanderReservation)
	},
	setNotificationMode(state, payload) {
		state.notificationMode = payload.notificationMode;
	},
	addNotificationToPendingNotifications(state, payload) {
		state.pendingNotifications.push(payload.notification)
	},
	deleteNotificationToPendingNotifications(state, payload) {
		payload.notificationId ?
			state.pendingNotifications = state.pendingNotifications.filter(notification =>
				notification.id !== payload.notificationId)
			: state.pendingNotifications = []
	},
	setTables(state, payload) {
		state.tables = payload.tables;
	},
	setReservationId(state, id) {
		state.reservationId = id;
	},
	setSelectedProductOrdersToServe(state, payload) {
		state.selectedProductOrdersToServe = payload.selectedProductOrdersToServe;
	},
	setSelectedProductOrdersToPrepared(state, payload) {
		state.selectedProductOrdersToPrepared = payload.selectedProductOrdersToPrepared;
	},
	updateProductOrdersStatus(state, payload) {
		console.log('vamos a actualizar los productsOrders y este es el payload', payload)
		state.reservations = state.reservations.map(reservation => {
			if (!reservation.cartSession || !Array.isArray(reservation.cartSession.productOrders)) {
				return reservation;
			}

			return {
				...reservation,
				cartSession: {
					...reservation.cartSession,
					productOrders: reservation.cartSession.productOrders.map(productOrder => {
						if (!Array.isArray(productOrder.items)) {
							return productOrder;
						}

						return {
							...productOrder,
							items: productOrder.items.map(item => {
								if (payload.productOrdersIdsToUpdate.includes(item.productOrderId)) {
									return {
										...item,
										statusProductOrder: payload.status
									};
								}
								return item;
							})
						};
					})
				}
			};
		});
	},
	updateSingleProductOrder(state, payload) {

		const selectedRoleObject = state.availableRoles.find(role => role.id === state.selectedRole);
		const selectedRoleName = selectedRoleObject ? selectedRoleObject.name : null;

		state.reservations = state.reservations.map(reservation => {
			if (!reservation.cartSession || !Array.isArray(reservation.cartSession.productOrders)) {
				return reservation;
			}

			return {
				...reservation,
				cartSession: {
					...reservation.cartSession,
					productOrders: reservation.cartSession.productOrders.map(productOrder => {
						if (!Array.isArray(productOrder.items)) {
							return productOrder;
						}

						return {
							...productOrder,
							items: productOrder.items.map(item => {
								if (payload.productOrderIdToUpdate === item.productOrderId) {

									//console.log("Ha llegado item y lo voy a meter a la mesa caliente de "+ item.requirementProductCopilotRoles[0].name, item)
									//console.log("Y el rol que tengo es " + selectedRoleName, selectedRoleObject)

									if (item.requirementProductCopilotRoles[0]
										&& item.requirementProductCopilotRoles[0].processType === 0
										&& item.requirementProductCopilotRoles[0].name === selectedRoleName
									) {

										state.hotTable.push({
											...item,
											statusProductOrder: payload.status,
											createdDateTime: productOrder.createdDateTime
										});
										console.log(state.hotTable)
									}
									return {
										...item,
										statusProductOrder: payload.status,
									};
								}
								return item;
							})
						};
					})
				}
			};
		});
	},
	addServiceEvent(state, payload) {
		state.serviceEvents.push(payload.serviceEvent);
	},
	updateReservation(state, payload) {
		state.reservations = state.reservations.map(reservation => {
			return reservation.id === payload.updatedReservation.id ? payload.updatedReservation : reservation
		});
	},
	updateServiceEvent(state, payload) {
		state.serviceEvents = state.serviceEvents.map(serviceEvent => {
			return serviceEvent.serviceEventId === payload.serviceEvent.serviceEventId ? payload.serviceEvent : serviceEvent
		});
	},
	addReservationToReservations(state, payload) {
		const logicalDayStartHour = 6;
		const logicalDayStart = DateTime.fromFormat(state.selectedDate, 'yyyy-MM-dd').plus({hours: logicalDayStartHour});
		const reservationTime = DateTime.fromFormat(payload.reservation.startDateTime, 'yyyy-MM-dd HH:mm:ss');
		const selectedDayStart = logicalDayStart.minus({days: reservationTime.hour < logicalDayStartHour ? 1 : 0});
		const selectedDayEnd = logicalDayStart.plus({days: 1});

		const isSameLogicalDay = reservationTime >= selectedDayStart && reservationTime < selectedDayEnd;

		if (isSameLogicalDay) {
			state.reservations.push(payload.reservation);
		}
	}
};

const actions = {
	// changeSelectedRole({ commit }, roleId) {
	// 	commit('setSelectedRole', roleId);
	// },
	async initAvailableRoles({commit}, {user}) {
		if (user != null && user.copilotRoles != null && user.copilotRoles.length > 0) {
			console.log("Leyendo roles del usuario:", user.copilotRoles)
			commit('setAvailableRoles', {availableRoles: user.copilotRoles});

			user.copilotRoles.forEach(role => {
				if (role.active === 1) {
					console.log("El usuario " + user.name + " tiene activo el rol " + role.name);
					commit('setSelectedRole', {selectedRole: role.id});
				}
			});

		} else {
			try {
				console.log("No hay usuario, trayendo todos los roles del restaurante")
				let roleResponse = await copilotRepository.getRolesCopilot()
				commit('setAvailableRoles', {availableRoles: roleResponse.copilot_roles});
			} catch (e) {
				console.log("pues hay excepcion", e)
			}
		}
	},

	async updateReservations({commit}) {
		try {
			commit('setIsLoadingGetReservations', {isLoadingGetReservations: true});
			console.log('La fecha desde', state.selectedDate)
			const resultGetByDay = await reservationRepository.getReservationsByDay(state.selectedDate);

			if (resultGetByDay === 401) {
				return window.location.href = origin + '/login';
			}

			if (resultGetByDay === 403) {
				return alert('No estas autorizado para acceder a copilot');
			}

			commit('setReservations', {reservations: resultGetByDay.reservations});
			commit('setServiceEvents', {serviceEvents: resultGetByDay.serviceEvents});

		} catch (e) {
			console.error(e)
		}
	},
	async updateOrdersAndNotifications({commit}) {
		try {
			console.log('Vamos a traer todos los pedidos y notificaciones: ')
			const result = await copilotRepository.getTakeAway();
			commit('setNotifications', {notifications: result.notifications});
			commit('setAccounts', {accounts: result.accounts});
			commit('setAssistantNotifications', {assistantNotifications: result.we});
			console.log(result.notifications)

		} catch (e) {
			console.log(e)
		}
	},
	async loadVenueMenus({commit}) {
		try {
			const menus = await menuRepository.getAvailableMenus();
			if (menus.result === 0) {
				console.log('desde la carga', menus);
				commit('setVenueMenus', {venueMenus: menus.menus});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	async getTables({commit}) {
		try {
			const rommsAndTablesResult = await copilotRepository.getTables();
			let rooms = rommsAndTablesResult.rooms;

			if (rooms.length >= 0) {
				console.log("Las rooms llegan como array")
			} else {
				rooms = Object.values(rooms);
			}
			const tables = rooms.flatMap(room => room.tables.map(table => ({
					...table,
					room: room.name
				}))
			);
			console.log('Las mesas: ', tables)
			if (rommsAndTablesResult.result === 0) {
				commit('setTables', {tables: tables});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},

	getIdReservation({commit}, id) {
		commit('setReservationId', id);
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};

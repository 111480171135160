<template src="./widgetReserve.html"></template>

<script>

import {mapGetters} from "vuex";

export default {
	name: 'WidgetReserve',
	data() {
		return {
			updatedUrl: ""
		};
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue',"reservationManagerUrl"]),
	},
	created() {
		this.updatedUrl = this.removeMyrestooBookings(this.reservationManagerUrl);
	},
	methods: {
		removeMyrestooBookings(url) {
			const substringToRemove = 'myrestoo/bookings';
			if (url.endsWith(substringToRemove)) {
				return url.slice(0, -substringToRemove.length);
			}
			return url;
		}
	}
}
</script>

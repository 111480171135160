<template src="./modalCheckInbox.html"></template>

<script>
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalCheckInbox',
	emits: [],
	props: {
    mailConfirmSave: String,
  },
	data() {
		return {};
	},
	beforeMount() {
	},
	mounted() {
	},
  components: {DismissButton},
  methods: {}

}
</script>

<template src="./generatorQr.html"></template>

<script>
import QRCodeVue from "vue-qrcode";
export default {
	components: {
		QRCodeVue
	},
	props: {
		link: String,
	},
	data() {
		return {}
	},
	watch: {

	},
	beforeMount() {

	},
	mounted() {
		console.log('el link desde', this.link)
	},
	methods: {
	}
}
</script>

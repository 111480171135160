<div id="messageModal" class="modal fade pe-0" tabindex="-1" role="dialog" aria-labelledby="alertModalLabel"  aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div v-if="message.products" class="modal-title h4" id="modal-message-header"><i class="fas fa-check-circle"></i> ¡Pedido enviado con éxito!</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p class="text-center text-md-start">
					<b>¡Gracias, {{message.clientName}}!</b> Tu {{ message.products ? 'pedido' : 'solicitud de cuenta' }} ha sido {{ message.products ? 'enviado' : 'recibida' }} con éxito.
				</p>

				<ul v-if="message.products" class="p-0">
					<li v-for="product in message.products" :key="product.sku" class="list-unstyled">
						<div class="border-bottom">
							<div class="row">
								<div class="col-9">
									<b>{{product.name}}</b>
									<br>
									<span v-for="(chosenOption,index) in product.chosenOptions" class="card-extras text-start">
										{{ (product.productOptions.flatMap(option => option.options).find(opt => opt.id === chosenOption)?.name) }}
										<span v-if="index !== product.chosenOptions.length - 1">, </span>
									</span>
								</div>
								<div class="col-3">
									<b>{{product.cartItemCount}} ud</b>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<p class="text-center text-md-start">
					{{ message.products
					? 'Tu comida se está preparando. ¿Quieres añadir algo más? Simplemente agrégalo al carrito y confirma tu pedido.'
					: ' Por favor, espera mientras un miembro de nuestro equipo se acerca para completar el proceso de pago.'
					}}
				</p>

				<div class="text-center text-md-start">
					<b>{{ message.products ? '¡Esperamos que disfrutes tu comida!' : '¡Gracias por tu visita!' }}</b>
				</div>

			</div>
			<div id="modal-footer-section" class="modal-footer">
				<DismissButton  @click="closeModal"/>
			</div>
		</div>
	</div>
</div>

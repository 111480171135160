import {userRepository} from "@/service/repository/userRepository";
import {cookieManager} from "@/service/cookieManager";

const state = {
    user: null,
    userReservations: [],
};

const getters = {
	firstReservation(state) {
		const allReservations = [...state.userReservations];
		allReservations.sort((a, b) => {
			const dateA = new Date(a.startDateTime);
			const dateB = new Date(b.startDateTime);
			return dateA - dateB;
		});

		return allReservations.length > 0 ? allReservations[0] : false;
	},
	user(state){
		return state.user
	},
};

const mutations = {
    setUser(state, payload) {
        state.user = payload.user;
    },
    loadUserReservations(state, payload) {
        state.userReservations = payload.userReservations;
    },
    eraseReservation(state, payload) {
		console.log('Las reservas antes de cancelar:', state.userReservations.length)
        state.userReservations = state.userReservations.filter(
            reservation => reservation.id !== payload.reservationId
        );
		console.log('Las reservas antes de cancelar:', state.userReservations.length, "despues de cancelar:", payload.reservationId )
    },
};

const actions = {
	async find({ commit }, userId) {
		try {
			const findUserResult = await userRepository.find(userId);
			if (findUserResult.result === 0){
				commit('setUser', findUserResult);
				cookieManager.setToken(findUserResult.token)
				return findUserResult
			} else {
				cookieManager.clearToken();
				console.log("No se ha encontrado ningun usuario con ese UID");
				return findUserResult
			}
		} catch (error) {
			console.error("Failed to fetch user", error);
		}
	},

	async loginWithToken({ commit }, token) {
		try {
			const findUserResult = await userRepository.loginWithToken(token);
			if (findUserResult.result === 0){
				console.log(findUserResult, 'el usuario que devuelve logar con token')
				commit('setUser', findUserResult);
				cookieManager.setToken(findUserResult.token)
				return findUserResult
			} else {
				cookieManager.clearToken();
				console.log("No se ha encontrado ningun usuario con ese token");
				return findUserResult
			}
		} catch (error) {
			console.error("Failed to fetch user", error);
		}
	},
}

export default {
    namespaced: true,
	state,
    mutations,
	getters,
	actions
};

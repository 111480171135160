<template src="./modalAvisoLegal.html"></template>

<script>
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalAvisoLegal',
	emits: [],
	props: [],
	data() {
		return {};
	},
	components: {DismissButton},
	beforeMount() {
	},
	mounted() {

	},
	methods: {}

}
</script>

import {sendRequestJson} from '@/utils'
class CartSessionRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('CartSession con urlBase: ', this.urlBase)
	}

	async processOrder(cartSessionOrderId, roleId) {
		const url = this.urlBase + "/world/api/cartsession/processorder"
		try {
			return await sendRequestJson({data:{cartSessionOrderId, roleId}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo procesando el pedido.");
		}
	}

	async createCartSessionOrder(orderInfo, products) {
		console.log('Creando cartSession order')
		const url = this.urlBase + "/world/api/cartsession/createcartsessionorder"
		try {
			return await sendRequestJson({data:{orderInfo, products}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo procesando el pedido.");
		}
	}

}

export const cartSessionRepository = new CartSessionRepository();

<template src="./ebitdata.html"></template>

<script>
import VChart from "vue-echarts";
import 'echarts';
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import DatePicker from "@/components/pickdate/DatePicker";
import HeaderComponent from "@/core/HeaderComponent";
import FooterWelcom from "@/core/FooterWelcom";

export default {
	name: 'EbitdataWelcom',
	components: {
		'v-chart': VChart,
		DatePicker,
		HeaderComponent,
		FooterWelcom
	},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'venueId', 'reservationManagerUrl', 'menuServices']),
		...mapGetters('ebitdata', ['selectedDate', 'reservations', 'tables', 'weekReservations']),

		allReservations() {
			if (!this.reservations) return null;

			const totalReservations = this.reservations.filter(reservation => {
				return reservation.cancelledDateTime === null && reservation.status !== -4;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + (reservation.pax || 0), 0);

			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},
		reservationsToArrive() {
			if (!this.reservations) return null;
			const totalReservations = this.reservations.filter(reservation => {
				return reservation.menuAccessEnabled !== 1 && !reservation.finishedDateTime && reservation.cancelledDateTime === null && reservation.status !== -4;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},
		seatedReservations() {
			if (!this.reservations) return null;
			const totalReservations = this.reservations.filter(reservation => {
				return reservation.menuAccessEnabled === 1 && !reservation.finishedDateTime;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},
		finalizedReservations() {
			if (!this.reservations) return null;
			const totalReservations = this.reservations.filter(reservation => reservation.finishedDateTime !== null);
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},
		noShowReservations() {
			if (!this.reservations) return null;
			const totalReservations = this.reservations.filter(reservation => reservation.status === -4);
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},
		cancelledReservations() {
			if (!this.reservations) return null;
			const totalReservations = this.reservations.filter(reservation => {
				return reservation.status === -4;
			});
			const totalPax = totalReservations.reduce((total, reservation) => total + reservation.pax, 0);
			return totalPax > 0 ? {totalReservations, totalPax} : null;
		},

		reservationRoomNameTicketMiddle() {
			const roomMap = new Map();

			// Iterar sobre las mesas para organizar las reservaciones por nombre de sala
			this.tables.forEach(table => {
				const roomName = table.room || "Sin Zona";

				// Si el roomName aún no está en el mapa, agregarlo con un objeto inicializado
				if (!roomMap.has(roomName)) {
					roomMap.set(roomName, {reservations: [], totalPax: 0, totalPrice: 0});
				}

				// Filtrar las reservaciones que coincidan con el nombre de la sala en la mesa
				const reservationsForRoom = this.reservations.filter(
					res => (res.roomName ? res.roomName.name : "Sin Zona") === roomName
				);

				// Agregar las reservaciones filtradas al arreglo correspondiente en el mapa
				const roomData = roomMap.get(roomName);
				roomData.reservations.push(...reservationsForRoom);

				// Sumar el total de pax y precios para la zona actual
				reservationsForRoom.forEach(reservation => {
					roomData.totalPax += reservation.pax || 0;

					if (reservation.cartSession) {
						reservation.cartSession.productOrders.forEach(
							products => {
								products.items.forEach(
									product => {
										roomData.totalPrice += product.price || 0;
									}
								)
							}
						)
					}
				});
			});

			// Añadir las reservaciones con roomName nulo o indefinido directamente a "Sin Zona"
			const reservationsWithoutRoom = this.reservations.filter(
				res => !res.roomName
			);

			if (reservationsWithoutRoom.length > 0) {
				if (!roomMap.has("Sin Zona")) {
					roomMap.set("Sin Zona", {reservations: [], totalPax: 0, totalPrice: 0});
				}
				const roomData = roomMap.get("Sin Zona");
				roomData.reservations.push(...reservationsWithoutRoom);

				// Sumar el total de pax y precios para "Sin Zona"
				reservationsWithoutRoom.forEach(reservation => {
					roomData.totalPax += reservation.pax || 0;

					if (reservation.cartSession) {
						reservation.cartSession.productOrders.forEach(
							products => {
								products.items.forEach(
									product => {
										roomData.totalPrice += product.price || 0;
									}
								)
							}
						)
					}
				});
			}

			// Convertir el mapa en un arreglo de objetos con roomName, reservaciones, totalPax, totalPrice y averageTicket
			const groupedReservations = [];
			roomMap.forEach((data, roomName) => {
				groupedReservations.push({
					roomName,
					reservations: data.reservations,
					totalPax: data.totalPax,
					totalPrice: data.totalPrice,
					averageTicket: data.totalPax > 0 ? parseFloat((data.totalPrice / data.totalPax).toFixed(3)) : 0
				});
			});

			console.log(roomMap);

			return groupedReservations;
		},

		//Pinta el grafico Reservas del dia y ticket medio
		chartOptionsReservationRoomNameTicketMiddle() {
			const categories = this.reservationRoomNameTicketMiddle.map(entry => entry.roomName);

			const resultData = {
				'Total Pax': this.reservationRoomNameTicketMiddle.map(entry => entry.totalPax),
				'Total': this.reservationRoomNameTicketMiddle.map(entry => entry.totalPrice),
				'Ticket Medio':  this.reservationRoomNameTicketMiddle.map(entry => entry.averageTicket),

			};

			return {
				title: {
					text: 'Reservas del dia y ticket medio'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					top: '7%',
					data: Object.keys(resultData)
				},
				grid: {
					top: '25%',
					left: '1%',
					right: '4%',
					bottom: '2%',
					containLabel: true
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: categories
				},
				yAxis: {
					type: 'value'
				},
				series: Object.keys(resultData).map(key => ({
					name: key,
					type: 'line',
					data: resultData[key],
					markPoint: {
						data: [{type: 'max', name: 'Punto más alto'}]
					}
				}))
				// series: [
				// 	{
				// 		name: 'Total Pax',
				// 		type: 'line',
				// 		data: data,
				// 		markPoint: {
				// 			data: [
				// 				{type: 'max', name: 'Punto más alto'}
				// 			]
				// 		}
				// 	},
				// 	{
				// 		name: 'Ticket medio',
				// 		type: 'line',
				// 		data: null,
				// 		markPoint: {
				// 			data: [
				// 				{type: 'max', name: 'Punto más alto'}
				// 			]
				// 		}
				// 	},
				// 	{
				// 		name: 'Total',
				// 		type: 'line',
				// 		data: null,
				// 		markPoint: {
				// 			data: [
				// 				{type: 'max', name: 'Punto más alto'}
				// 			]
				// 		}
				// 	}
				// ]
			};
		},
		//Pinta el grafico reservas por semana
		reservationStatusWeek() {
			const daysOfWeek = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];
			const selectedDate = new Date(this.selectedDate);
			const startDate = new Date(selectedDate);
			startDate.setDate(startDate.getDate() - startDate.getDay() + 1); // Comienza el lunes

			// Crear un array de fechas desde startDate por 7 días
			const dateRange = [];
			for (let i = 0; i < 7; i++) {
				const date = new Date(startDate);
				date.setDate(startDate.getDate() + i);
				dateRange.push(date);
			}

			// Inicializa los datos para cada categoría
			const resultData = {
				Reservas: Array(7).fill(0),
				Canceladas: Array(7).fill(0),
				'No show': Array(7).fill(0),
				'Walk in': Array(7).fill(0),
				'Sit in': Array(7).fill(0),
			};

			// Array para las fechas formateadas
			const dates = dateRange.map(date => {
				return date.toISOString().split('T')[0];
			});

			console.log('weekReservations:', this.weekReservations);

			// Itera sobre las reservas de la semana para contar las reservas en cada categoría por día
			this.weekReservations.forEach(reservation => {
				const reservationDate = new Date(reservation.startDateTime.replace(' ', 'T'));
				const dayIndex = dates.findIndex(date => date === reservationDate.toISOString().split('T')[0]);

				if (dayIndex !== -1) {
					if (reservation.cancelledDateTime === null && reservation.status !== -4) {
						resultData.Reservas[dayIndex]++;
					}
					if (reservation.status === -4) {
						resultData.Canceladas[dayIndex]++;
					}
					if (reservation.status === -4) {
						resultData['No show'][dayIndex]++;
					}
					if (reservation.walkIn === 1) {
						resultData['Walk in'][dayIndex]++;
					}
					if (reservation.walkIn === 2) {
						resultData['Sit in'][dayIndex]++;
					}
				}
			});

			return {
				title: {
					text: 'Control de reservas semanales'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					top: '7%',
					data: Object.keys(resultData)
				},
				grid: {
					top: '25%',
					left: '1%',
					right: '4%',
					bottom: '2%',
					containLabel: true
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: dates.map((date, index) => `${date} (${daysOfWeek[index]})`)
				},
				yAxis: {
					type: 'value'
				},
				series: Object.keys(resultData).map(key => ({
					name: key,
					type: 'line',
					data: resultData[key],
					markPoint: {
						data: [{type: 'max', name: 'Punto más alto'}]
					}
				}))
			};
		}
	},
	watch: {
		selectedDate() {
			this.updateReservations()
			this.updateReservationsForWeek()
		},
	},
	mounted() {
		this.updateReservations();
		this.updateReservationsForWeek()
	},
	beforeMount() {
		console.log('el usuario antes de cargar ebitdata: ', this.user)
	},
	data() {
		return {
			reservationTypes: [
				{key: 'allReservations', title: 'Total', class: ''},
				// { key: 'reservationsToArrive', title: 'Por Llegar' },
				// { key: 'seatedReservations', title: 'Sentados' },
				// { key: 'finalizedReservations', title: 'Finalizadas' },
				{key: 'noShowReservations', title: 'No Show', class: 'card-ebitdata-danger'},
				{key: 'cancelledReservations', title: 'Canceladas', class: 'card-ebitdata-warning'},
			],
			optionMinAvgMax: {
				"title": {
					"text": "Evolución",
					"subtext": "Ticket Mínimo, Medio y Máximo de comida"
				},
				"legend": {
					"bottom": 0
				},
				"tooltip": {},
				"dataset": {
					"dimensions": [
						"interval",
						"Tmax",
						"Tmed",
						"Tmin"
					],
					"source": [
						{
							"interval": "01-06 al 30-06",
							"Tmax": 61.12,
							"Tmed": 23.89,
							"Tmin": 5.58
						},
						{
							"interval": "01-07 al 31-07",
							"Tmax": 59.84,
							"Tmed": 23.58,
							"Tmin": 5.66
						},
						{
							"interval": "01-08 al 31-08",
							"Tmax": 63.56,
							"Tmed": 22.94,
							"Tmin": 4.9
						},
						{
							"interval": "01-09 al 30-09",
							"Tmax": 64.91,
							"Tmed": 24.16,
							"Tmin": 1.5
						},
						{
							"interval": "01-10 al 31-10",
							"Tmax": 54.01,
							"Tmed": 22.98,
							"Tmin": 1
						},
						{
							"interval": "01-11 al 30-11",
							"Tmax": 101.5,
							"Tmed": 24.27,
							"Tmin": 0.5
						},
						{
							"interval": "01-12 al 31-12",
							"Tmax": 61.26,
							"Tmed": 26.48,
							"Tmin": 1.5
						}
					]
				},
				"xAxis": {
					"type": "category",
					"axisLabel": {
						"rotate": 30
					}
				},
				"yAxis": {
					"type": "value",
					"axisLabel": {
						"formatter": "{value}€"
					}
				},
				"label": {
					"show": true,
					"position": "top",
					"color": "inherit"
				},
				"series": [
					{
						"type": "line",
						"label": {
							"formatter": "{@[1]}€"
						}
					},
					{
						"type": "line",
						"label": {
							"formatter": "{@[2]}€"
						}
					},
					{
						"type": "line",
						"label": {
							"formatter": "{@[3]}€"
						}
					}
				]
			},
			optionFrequencyEvo: {
				"title": {
					"text": "Evolución",
					"subtext": "Ticket medio por frecuencia"
				},
				"legend": {
					"bottom": 0
				},
				"dataset": {
					"dimensions": [
						"interval",
						"Más de 1 Visita",
						"Primera Visita"
					],
					"source": [
						{
							"interval": "01-06 al 30-06",
							"Más de 1 Visita": 21.83,
							"Primera Visita": 25.39
						},
						{
							"interval": "01-07 al 31-07",
							"Más de 1 Visita": 22.24,
							"Primera Visita": 24.96
						},
						{
							"interval": "01-08 al 31-08",
							"Más de 1 Visita": 20.89,
							"Primera Visita": 23.9
						},
						{
							"interval": "01-09 al 30-09",
							"Más de 1 Visita": 24.41,
							"Primera Visita": 24.9
						},
						{
							"interval": "01-10 al 31-10",
							"Más de 1 Visita": 24.97,
							"Primera Visita": 23.32
						},
						{
							"interval": "01-11 al 30-11",
							"Más de 1 Visita": 23.8,
							"Primera Visita": 25.29
						},
						{
							"interval": "01-12 al 31-12",
							"Más de 1 Visita": 27.52,
							"Primera Visita": 27.07
						}
					]
				},
				"xAxis": {
					"type": "category",
					"axisLabel": {
						"rotate": 30
					}
				},
				"yAxis": {
					"type": "value",
					"axisLabel": {
						"formatter": "{value}€"
					},
					"min": 18
				},
				"label": {
					"show": true,
					"position": "top",
					"color": "inherit"
				},
				"series": [
					{
						"type": "line",
						"label": {
							"formatter": "{@[1]}€"
						}
					},
					{
						"type": "line",
						"label": {
							"formatter": "{@[2]}€"
						}
					}
				]
			},
			optionEvoQr: {
				"title": {
					"text": "Evolución",
					"subtext": "% Mostrar QR, % Pedir con WELKHOMEmenu, Valoración"
				},
				"legend": {
					"bottom": 0
				},
				"tooltip": {},
				"dataset": {
					"dimensions": [
						"interval",
						"% QR",
						"% Pedir",
						"Stars"
					],
					"source": [
						{
							"interval": "01-06 al 30-06",
							"% QR": "74.52",
							"% Pedir": "91.72",
							"Stars": "4.83"
						},
						{
							"interval": "01-07 al 30-07",
							"% QR": "76.34",
							"% Pedir": "96.95",
							"Stars": "4.84"
						},
						{
							"interval": "01-08 al 30-08",
							"% QR": "74.14",
							"% Pedir": "94.83",
							"Stars": "4.94"
						},
						{
							"interval": "01-09 al 30-09",
							"% QR": "75.66",
							"% Pedir": "91.45",
							"Stars": "4.83"
						},
						{
							"interval": "01-10 al 30-10",
							"% QR": "80.69",
							"% Pedir": "95.86",
							"Stars": "4.81"
						},
						{
							"interval": "01-11 al 30-11",
							"% QR": "69.14",
							"% Pedir": "93.71",
							"Stars": "4.77"
						},
						{
							"interval": "01-12 al 30-12",
							"% QR": "73.71",
							"% Pedir": "92.78",
							"Stars": "4.85"
						}
					]
				},
				"xAxis": {
					"type": "category",
					"axisLabel": {
						"rotate": -45
					}
				},
				"yAxis": [
					{
						"type": "value",
						"min": 70,
						"max": 110,
						"axisLabel": {
							"formatter": "{value}%"
						}
					},
					{
						"show": true,
						"type": "value",
						"min": -5,
						"max": 5,
						"axisLabel": {
							"formatter": "{value}/5"
						}
					}
				],
				"label": {
					"show": true,
					"position": "top",
					"color": "inherit"
				},
				"series": [
					{
						"type": "line",
						"label": {
							"formatter": "{@[1]}%"
						}
					},
					{
						"type": "line",
						"label": {
							"formatter": "{@[2]}%"
						}
					},
					{
						"type": "line",
						"label": {
							"formatter": "{@[3]}/5"
						},
						"yAxisIndex": 1,
						"symbol": "path://M26.28499984741211,2.4860000610351562l5.406999588012695,10.956000328063965c0.37600135803222656,0.7620000839233398,1.1029987335205078,1.2899999618530273,1.9440021514892578,1.4119997024536133l12.090999603271484,1.7569999694824219c2.118000030517578,0.3080005645751953,2.9629974365234375,2.9099998474121094,1.430999755859375,4.4029998779296875l-8.749000549316406,8.527999877929688c-0.608001708984375,0.5930004119873047,-0.8860015869140625,1.4479999542236328,-0.7420005798339844,2.2849998474121094l2.0649986267089844,12.041999816894531c0.3620033264160156,2.1090011596679688,-1.8519973754882812,3.716999053955078,-3.7459983825683594,2.7220001220703125l-10.81399917602539,-5.685001373291016c-0.7520008087158203,-0.39499664306640625,-1.6510009765625,-0.39499664306640625,-2.4030017852783203,0l-10.81399917602539,5.685001373291016c-1.8940000534057617,0.996002197265625,-4.107999801635742,-0.6129989624023438,-3.746000289916992,-2.7220001220703125l2.065000534057617,-12.041999816894531c0.14400005340576172,-0.8369998931884766,-0.13399982452392578,-1.6919994354248047,-0.7420005798339844,-2.2849998474121094l-8.74899959564209,-8.527999877929688c-1.5320000052452087,-1.4939994812011719,-0.6869999915361404,-4.096000671386719,1.430999994277954,-4.4029998779296875l12.091000318527222,-1.7569999694824219c0.8409996032714844,-0.12199974060058594,1.5679998397827148,-0.6499996185302734,1.944000244140625,-1.4119997024536133l5.406999588012695,-10.956000328063965C22.601999282836914,0.5669999718666077,25.33799934387207,0.5669999718666077,26.28499984741211,2.4860000610351562Z"
					}
				]
			},
			optionSalesEvoStacked: {
				"title": {
					"text": "Evolución",
					"subtext": "Ingresos por canal"
				},
				"tooltip": {
					"trigger": "axis"
				},
				"legend": {
					"bottom": 0,
					"type": "scroll"
				},
				"grid": {
					"right": 0,
					'left': 55,
				},
				"xAxis": [
					{
						"type": "category",
						"axisLabel": {
							"rotate": 20
						},
						"axisPointer": {
							"type": "shadow"
						}
					}
				],
				"yAxis": [
					{
						"type": "value",
						"axisLabel": {
							"formatter": "{value}€"
						}
					},
					{
						"show": false,
						"type": "value",
						"min": -8000,
						"max": 44875.56,
						"axisLabel": {
							"formatter": "{value}€"
						}
					}
				],
				"series": [
					{
						"name": "Just Eat",
						"itemStyle": {
							"color": "#52657e"
						},
						"type": "bar",
						"stack": "Delivery",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Uber Eats",
						"itemStyle": {
							"color": "#6a83a3"
						},
						"type": "bar",
						"stack": "Delivery",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Glovo",
						"itemStyle": {
							"color": "#85a4cd"
						},
						"type": "bar",
						"barWidth": "15%",
						"stack": "Delivery",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "TheFork",
						"itemStyle": {
							"color": "#07a2a4"
						},
						"type": "bar",
						"barWidth": "15%",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Web",
						"type": "bar",
						"stack": "WELKHOMEclub",
						"itemStyle": {
							"color": "#d49f1d"
						},
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Beber",
						"type": "bar",
						"stack": "WELKHOMEclub",
						"itemStyle": {
							"color": "#64ce99"
						},
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "gmap",
						"itemStyle": {
							"color": "#e3b239"
						},
						"type": "bar",
						"stack": "WELKHOMEclub",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Walk-in",
						"itemStyle": {
							"color": "#f4ca62"
						},
						"type": "bar",
						"stack": "WELKHOMEclub",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Mailing",
						"itemStyle": {
							"color": "#f2d58e"
						},
						"type": "bar",
						"barWidth": "20%",
						"stack": "WELKHOMEclub",
						"emphasis": {
							"focus": "series"
						}
					},
					{
						"name": "Total",
						"type": "line",
						"yAxisIndex": 1,
						"label": {
							"show": true,
							"position": "top",
							"color": "inherit",
							"formatter": "{@Total}€"
						}
					}
				],
				"dataset": {
					"dimensions": [
						"interval",
						"Just Eat",
						"Uber Eats",
						"Glovo",
						"TheFork",
						"Web",
						"Beber",
						"gmap",
						"Walk-in",
						"Mailing",
						"Total",
						"Wel",
						"Delivery",
						"Instagram"
					],
					"source": [
						{
							"interval": "01-06 al 30-06",
							"Beber": 1420.9,
							"gmap": 525.52,
							"Just Eat": 45.07,
							"Mailing": 342.73,
							"TheFork": 18384.33,
							"Uber Eats": 273.86,
							"Walk-in": 2836.12,
							"Web": 11126.55,
							"Wel": 16251.82,
							"Total": 34955.08,
							"Delivery": 318.93
						},
						{
							"interval": "01-07 al 31-07",
							"Beber": 1082.02,
							"gmap": 474.58,
							"Just Eat": 128.08,
							"Mailing": 935.79,
							"TheFork": 20731.28,
							"Uber Eats": 273.99,
							"Walk-in": 2811.52,
							"Web": 8038.99,
							"Wel": 13342.90,
							"Total": 34476.25,
							"Delivery": 402.07
						},
						{
							"interval": "01-08 al 31-08",
							"Beber": 723.9,
							"gmap": 284.52,
							"Mailing": 1052.26,
							"TheFork": 14991.17,
							"Uber Eats": 307.61,
							"Walk-in": 2308.01,
							"Web": 6648.6,
							"Wel": 11017.29,
							"Total": 26316.07,
							"Delivery": 307.61
						},
						{
							"interval": "01-09 al 30-09",
							"Beber": 1132.66,
							"gmap": 634.93,
							"Instagram": 64.5,
							"Just Eat": 165.09,
							"Mailing": 774.75,
							"TheFork": 17687.28,
							"Uber Eats": 368.81,
							"Walk-in": 3007.81,
							"Web": 11612.15,
							"Wel": 17226.80,
							"Total": 35447.98,
							"Delivery": 533.90
						},
						{
							"interval": "01-10 al 31-10",
							"Beber": 1446.54,
							"gmap": 296.74,
							"Instagram": 94.48,
							"Just Eat": 24.21,
							"Mailing": 966.15,
							"TheFork": 20974.25,
							"Uber Eats": 1147.19,
							"Walk-in": 3948.11,
							"Web": 10870.2,
							"Wel": 17622.22,
							"Total": 39767.87,
							"Delivery": 1171.40
						},
						{
							"interval": "01-11 al 30-11",
							"Beber": 2383.11,
							"Glovo": 233.6,
							"gmap": 1051.03,
							"Just Eat": 14.45,
							"Mailing": 869.7,
							"TheFork": 21267.59,
							"Uber Eats": 478.05,
							"Walk-in": 2961.23,
							"Web": 13430.68,
							"Wel": 20695.75,
							"Total": 42689.44,
							"Delivery": 726.10
						},
						{
							"interval": "01-12 al 31-12",
							"Beber": 2624.52,
							"gmap": 982.13,
							"Instagram": 91.66,
							"Mailing": 1244.09,
							"TheFork": 16811.89,
							"Uber Eats": 213.57,
							"Walk-in": 2631.12,
							"Web": 19276.58,
							"Wel": 26850.10,
							"Total": 43875.56,
							"Delivery": 213.57
						}
					]
				}
			},
			optionCheckinHeatmap: {
				"title": {
					"text": "Afluencia por día y hora - Calendario",
					"subtext": "Sin incluir adultos sin reserva"
				},
				"legend": {
					"data": [
						"Check-ins",
						"Personas con reserva"
					],
					"selected": {
						"Check-ins": true,
						"Personas con reserva": false
					},
					"top": "60"
				},
				"tooltip": {
					"position": "top"
				},
				"grid": {
					"height": "65%",
					"top": "20%",
					"bottom": "10%",
					"right": '0',
					"left": '50',
				},
				"xAxis": {
					"type": "category",
					"data": [
						"12:00",
						"12:15",
						"12:30",
						"12:45",
						"13:00",
						"13:15",
						"13:30",
						"13:45",
						"14:00",
						"14:15",
						"14:30",
						"14:45",
						"15:00",
						"15:15",
						"15:30",
						"15:45",
						"16:00",
						"16:15",
						"16:30",
						"16:45",
						"17:00",
						"17:15",
						"17:30",
						"17:45",
						"18:00",
						"18:15",
						"18:30",
						"18:45",
						"19:00",
						"19:15",
						"19:30"
					],
					"splitArea": {
						"show": true
					}
				},
				"yAxis": {
					"type": "category",
					"data": [
						"05 gen.",
						"04 gen.",
						"03 gen.",
						"02 gen.",
						"01 gen.",
						"31 des.",
						"30 des.",
						"29 des.",
						"28 des.",
						"27 des.",
						"26 des.",
						"25 des.",
						"24 des.",
						"23 des.",
						"22 des.",
						"21 des.",
						"20 des.",
						"19 des.",
						"18 des.",
						"17 des.",
						"16 des.",
						"15 des."
					],
					"splitArea": {
						"show": true
					}
				},
				"visualMap": {
					"min": 0,
					"max": 40,
					"calculable": true,
					"orient": "horizontal",
					"left": "center",
					"bottom": "0",
					"inRange": {
						"color": [
							"#ffffff",
							"#11283d",
						]
					}
				},
				"series": [
					{
						"name": "Check-ins",
						"type": "heatmap",
						"data": [
							[
								0,
								21,
								"-"
							],
							[
								1,
								21,
								"-"
							],
							[
								2,
								21,
								"-"
							],
							[
								3,
								21,
								"-"
							],
							[
								4,
								21,
								"-"
							],
							[
								5,
								21,
								"-"
							],
							[
								6,
								21,
								"-"
							],
							[
								7,
								21,
								"-"
							],
							[
								8,
								21,
								"-"
							],
							[
								9,
								21,
								"-"
							],
							[
								10,
								21,
								"-"
							],
							[
								11,
								21,
								"-"
							],
							[
								12,
								21,
								"-"
							],
							[
								13,
								21,
								"-"
							],
							[
								14,
								21,
								"-"
							],
							[
								15,
								21,
								"-"
							],
							[
								16,
								21,
								"-"
							],
							[
								17,
								21,
								"-"
							],
							[
								18,
								21,
								"-"
							],
							[
								19,
								21,
								"-"
							],
							[
								20,
								21,
								"-"
							],
							[
								21,
								21,
								"-"
							],
							[
								22,
								21,
								"-"
							],
							[
								23,
								21,
								"-"
							],
							[
								24,
								21,
								"-"
							],
							[
								25,
								21,
								"-"
							],
							[
								26,
								21,
								"-"
							],
							[
								27,
								21,
								"-"
							],
							[
								28,
								21,
								"-"
							],
							[
								29,
								21,
								"-"
							],
							[
								30,
								21,
								"-"
							],
							[
								0,
								20,
								"-"
							],
							[
								1,
								20,
								"-"
							],
							[
								2,
								20,
								"-"
							],
							[
								3,
								20,
								"-"
							],
							[
								4,
								20,
								"-"
							],
							[
								5,
								20,
								"-"
							],
							[
								6,
								20,
								"-"
							],
							[
								7,
								20,
								"-"
							],
							[
								8,
								20,
								"-"
							],
							[
								9,
								20,
								"-"
							],
							[
								10,
								20,
								"-"
							],
							[
								11,
								20,
								"-"
							],
							[
								12,
								20,
								"-"
							],
							[
								13,
								20,
								"-"
							],
							[
								14,
								20,
								"-"
							],
							[
								15,
								20,
								"-"
							],
							[
								16,
								20,
								"-"
							],
							[
								17,
								20,
								"-"
							],
							[
								18,
								20,
								"-"
							],
							[
								19,
								20,
								"-"
							],
							[
								20,
								20,
								"-"
							],
							[
								21,
								20,
								"-"
							],
							[
								22,
								20,
								4
							],
							[
								23,
								20,
								"-"
							],
							[
								24,
								20,
								13
							],
							[
								25,
								20,
								10
							],
							[
								26,
								20,
								13
							],
							[
								27,
								20,
								18
							],
							[
								28,
								20,
								13
							],
							[
								29,
								20,
								19
							],
							[
								30,
								20,
								12
							],
							[
								0,
								19,
								20
							],
							[
								1,
								19,
								13
							],
							[
								2,
								19,
								16
							],
							[
								3,
								19,
								18
							],
							[
								4,
								19,
								9
							],
							[
								5,
								19,
								13
							],
							[
								6,
								19,
								11
							],
							[
								7,
								19,
								13
							],
							[
								8,
								19,
								16
							],
							[
								9,
								19,
								10
							],
							[
								10,
								19,
								11
							],
							[
								11,
								19,
								14
							],
							[
								12,
								19,
								6
							],
							[
								13,
								19,
								12
							],
							[
								14,
								19,
								15
							],
							[
								15,
								19,
								25
							],
							[
								16,
								19,
								17
							],
							[
								17,
								19,
								14
							],
							[
								18,
								19,
								14
							],
							[
								19,
								19,
								7
							],
							[
								20,
								19,
								21
							],
							[
								21,
								19,
								11
							],
							[
								22,
								19,
								4
							],
							[
								23,
								19,
								16
							],
							[
								24,
								19,
								6
							],
							[
								25,
								19,
								20
							],
							[
								26,
								19,
								13
							],
							[
								27,
								19,
								8
							],
							[
								28,
								19,
								10
							],
							[
								29,
								19,
								14
							],
							[
								30,
								19,
								14
							],
							[
								0,
								18,
								"-"
							],
							[
								1,
								18,
								"-"
							],
							[
								2,
								18,
								"-"
							],
							[
								3,
								18,
								"-"
							],
							[
								4,
								18,
								"-"
							],
							[
								5,
								18,
								"-"
							],
							[
								6,
								18,
								"-"
							],
							[
								7,
								18,
								"-"
							],
							[
								8,
								18,
								"-"
							],
							[
								9,
								18,
								"-"
							],
							[
								10,
								18,
								"-"
							],
							[
								11,
								18,
								"-"
							],
							[
								12,
								18,
								"-"
							],
							[
								13,
								18,
								"-"
							],
							[
								14,
								18,
								"-"
							],
							[
								15,
								18,
								"-"
							],
							[
								16,
								18,
								10
							],
							[
								17,
								18,
								6
							],
							[
								18,
								18,
								7
							],
							[
								19,
								18,
								6
							],
							[
								20,
								18,
								16
							],
							[
								21,
								18,
								9
							],
							[
								22,
								18,
								19
							],
							[
								23,
								18,
								14
							],
							[
								24,
								18,
								23
							],
							[
								25,
								18,
								19
							],
							[
								26,
								18,
								20
							],
							[
								27,
								18,
								20
							],
							[
								28,
								18,
								12
							],
							[
								29,
								18,
								8
							],
							[
								30,
								18,
								19
							],
							[
								0,
								17,
								"-"
							],
							[
								1,
								17,
								"-"
							],
							[
								2,
								17,
								"-"
							],
							[
								3,
								17,
								"-"
							],
							[
								4,
								17,
								"-"
							],
							[
								5,
								17,
								"-"
							],
							[
								6,
								17,
								"-"
							],
							[
								7,
								17,
								"-"
							],
							[
								8,
								17,
								"-"
							],
							[
								9,
								17,
								"-"
							],
							[
								10,
								17,
								"-"
							],
							[
								11,
								17,
								"-"
							],
							[
								12,
								17,
								"-"
							],
							[
								13,
								17,
								"-"
							],
							[
								14,
								17,
								"-"
							],
							[
								15,
								17,
								"-"
							],
							[
								16,
								17,
								6
							],
							[
								17,
								17,
								3
							],
							[
								18,
								17,
								12
							],
							[
								19,
								17,
								11
							],
							[
								20,
								17,
								15
							],
							[
								21,
								17,
								21
							],
							[
								22,
								17,
								20
							],
							[
								23,
								17,
								25
							],
							[
								24,
								17,
								18
							],
							[
								25,
								17,
								14
							],
							[
								26,
								17,
								20
							],
							[
								27,
								17,
								18
							],
							[
								28,
								17,
								26
							],
							[
								29,
								17,
								20
							],
							[
								30,
								17,
								13
							],
							[
								0,
								16,
								"-"
							],
							[
								1,
								16,
								"-"
							],
							[
								2,
								16,
								"-"
							],
							[
								3,
								16,
								"-"
							],
							[
								4,
								16,
								"-"
							],
							[
								5,
								16,
								"-"
							],
							[
								6,
								16,
								"-"
							],
							[
								7,
								16,
								"-"
							],
							[
								8,
								16,
								"-"
							],
							[
								9,
								16,
								"-"
							],
							[
								10,
								16,
								"-"
							],
							[
								11,
								16,
								"-"
							],
							[
								12,
								16,
								"-"
							],
							[
								13,
								16,
								"-"
							],
							[
								14,
								16,
								"-"
							],
							[
								15,
								16,
								"-"
							],
							[
								16,
								16,
								14
							],
							[
								17,
								16,
								18
							],
							[
								18,
								16,
								17
							],
							[
								19,
								16,
								16
							],
							[
								20,
								16,
								27
							],
							[
								21,
								16,
								16
							],
							[
								22,
								16,
								21
							],
							[
								23,
								16,
								19
							],
							[
								24,
								16,
								6
							],
							[
								25,
								16,
								16
							],
							[
								26,
								16,
								24
							],
							[
								27,
								16,
								31
							],
							[
								28,
								16,
								24
							],
							[
								29,
								16,
								16
							],
							[
								30,
								16,
								15
							],
							[
								0,
								15,
								"-"
							],
							[
								1,
								15,
								"-"
							],
							[
								2,
								15,
								"-"
							],
							[
								3,
								15,
								"-"
							],
							[
								4,
								15,
								"-"
							],
							[
								5,
								15,
								"-"
							],
							[
								6,
								15,
								"-"
							],
							[
								7,
								15,
								"-"
							],
							[
								8,
								15,
								"-"
							],
							[
								9,
								15,
								"-"
							],
							[
								10,
								15,
								"-"
							],
							[
								11,
								15,
								"-"
							],
							[
								12,
								15,
								"-"
							],
							[
								13,
								15,
								"-"
							],
							[
								14,
								15,
								"-"
							],
							[
								15,
								15,
								"-"
							],
							[
								16,
								15,
								20
							],
							[
								17,
								15,
								23
							],
							[
								18,
								15,
								16
							],
							[
								19,
								15,
								24
							],
							[
								20,
								15,
								22
							],
							[
								21,
								15,
								19
							],
							[
								22,
								15,
								29
							],
							[
								23,
								15,
								22
							],
							[
								24,
								15,
								32
							],
							[
								25,
								15,
								29
							],
							[
								26,
								15,
								34
							],
							[
								27,
								15,
								16
							],
							[
								28,
								15,
								28
							],
							[
								29,
								15,
								27
							],
							[
								30,
								15,
								20
							],
							[
								0,
								14,
								"-"
							],
							[
								1,
								14,
								"-"
							],
							[
								2,
								14,
								"-"
							],
							[
								3,
								14,
								"-"
							],
							[
								4,
								14,
								"-"
							],
							[
								5,
								14,
								"-"
							],
							[
								6,
								14,
								"-"
							],
							[
								7,
								14,
								"-"
							],
							[
								8,
								14,
								"-"
							],
							[
								9,
								14,
								"-"
							],
							[
								10,
								14,
								"-"
							],
							[
								11,
								14,
								"-"
							],
							[
								12,
								14,
								"-"
							],
							[
								13,
								14,
								"-"
							],
							[
								14,
								14,
								"-"
							],
							[
								15,
								14,
								"-"
							],
							[
								16,
								14,
								31
							],
							[
								17,
								14,
								23
							],
							[
								18,
								14,
								23
							],
							[
								19,
								14,
								28
							],
							[
								20,
								14,
								26
							],
							[
								21,
								14,
								10
							],
							[
								22,
								14,
								19
							],
							[
								23,
								14,
								16
							],
							[
								24,
								14,
								25
							],
							[
								25,
								14,
								6
							],
							[
								26,
								14,
								30
							],
							[
								27,
								14,
								17
							],
							[
								28,
								14,
								9
							],
							[
								29,
								14,
								13
							],
							[
								30,
								14,
								7
							],
							[
								0,
								13,
								21
							],
							[
								1,
								13,
								12
							],
							[
								2,
								13,
								18
							],
							[
								3,
								13,
								25
							],
							[
								4,
								13,
								23
							],
							[
								5,
								13,
								23
							],
							[
								6,
								13,
								19
							],
							[
								7,
								13,
								15
							],
							[
								8,
								13,
								22
							],
							[
								9,
								13,
								28
							],
							[
								10,
								13,
								22
							],
							[
								11,
								13,
								22
							],
							[
								12,
								13,
								11
							],
							[
								13,
								13,
								28
							],
							[
								14,
								13,
								22
							],
							[
								15,
								13,
								29
							],
							[
								16,
								13,
								24
							],
							[
								17,
								13,
								37
							],
							[
								18,
								13,
								31
							],
							[
								19,
								13,
								27
							],
							[
								20,
								13,
								14
							],
							[
								21,
								13,
								26
							],
							[
								22,
								13,
								27
							],
							[
								23,
								13,
								13
							],
							[
								24,
								13,
								5
							],
							[
								25,
								13,
								15
							],
							[
								26,
								13,
								12
							],
							[
								27,
								13,
								16
							],
							[
								28,
								13,
								17
							],
							[
								29,
								13,
								13
							],
							[
								30,
								13,
								11
							],
							[
								0,
								12,
								24
							],
							[
								1,
								12,
								27
							],
							[
								2,
								12,
								26
							],
							[
								3,
								12,
								18
							],
							[
								4,
								12,
								17
							],
							[
								5,
								12,
								27
							],
							[
								6,
								12,
								28
							],
							[
								7,
								12,
								18
							],
							[
								8,
								12,
								17
							],
							[
								9,
								12,
								2
							],
							[
								10,
								12,
								2
							],
							[
								11,
								12,
								"-"
							],
							[
								12,
								12,
								5
							],
							[
								13,
								12,
								"-"
							],
							[
								14,
								12,
								5
							],
							[
								15,
								12,
								"-"
							],
							[
								16,
								12,
								15
							],
							[
								17,
								12,
								25
							],
							[
								18,
								12,
								23
							],
							[
								19,
								12,
								18
							],
							[
								20,
								12,
								6
							],
							[
								21,
								12,
								17
							],
							[
								22,
								12,
								18
							],
							[
								23,
								12,
								30
							],
							[
								24,
								12,
								18
							],
							[
								25,
								12,
								18
							],
							[
								26,
								12,
								17
							],
							[
								27,
								12,
								6
							],
							[
								28,
								12,
								15
							],
							[
								29,
								12,
								"-"
							],
							[
								30,
								12,
								3
							],
							[
								0,
								11,
								"-"
							],
							[
								1,
								11,
								"-"
							],
							[
								2,
								11,
								"-"
							],
							[
								3,
								11,
								"-"
							],
							[
								4,
								11,
								"-"
							],
							[
								5,
								11,
								"-"
							],
							[
								6,
								11,
								"-"
							],
							[
								7,
								11,
								"-"
							],
							[
								8,
								11,
								"-"
							],
							[
								9,
								11,
								"-"
							],
							[
								10,
								11,
								"-"
							],
							[
								11,
								11,
								"-"
							],
							[
								12,
								11,
								"-"
							],
							[
								13,
								11,
								"-"
							],
							[
								14,
								11,
								"-"
							],
							[
								15,
								11,
								"-"
							],
							[
								16,
								11,
								"-"
							],
							[
								17,
								11,
								"-"
							],
							[
								18,
								11,
								"-"
							],
							[
								19,
								11,
								"-"
							],
							[
								20,
								11,
								"-"
							],
							[
								21,
								11,
								"-"
							],
							[
								22,
								11,
								"-"
							],
							[
								23,
								11,
								"-"
							],
							[
								24,
								11,
								"-"
							],
							[
								25,
								11,
								"-"
							],
							[
								26,
								11,
								"-"
							],
							[
								27,
								11,
								"-"
							],
							[
								28,
								11,
								"-"
							],
							[
								29,
								11,
								"-"
							],
							[
								30,
								11,
								"-"
							],
							[
								0,
								10,
								"-"
							],
							[
								1,
								10,
								"-"
							],
							[
								2,
								10,
								"-"
							],
							[
								3,
								10,
								"-"
							],
							[
								4,
								10,
								"-"
							],
							[
								5,
								10,
								"-"
							],
							[
								6,
								10,
								"-"
							],
							[
								7,
								10,
								"-"
							],
							[
								8,
								10,
								"-"
							],
							[
								9,
								10,
								"-"
							],
							[
								10,
								10,
								"-"
							],
							[
								11,
								10,
								"-"
							],
							[
								12,
								10,
								"-"
							],
							[
								13,
								10,
								"-"
							],
							[
								14,
								10,
								"-"
							],
							[
								15,
								10,
								"-"
							],
							[
								16,
								10,
								"-"
							],
							[
								17,
								10,
								"-"
							],
							[
								18,
								10,
								"-"
							],
							[
								19,
								10,
								"-"
							],
							[
								20,
								10,
								"-"
							],
							[
								21,
								10,
								"-"
							],
							[
								22,
								10,
								"-"
							],
							[
								23,
								10,
								"-"
							],
							[
								24,
								10,
								"-"
							],
							[
								25,
								10,
								"-"
							],
							[
								26,
								10,
								"-"
							],
							[
								27,
								10,
								"-"
							],
							[
								28,
								10,
								"-"
							],
							[
								29,
								10,
								"-"
							],
							[
								30,
								10,
								"-"
							],
							[
								0,
								9,
								"-"
							],
							[
								1,
								9,
								"-"
							],
							[
								2,
								9,
								"-"
							],
							[
								3,
								9,
								"-"
							],
							[
								4,
								9,
								"-"
							],
							[
								5,
								9,
								"-"
							],
							[
								6,
								9,
								"-"
							],
							[
								7,
								9,
								"-"
							],
							[
								8,
								9,
								"-"
							],
							[
								9,
								9,
								"-"
							],
							[
								10,
								9,
								"-"
							],
							[
								11,
								9,
								"-"
							],
							[
								12,
								9,
								"-"
							],
							[
								13,
								9,
								"-"
							],
							[
								14,
								9,
								"-"
							],
							[
								15,
								9,
								"-"
							],
							[
								16,
								9,
								13
							],
							[
								17,
								9,
								22
							],
							[
								18,
								9,
								14
							],
							[
								19,
								9,
								28
							],
							[
								20,
								9,
								28
							],
							[
								21,
								9,
								29
							],
							[
								22,
								9,
								19
							],
							[
								23,
								9,
								34
							],
							[
								24,
								9,
								29
							],
							[
								25,
								9,
								26
							],
							[
								26,
								9,
								24
							],
							[
								27,
								9,
								28
							],
							[
								28,
								9,
								24
							],
							[
								29,
								9,
								20
							],
							[
								30,
								9,
								14
							],
							[
								0,
								8,
								"-"
							],
							[
								1,
								8,
								"-"
							],
							[
								2,
								8,
								"-"
							],
							[
								3,
								8,
								"-"
							],
							[
								4,
								8,
								"-"
							],
							[
								5,
								8,
								"-"
							],
							[
								6,
								8,
								"-"
							],
							[
								7,
								8,
								"-"
							],
							[
								8,
								8,
								"-"
							],
							[
								9,
								8,
								"-"
							],
							[
								10,
								8,
								"-"
							],
							[
								11,
								8,
								"-"
							],
							[
								12,
								8,
								"-"
							],
							[
								13,
								8,
								"-"
							],
							[
								14,
								8,
								"-"
							],
							[
								15,
								8,
								"-"
							],
							[
								16,
								8,
								22
							],
							[
								17,
								8,
								28
							],
							[
								18,
								8,
								34
							],
							[
								19,
								8,
								23
							],
							[
								20,
								8,
								28
							],
							[
								21,
								8,
								30
							],
							[
								22,
								8,
								9
							],
							[
								23,
								8,
								14
							],
							[
								24,
								8,
								26
							],
							[
								25,
								8,
								27
							],
							[
								26,
								8,
								15
							],
							[
								27,
								8,
								22
							],
							[
								28,
								8,
								27
							],
							[
								29,
								8,
								28
							],
							[
								30,
								8,
								20
							],
							[
								0,
								7,
								"-"
							],
							[
								1,
								7,
								"-"
							],
							[
								2,
								7,
								"-"
							],
							[
								3,
								7,
								"-"
							],
							[
								4,
								7,
								"-"
							],
							[
								5,
								7,
								"-"
							],
							[
								6,
								7,
								"-"
							],
							[
								7,
								7,
								"-"
							],
							[
								8,
								7,
								"-"
							],
							[
								9,
								7,
								"-"
							],
							[
								10,
								7,
								"-"
							],
							[
								11,
								7,
								"-"
							],
							[
								12,
								7,
								"-"
							],
							[
								13,
								7,
								"-"
							],
							[
								14,
								7,
								"-"
							],
							[
								15,
								7,
								"-"
							],
							[
								16,
								7,
								18
							],
							[
								17,
								7,
								34
							],
							[
								18,
								7,
								26
							],
							[
								19,
								7,
								21
							],
							[
								20,
								7,
								7
							],
							[
								21,
								7,
								18
							],
							[
								22,
								7,
								14
							],
							[
								23,
								7,
								23
							],
							[
								24,
								7,
								"-"
							],
							[
								25,
								7,
								21
							],
							[
								26,
								7,
								16
							],
							[
								27,
								7,
								10
							],
							[
								28,
								7,
								10
							],
							[
								29,
								7,
								8
							],
							[
								30,
								7,
								4
							],
							[
								0,
								6,
								27
							],
							[
								1,
								6,
								26
							],
							[
								2,
								6,
								12
							],
							[
								3,
								6,
								14
							],
							[
								4,
								6,
								32
							],
							[
								5,
								6,
								17
							],
							[
								6,
								6,
								7
							],
							[
								7,
								6,
								6
							],
							[
								8,
								6,
								8
							],
							[
								9,
								6,
								2
							],
							[
								10,
								6,
								3
							],
							[
								11,
								6,
								"-"
							],
							[
								12,
								6,
								4
							],
							[
								13,
								6,
								9
							],
							[
								14,
								6,
								2
							],
							[
								15,
								6,
								19
							],
							[
								16,
								6,
								21
							],
							[
								17,
								6,
								20
							],
							[
								18,
								6,
								20
							],
							[
								19,
								6,
								26
							],
							[
								20,
								6,
								20
							],
							[
								21,
								6,
								14
							],
							[
								22,
								6,
								18
							],
							[
								23,
								6,
								13
							],
							[
								24,
								6,
								18
							],
							[
								25,
								6,
								6
							],
							[
								26,
								6,
								10
							],
							[
								27,
								6,
								14
							],
							[
								28,
								6,
								14
							],
							[
								29,
								6,
								3
							],
							[
								30,
								6,
								6
							],
							[
								0,
								5,
								"-"
							],
							[
								1,
								5,
								"-"
							],
							[
								2,
								5,
								"-"
							],
							[
								3,
								5,
								"-"
							],
							[
								4,
								5,
								"-"
							],
							[
								5,
								5,
								"-"
							],
							[
								6,
								5,
								"-"
							],
							[
								7,
								5,
								"-"
							],
							[
								8,
								5,
								"-"
							],
							[
								9,
								5,
								"-"
							],
							[
								10,
								5,
								"-"
							],
							[
								11,
								5,
								"-"
							],
							[
								12,
								5,
								"-"
							],
							[
								13,
								5,
								"-"
							],
							[
								14,
								5,
								"-"
							],
							[
								15,
								5,
								"-"
							],
							[
								16,
								5,
								"-"
							],
							[
								17,
								5,
								"-"
							],
							[
								18,
								5,
								"-"
							],
							[
								19,
								5,
								"-"
							],
							[
								20,
								5,
								"-"
							],
							[
								21,
								5,
								"-"
							],
							[
								22,
								5,
								"-"
							],
							[
								23,
								5,
								"-"
							],
							[
								24,
								5,
								"-"
							],
							[
								25,
								5,
								"-"
							],
							[
								26,
								5,
								"-"
							],
							[
								27,
								5,
								"-"
							],
							[
								28,
								5,
								"-"
							],
							[
								29,
								5,
								"-"
							],
							[
								30,
								5,
								"-"
							],
							[
								0,
								4,
								"-"
							],
							[
								1,
								4,
								"-"
							],
							[
								2,
								4,
								"-"
							],
							[
								3,
								4,
								"-"
							],
							[
								4,
								4,
								"-"
							],
							[
								5,
								4,
								"-"
							],
							[
								6,
								4,
								"-"
							],
							[
								7,
								4,
								"-"
							],
							[
								8,
								4,
								"-"
							],
							[
								9,
								4,
								"-"
							],
							[
								10,
								4,
								"-"
							],
							[
								11,
								4,
								"-"
							],
							[
								12,
								4,
								"-"
							],
							[
								13,
								4,
								"-"
							],
							[
								14,
								4,
								"-"
							],
							[
								15,
								4,
								"-"
							],
							[
								16,
								4,
								"-"
							],
							[
								17,
								4,
								"-"
							],
							[
								18,
								4,
								"-"
							],
							[
								19,
								4,
								"-"
							],
							[
								20,
								4,
								"-"
							],
							[
								21,
								4,
								"-"
							],
							[
								22,
								4,
								"-"
							],
							[
								23,
								4,
								"-"
							],
							[
								24,
								4,
								"-"
							],
							[
								25,
								4,
								"-"
							],
							[
								26,
								4,
								"-"
							],
							[
								27,
								4,
								"-"
							],
							[
								28,
								4,
								"-"
							],
							[
								29,
								4,
								"-"
							],
							[
								30,
								4,
								"-"
							],
							[
								0,
								3,
								"-"
							],
							[
								1,
								3,
								"-"
							],
							[
								2,
								3,
								"-"
							],
							[
								3,
								3,
								"-"
							],
							[
								4,
								3,
								"-"
							],
							[
								5,
								3,
								"-"
							],
							[
								6,
								3,
								"-"
							],
							[
								7,
								3,
								"-"
							],
							[
								8,
								3,
								"-"
							],
							[
								9,
								3,
								"-"
							],
							[
								10,
								3,
								"-"
							],
							[
								11,
								3,
								"-"
							],
							[
								12,
								3,
								"-"
							],
							[
								13,
								3,
								"-"
							],
							[
								14,
								3,
								"-"
							],
							[
								15,
								3,
								"-"
							],
							[
								16,
								3,
								24
							],
							[
								17,
								3,
								1
							],
							[
								18,
								3,
								12
							],
							[
								19,
								3,
								"-"
							],
							[
								20,
								3,
								10
							],
							[
								21,
								3,
								12
							],
							[
								22,
								3,
								16
							],
							[
								23,
								3,
								18
							],
							[
								24,
								3,
								26
							],
							[
								25,
								3,
								10
							],
							[
								26,
								3,
								12
							],
							[
								27,
								3,
								10
							],
							[
								28,
								3,
								14
							],
							[
								29,
								3,
								9
							],
							[
								30,
								3,
								23
							],
							[
								0,
								2,
								"-"
							],
							[
								1,
								2,
								"-"
							],
							[
								2,
								2,
								"-"
							],
							[
								3,
								2,
								"-"
							],
							[
								4,
								2,
								"-"
							],
							[
								5,
								2,
								"-"
							],
							[
								6,
								2,
								"-"
							],
							[
								7,
								2,
								"-"
							],
							[
								8,
								2,
								"-"
							],
							[
								9,
								2,
								"-"
							],
							[
								10,
								2,
								"-"
							],
							[
								11,
								2,
								"-"
							],
							[
								12,
								2,
								"-"
							],
							[
								13,
								2,
								"-"
							],
							[
								14,
								2,
								"-"
							],
							[
								15,
								2,
								"-"
							],
							[
								16,
								2,
								25
							],
							[
								17,
								2,
								"-"
							],
							[
								18,
								2,
								14
							],
							[
								19,
								2,
								9
							],
							[
								20,
								2,
								19
							],
							[
								21,
								2,
								15
							],
							[
								22,
								2,
								10
							],
							[
								23,
								2,
								27
							],
							[
								24,
								2,
								12
							],
							[
								25,
								2,
								21
							],
							[
								26,
								2,
								25
							],
							[
								27,
								2,
								9
							],
							[
								28,
								2,
								15
							],
							[
								29,
								2,
								"-"
							],
							[
								30,
								2,
								7
							],
							[
								0,
								1,
								"-"
							],
							[
								1,
								1,
								"-"
							],
							[
								2,
								1,
								"-"
							],
							[
								3,
								1,
								"-"
							],
							[
								4,
								1,
								"-"
							],
							[
								5,
								1,
								"-"
							],
							[
								6,
								1,
								"-"
							],
							[
								7,
								1,
								"-"
							],
							[
								8,
								1,
								"-"
							],
							[
								9,
								1,
								"-"
							],
							[
								10,
								1,
								"-"
							],
							[
								11,
								1,
								"-"
							],
							[
								12,
								1,
								"-"
							],
							[
								13,
								1,
								"-"
							],
							[
								14,
								1,
								"-"
							],
							[
								15,
								1,
								"-"
							],
							[
								16,
								1,
								12
							],
							[
								17,
								1,
								6
							],
							[
								18,
								1,
								12
							],
							[
								19,
								1,
								7
							],
							[
								20,
								1,
								3
							],
							[
								21,
								1,
								9
							],
							[
								22,
								1,
								20
							],
							[
								23,
								1,
								7
							],
							[
								24,
								1,
								6
							],
							[
								25,
								1,
								2
							],
							[
								26,
								1,
								8
							],
							[
								27,
								1,
								1
							],
							[
								28,
								1,
								2
							],
							[
								29,
								1,
								"-"
							],
							[
								30,
								1,
								2
							],
							[
								0,
								0,
								"-"
							],
							[
								1,
								0,
								"-"
							],
							[
								2,
								0,
								"-"
							],
							[
								3,
								0,
								"-"
							],
							[
								4,
								0,
								"-"
							],
							[
								5,
								0,
								"-"
							],
							[
								6,
								0,
								"-"
							],
							[
								7,
								0,
								"-"
							],
							[
								8,
								0,
								"-"
							],
							[
								9,
								0,
								"-"
							],
							[
								10,
								0,
								"-"
							],
							[
								11,
								0,
								"-"
							],
							[
								12,
								0,
								"-"
							],
							[
								13,
								0,
								"-"
							],
							[
								14,
								0,
								"-"
							],
							[
								15,
								0,
								"-"
							],
							[
								16,
								0,
								13
							],
							[
								17,
								0,
								"-"
							],
							[
								18,
								0,
								4
							],
							[
								19,
								0,
								"-"
							],
							[
								20,
								0,
								16
							],
							[
								21,
								0,
								"-"
							],
							[
								22,
								0,
								2
							],
							[
								23,
								0,
								"-"
							],
							[
								24,
								0,
								6
							],
							[
								25,
								0,
								"-"
							],
							[
								26,
								0,
								2
							],
							[
								27,
								0,
								"-"
							],
							[
								28,
								0,
								2
							],
							[
								29,
								0,
								"-"
							],
							[
								30,
								0,
								"-"
							]
						],
						"label": {
							"show": false
						},
						"emphasis": {
							"itemStyle": {
								"shadowBlur": 10,
								"shadowColor": "rgba(0, 0, 0, 0.5)"
							}
						}
					},
					{
						"name": "Personas con reserva",
						"type": "heatmap",
						"data": [
							[
								0,
								21,
								"-"
							],
							[
								1,
								21,
								"-"
							],
							[
								2,
								21,
								"-"
							],
							[
								3,
								21,
								"-"
							],
							[
								4,
								21,
								"-"
							],
							[
								5,
								21,
								"-"
							],
							[
								6,
								21,
								"-"
							],
							[
								7,
								21,
								"-"
							],
							[
								8,
								21,
								"-"
							],
							[
								9,
								21,
								"-"
							],
							[
								10,
								21,
								"-"
							],
							[
								11,
								21,
								"-"
							],
							[
								12,
								21,
								"-"
							],
							[
								13,
								21,
								"-"
							],
							[
								14,
								21,
								"-"
							],
							[
								15,
								21,
								"-"
							],
							[
								16,
								21,
								20
							],
							[
								17,
								21,
								13
							],
							[
								18,
								21,
								3
							],
							[
								19,
								21,
								7
							],
							[
								20,
								21,
								22
							],
							[
								21,
								21,
								21
							],
							[
								22,
								21,
								24
							],
							[
								23,
								21,
								20
							],
							[
								24,
								21,
								21
							],
							[
								25,
								21,
								20
							],
							[
								26,
								21,
								20
							],
							[
								27,
								21,
								23
							],
							[
								28,
								21,
								21
							],
							[
								29,
								21,
								22
							],
							[
								30,
								21,
								20
							],
							[
								0,
								20,
								20
							],
							[
								1,
								20,
								20
							],
							[
								2,
								20,
								28
							],
							[
								3,
								20,
								20
							],
							[
								4,
								20,
								20
							],
							[
								5,
								20,
								22
							],
							[
								6,
								20,
								26
							],
							[
								7,
								20,
								21
							],
							[
								8,
								20,
								30
							],
							[
								9,
								20,
								18
							],
							[
								10,
								20,
								17
							],
							[
								11,
								20,
								23
							],
							[
								12,
								20,
								21
							],
							[
								13,
								20,
								21
							],
							[
								14,
								20,
								22
							],
							[
								15,
								20,
								22
							],
							[
								16,
								20,
								21
							],
							[
								17,
								20,
								21
							],
							[
								18,
								20,
								21
							],
							[
								19,
								20,
								21
							],
							[
								20,
								20,
								20
							],
							[
								21,
								20,
								23
							],
							[
								22,
								20,
								21
							],
							[
								23,
								20,
								22
							],
							[
								24,
								20,
								22
							],
							[
								25,
								20,
								20
							],
							[
								26,
								20,
								21
							],
							[
								27,
								20,
								21
							],
							[
								28,
								20,
								22
							],
							[
								29,
								20,
								25
							],
							[
								30,
								20,
								21
							],
							[
								0,
								19,
								24
							],
							[
								1,
								19,
								23
							],
							[
								2,
								19,
								20
							],
							[
								3,
								19,
								20
							],
							[
								4,
								19,
								21
							],
							[
								5,
								19,
								22
							],
							[
								6,
								19,
								20
							],
							[
								7,
								19,
								22
							],
							[
								8,
								19,
								20
							],
							[
								9,
								19,
								21
							],
							[
								10,
								19,
								20
							],
							[
								11,
								19,
								26
							],
							[
								12,
								19,
								21
							],
							[
								13,
								19,
								20
							],
							[
								14,
								19,
								23
							],
							[
								15,
								19,
								25
							],
							[
								16,
								19,
								20
							],
							[
								17,
								19,
								22
							],
							[
								18,
								19,
								20
							],
							[
								19,
								19,
								21
							],
							[
								20,
								19,
								27
							],
							[
								21,
								19,
								21
							],
							[
								22,
								19,
								21
							],
							[
								23,
								19,
								21
							],
							[
								24,
								19,
								22
							],
							[
								25,
								19,
								20
							],
							[
								26,
								19,
								22
							],
							[
								27,
								19,
								21
							],
							[
								28,
								19,
								22
							],
							[
								29,
								19,
								21
							],
							[
								30,
								19,
								23
							],
							[
								0,
								18,
								"-"
							],
							[
								1,
								18,
								"-"
							],
							[
								2,
								18,
								"-"
							],
							[
								3,
								18,
								"-"
							],
							[
								4,
								18,
								"-"
							],
							[
								5,
								18,
								"-"
							],
							[
								6,
								18,
								"-"
							],
							[
								7,
								18,
								"-"
							],
							[
								8,
								18,
								"-"
							],
							[
								9,
								18,
								"-"
							],
							[
								10,
								18,
								"-"
							],
							[
								11,
								18,
								"-"
							],
							[
								12,
								18,
								"-"
							],
							[
								13,
								18,
								"-"
							],
							[
								14,
								18,
								"-"
							],
							[
								15,
								18,
								"-"
							],
							[
								16,
								18,
								22
							],
							[
								17,
								18,
								20
							],
							[
								18,
								18,
								10
							],
							[
								19,
								18,
								10
							],
							[
								20,
								18,
								18
							],
							[
								21,
								18,
								20
							],
							[
								22,
								18,
								24
							],
							[
								23,
								18,
								22
							],
							[
								24,
								18,
								28
							],
							[
								25,
								18,
								22
							],
							[
								26,
								18,
								22
							],
							[
								27,
								18,
								21
							],
							[
								28,
								18,
								21
							],
							[
								29,
								18,
								22
							],
							[
								30,
								18,
								23
							],
							[
								0,
								17,
								"-"
							],
							[
								1,
								17,
								"-"
							],
							[
								2,
								17,
								"-"
							],
							[
								3,
								17,
								"-"
							],
							[
								4,
								17,
								"-"
							],
							[
								5,
								17,
								"-"
							],
							[
								6,
								17,
								"-"
							],
							[
								7,
								17,
								"-"
							],
							[
								8,
								17,
								"-"
							],
							[
								9,
								17,
								"-"
							],
							[
								10,
								17,
								"-"
							],
							[
								11,
								17,
								"-"
							],
							[
								12,
								17,
								"-"
							],
							[
								13,
								17,
								"-"
							],
							[
								14,
								17,
								"-"
							],
							[
								15,
								17,
								"-"
							],
							[
								16,
								17,
								34
							],
							[
								17,
								17,
								4
							],
							[
								18,
								17,
								20
							],
							[
								19,
								17,
								26
							],
							[
								20,
								17,
								25
							],
							[
								21,
								17,
								25
							],
							[
								22,
								17,
								22
							],
							[
								23,
								17,
								27
							],
							[
								24,
								17,
								32
							],
							[
								25,
								17,
								25
							],
							[
								26,
								17,
								24
							],
							[
								27,
								17,
								20
							],
							[
								28,
								17,
								30
							],
							[
								29,
								17,
								20
							],
							[
								30,
								17,
								23
							],
							[
								0,
								16,
								"-"
							],
							[
								1,
								16,
								"-"
							],
							[
								2,
								16,
								"-"
							],
							[
								3,
								16,
								"-"
							],
							[
								4,
								16,
								"-"
							],
							[
								5,
								16,
								"-"
							],
							[
								6,
								16,
								"-"
							],
							[
								7,
								16,
								"-"
							],
							[
								8,
								16,
								"-"
							],
							[
								9,
								16,
								"-"
							],
							[
								10,
								16,
								"-"
							],
							[
								11,
								16,
								"-"
							],
							[
								12,
								16,
								"-"
							],
							[
								13,
								16,
								"-"
							],
							[
								14,
								16,
								"-"
							],
							[
								15,
								16,
								"-"
							],
							[
								16,
								16,
								25
							],
							[
								17,
								16,
								27
							],
							[
								18,
								16,
								19
							],
							[
								19,
								16,
								21
							],
							[
								20,
								16,
								29
							],
							[
								21,
								16,
								23
							],
							[
								22,
								16,
								26
							],
							[
								23,
								16,
								23
							],
							[
								24,
								16,
								19
							],
							[
								25,
								16,
								31
							],
							[
								26,
								16,
								35
							],
							[
								27,
								16,
								34
							],
							[
								28,
								16,
								28
							],
							[
								29,
								16,
								27
							],
							[
								30,
								16,
								38
							],
							[
								0,
								15,
								"-"
							],
							[
								1,
								15,
								"-"
							],
							[
								2,
								15,
								"-"
							],
							[
								3,
								15,
								"-"
							],
							[
								4,
								15,
								"-"
							],
							[
								5,
								15,
								"-"
							],
							[
								6,
								15,
								"-"
							],
							[
								7,
								15,
								"-"
							],
							[
								8,
								15,
								"-"
							],
							[
								9,
								15,
								"-"
							],
							[
								10,
								15,
								"-"
							],
							[
								11,
								15,
								"-"
							],
							[
								12,
								15,
								"-"
							],
							[
								13,
								15,
								"-"
							],
							[
								14,
								15,
								"-"
							],
							[
								15,
								15,
								"-"
							],
							[
								16,
								15,
								41
							],
							[
								17,
								15,
								37
							],
							[
								18,
								15,
								37
							],
							[
								19,
								15,
								34
							],
							[
								20,
								15,
								33
							],
							[
								21,
								15,
								35
							],
							[
								22,
								15,
								39
							],
							[
								23,
								15,
								38
							],
							[
								24,
								15,
								36
							],
							[
								25,
								15,
								37
							],
							[
								26,
								15,
								37
							],
							[
								27,
								15,
								31
							],
							[
								28,
								15,
								33
							],
							[
								29,
								15,
								31
							],
							[
								30,
								15,
								34
							],
							[
								0,
								14,
								"-"
							],
							[
								1,
								14,
								"-"
							],
							[
								2,
								14,
								"-"
							],
							[
								3,
								14,
								"-"
							],
							[
								4,
								14,
								"-"
							],
							[
								5,
								14,
								"-"
							],
							[
								6,
								14,
								"-"
							],
							[
								7,
								14,
								"-"
							],
							[
								8,
								14,
								"-"
							],
							[
								9,
								14,
								"-"
							],
							[
								10,
								14,
								"-"
							],
							[
								11,
								14,
								"-"
							],
							[
								12,
								14,
								"-"
							],
							[
								13,
								14,
								"-"
							],
							[
								14,
								14,
								"-"
							],
							[
								15,
								14,
								"-"
							],
							[
								16,
								14,
								37
							],
							[
								17,
								14,
								35
							],
							[
								18,
								14,
								38
							],
							[
								19,
								14,
								38
							],
							[
								20,
								14,
								40
							],
							[
								21,
								14,
								21
							],
							[
								22,
								14,
								40
							],
							[
								23,
								14,
								21
							],
							[
								24,
								14,
								30
							],
							[
								25,
								14,
								24
							],
							[
								26,
								14,
								33
							],
							[
								27,
								14,
								29
							],
							[
								28,
								14,
								21
							],
							[
								29,
								14,
								21
							],
							[
								30,
								14,
								25
							],
							[
								0,
								13,
								37
							],
							[
								1,
								13,
								35
							],
							[
								2,
								13,
								35
							],
							[
								3,
								13,
								37
							],
							[
								4,
								13,
								36
							],
							[
								5,
								13,
								36
							],
							[
								6,
								13,
								35
							],
							[
								7,
								13,
								37
							],
							[
								8,
								13,
								36
							],
							[
								9,
								13,
								35
							],
							[
								10,
								13,
								35
							],
							[
								11,
								13,
								39
							],
							[
								12,
								13,
								37
							],
							[
								13,
								13,
								38
							],
							[
								14,
								13,
								42
							],
							[
								15,
								13,
								38
							],
							[
								16,
								13,
								50
							],
							[
								17,
								13,
								37
							],
							[
								18,
								13,
								36
							],
							[
								19,
								13,
								37
							],
							[
								20,
								13,
								22
							],
							[
								21,
								13,
								31
							],
							[
								22,
								13,
								35
							],
							[
								23,
								13,
								22
							],
							[
								24,
								13,
								22
							],
							[
								25,
								13,
								23
							],
							[
								26,
								13,
								20
							],
							[
								27,
								13,
								22
							],
							[
								28,
								13,
								23
							],
							[
								29,
								13,
								20
							],
							[
								30,
								13,
								26
							],
							[
								0,
								12,
								38
							],
							[
								1,
								12,
								30
							],
							[
								2,
								12,
								36
							],
							[
								3,
								12,
								33
							],
							[
								4,
								12,
								32
							],
							[
								5,
								12,
								30
							],
							[
								6,
								12,
								37
							],
							[
								7,
								12,
								35
							],
							[
								8,
								12,
								24
							],
							[
								9,
								12,
								2
							],
							[
								10,
								12,
								6
							],
							[
								11,
								12,
								3
							],
							[
								12,
								12,
								5
							],
							[
								13,
								12,
								"-"
							],
							[
								14,
								12,
								9
							],
							[
								15,
								12,
								"-"
							],
							[
								16,
								12,
								36
							],
							[
								17,
								12,
								25
							],
							[
								18,
								12,
								32
							],
							[
								19,
								12,
								29
							],
							[
								20,
								12,
								35
							],
							[
								21,
								12,
								34
							],
							[
								22,
								12,
								36
							],
							[
								23,
								12,
								34
							],
							[
								24,
								12,
								35
							],
							[
								25,
								12,
								25
							],
							[
								26,
								12,
								36
							],
							[
								27,
								12,
								9
							],
							[
								28,
								12,
								19
							],
							[
								29,
								12,
								"-"
							],
							[
								30,
								12,
								20
							],
							[
								0,
								11,
								"-"
							],
							[
								1,
								11,
								"-"
							],
							[
								2,
								11,
								"-"
							],
							[
								3,
								11,
								"-"
							],
							[
								4,
								11,
								"-"
							],
							[
								5,
								11,
								"-"
							],
							[
								6,
								11,
								"-"
							],
							[
								7,
								11,
								"-"
							],
							[
								8,
								11,
								"-"
							],
							[
								9,
								11,
								"-"
							],
							[
								10,
								11,
								"-"
							],
							[
								11,
								11,
								"-"
							],
							[
								12,
								11,
								"-"
							],
							[
								13,
								11,
								"-"
							],
							[
								14,
								11,
								"-"
							],
							[
								15,
								11,
								"-"
							],
							[
								16,
								11,
								"-"
							],
							[
								17,
								11,
								"-"
							],
							[
								18,
								11,
								"-"
							],
							[
								19,
								11,
								"-"
							],
							[
								20,
								11,
								"-"
							],
							[
								21,
								11,
								"-"
							],
							[
								22,
								11,
								"-"
							],
							[
								23,
								11,
								"-"
							],
							[
								24,
								11,
								"-"
							],
							[
								25,
								11,
								"-"
							],
							[
								26,
								11,
								"-"
							],
							[
								27,
								11,
								"-"
							],
							[
								28,
								11,
								"-"
							],
							[
								29,
								11,
								"-"
							],
							[
								30,
								11,
								"-"
							],
							[
								0,
								10,
								"-"
							],
							[
								1,
								10,
								"-"
							],
							[
								2,
								10,
								"-"
							],
							[
								3,
								10,
								"-"
							],
							[
								4,
								10,
								"-"
							],
							[
								5,
								10,
								"-"
							],
							[
								6,
								10,
								"-"
							],
							[
								7,
								10,
								"-"
							],
							[
								8,
								10,
								"-"
							],
							[
								9,
								10,
								"-"
							],
							[
								10,
								10,
								"-"
							],
							[
								11,
								10,
								"-"
							],
							[
								12,
								10,
								"-"
							],
							[
								13,
								10,
								"-"
							],
							[
								14,
								10,
								"-"
							],
							[
								15,
								10,
								"-"
							],
							[
								16,
								10,
								"-"
							],
							[
								17,
								10,
								"-"
							],
							[
								18,
								10,
								"-"
							],
							[
								19,
								10,
								"-"
							],
							[
								20,
								10,
								"-"
							],
							[
								21,
								10,
								"-"
							],
							[
								22,
								10,
								"-"
							],
							[
								23,
								10,
								"-"
							],
							[
								24,
								10,
								"-"
							],
							[
								25,
								10,
								"-"
							],
							[
								26,
								10,
								"-"
							],
							[
								27,
								10,
								"-"
							],
							[
								28,
								10,
								"-"
							],
							[
								29,
								10,
								"-"
							],
							[
								30,
								10,
								"-"
							],
							[
								0,
								9,
								"-"
							],
							[
								1,
								9,
								"-"
							],
							[
								2,
								9,
								"-"
							],
							[
								3,
								9,
								"-"
							],
							[
								4,
								9,
								"-"
							],
							[
								5,
								9,
								"-"
							],
							[
								6,
								9,
								"-"
							],
							[
								7,
								9,
								"-"
							],
							[
								8,
								9,
								"-"
							],
							[
								9,
								9,
								"-"
							],
							[
								10,
								9,
								"-"
							],
							[
								11,
								9,
								"-"
							],
							[
								12,
								9,
								"-"
							],
							[
								13,
								9,
								"-"
							],
							[
								14,
								9,
								"-"
							],
							[
								15,
								9,
								"-"
							],
							[
								16,
								9,
								34
							],
							[
								17,
								9,
								29
							],
							[
								18,
								9,
								30
							],
							[
								19,
								9,
								38
							],
							[
								20,
								9,
								36
							],
							[
								21,
								9,
								35
							],
							[
								22,
								9,
								38
							],
							[
								23,
								9,
								38
							],
							[
								24,
								9,
								35
							],
							[
								25,
								9,
								36
							],
							[
								26,
								9,
								36
							],
							[
								27,
								9,
								35
							],
							[
								28,
								9,
								38
							],
							[
								29,
								9,
								31
							],
							[
								30,
								9,
								36
							],
							[
								0,
								8,
								"-"
							],
							[
								1,
								8,
								"-"
							],
							[
								2,
								8,
								"-"
							],
							[
								3,
								8,
								"-"
							],
							[
								4,
								8,
								"-"
							],
							[
								5,
								8,
								"-"
							],
							[
								6,
								8,
								"-"
							],
							[
								7,
								8,
								"-"
							],
							[
								8,
								8,
								"-"
							],
							[
								9,
								8,
								"-"
							],
							[
								10,
								8,
								"-"
							],
							[
								11,
								8,
								"-"
							],
							[
								12,
								8,
								"-"
							],
							[
								13,
								8,
								"-"
							],
							[
								14,
								8,
								"-"
							],
							[
								15,
								8,
								"-"
							],
							[
								16,
								8,
								39
							],
							[
								17,
								8,
								37
							],
							[
								18,
								8,
								36
							],
							[
								19,
								8,
								34
							],
							[
								20,
								8,
								36
							],
							[
								21,
								8,
								37
							],
							[
								22,
								8,
								36
							],
							[
								23,
								8,
								36
							],
							[
								24,
								8,
								38
							],
							[
								25,
								8,
								34
							],
							[
								26,
								8,
								35
							],
							[
								27,
								8,
								39
							],
							[
								28,
								8,
								39
							],
							[
								29,
								8,
								36
							],
							[
								30,
								8,
								38
							],
							[
								0,
								7,
								"-"
							],
							[
								1,
								7,
								"-"
							],
							[
								2,
								7,
								"-"
							],
							[
								3,
								7,
								"-"
							],
							[
								4,
								7,
								"-"
							],
							[
								5,
								7,
								"-"
							],
							[
								6,
								7,
								"-"
							],
							[
								7,
								7,
								"-"
							],
							[
								8,
								7,
								"-"
							],
							[
								9,
								7,
								"-"
							],
							[
								10,
								7,
								"-"
							],
							[
								11,
								7,
								"-"
							],
							[
								12,
								7,
								"-"
							],
							[
								13,
								7,
								"-"
							],
							[
								14,
								7,
								"-"
							],
							[
								15,
								7,
								"-"
							],
							[
								16,
								7,
								36
							],
							[
								17,
								7,
								36
							],
							[
								18,
								7,
								35
							],
							[
								19,
								7,
								32
							],
							[
								20,
								7,
								20
							],
							[
								21,
								7,
								20
							],
							[
								22,
								7,
								20
							],
							[
								23,
								7,
								23
							],
							[
								24,
								7,
								24
							],
							[
								25,
								7,
								21
							],
							[
								26,
								7,
								18
							],
							[
								27,
								7,
								19
							],
							[
								28,
								7,
								20
							],
							[
								29,
								7,
								21
							],
							[
								30,
								7,
								22
							],
							[
								0,
								6,
								36
							],
							[
								1,
								6,
								38
							],
							[
								2,
								6,
								35
							],
							[
								3,
								6,
								45
							],
							[
								4,
								6,
								35
							],
							[
								5,
								6,
								20
							],
							[
								6,
								6,
								7
							],
							[
								7,
								6,
								6
							],
							[
								8,
								6,
								18
							],
							[
								9,
								6,
								2
							],
							[
								10,
								6,
								3
							],
							[
								11,
								6,
								"-"
							],
							[
								12,
								6,
								4
							],
							[
								13,
								6,
								9
							],
							[
								14,
								6,
								2
							],
							[
								15,
								6,
								20
							],
							[
								16,
								6,
								36
							],
							[
								17,
								6,
								35
							],
							[
								18,
								6,
								36
							],
							[
								19,
								6,
								42
							],
							[
								20,
								6,
								23
							],
							[
								21,
								6,
								20
							],
							[
								22,
								6,
								22
							],
							[
								23,
								6,
								21
							],
							[
								24,
								6,
								22
							],
							[
								25,
								6,
								20
							],
							[
								26,
								6,
								23
							],
							[
								27,
								6,
								20
							],
							[
								28,
								6,
								18
							],
							[
								29,
								6,
								18
							],
							[
								30,
								6,
								23
							],
							[
								0,
								5,
								"-"
							],
							[
								1,
								5,
								"-"
							],
							[
								2,
								5,
								"-"
							],
							[
								3,
								5,
								"-"
							],
							[
								4,
								5,
								"-"
							],
							[
								5,
								5,
								"-"
							],
							[
								6,
								5,
								"-"
							],
							[
								7,
								5,
								"-"
							],
							[
								8,
								5,
								"-"
							],
							[
								9,
								5,
								"-"
							],
							[
								10,
								5,
								"-"
							],
							[
								11,
								5,
								"-"
							],
							[
								12,
								5,
								"-"
							],
							[
								13,
								5,
								"-"
							],
							[
								14,
								5,
								"-"
							],
							[
								15,
								5,
								"-"
							],
							[
								16,
								5,
								"-"
							],
							[
								17,
								5,
								"-"
							],
							[
								18,
								5,
								"-"
							],
							[
								19,
								5,
								"-"
							],
							[
								20,
								5,
								"-"
							],
							[
								21,
								5,
								"-"
							],
							[
								22,
								5,
								"-"
							],
							[
								23,
								5,
								"-"
							],
							[
								24,
								5,
								"-"
							],
							[
								25,
								5,
								"-"
							],
							[
								26,
								5,
								"-"
							],
							[
								27,
								5,
								"-"
							],
							[
								28,
								5,
								"-"
							],
							[
								29,
								5,
								"-"
							],
							[
								30,
								5,
								"-"
							],
							[
								0,
								4,
								"-"
							],
							[
								1,
								4,
								"-"
							],
							[
								2,
								4,
								"-"
							],
							[
								3,
								4,
								"-"
							],
							[
								4,
								4,
								"-"
							],
							[
								5,
								4,
								"-"
							],
							[
								6,
								4,
								"-"
							],
							[
								7,
								4,
								"-"
							],
							[
								8,
								4,
								"-"
							],
							[
								9,
								4,
								"-"
							],
							[
								10,
								4,
								"-"
							],
							[
								11,
								4,
								"-"
							],
							[
								12,
								4,
								"-"
							],
							[
								13,
								4,
								"-"
							],
							[
								14,
								4,
								"-"
							],
							[
								15,
								4,
								"-"
							],
							[
								16,
								4,
								"-"
							],
							[
								17,
								4,
								"-"
							],
							[
								18,
								4,
								"-"
							],
							[
								19,
								4,
								"-"
							],
							[
								20,
								4,
								"-"
							],
							[
								21,
								4,
								"-"
							],
							[
								22,
								4,
								"-"
							],
							[
								23,
								4,
								"-"
							],
							[
								24,
								4,
								"-"
							],
							[
								25,
								4,
								"-"
							],
							[
								26,
								4,
								"-"
							],
							[
								27,
								4,
								"-"
							],
							[
								28,
								4,
								"-"
							],
							[
								29,
								4,
								"-"
							],
							[
								30,
								4,
								"-"
							],
							[
								0,
								3,
								"-"
							],
							[
								1,
								3,
								"-"
							],
							[
								2,
								3,
								"-"
							],
							[
								3,
								3,
								"-"
							],
							[
								4,
								3,
								"-"
							],
							[
								5,
								3,
								"-"
							],
							[
								6,
								3,
								"-"
							],
							[
								7,
								3,
								"-"
							],
							[
								8,
								3,
								"-"
							],
							[
								9,
								3,
								"-"
							],
							[
								10,
								3,
								"-"
							],
							[
								11,
								3,
								"-"
							],
							[
								12,
								3,
								"-"
							],
							[
								13,
								3,
								"-"
							],
							[
								14,
								3,
								"-"
							],
							[
								15,
								3,
								"-"
							],
							[
								16,
								3,
								36
							],
							[
								17,
								3,
								8
							],
							[
								18,
								3,
								16
							],
							[
								19,
								3,
								"-"
							],
							[
								20,
								3,
								33
							],
							[
								21,
								3,
								27
							],
							[
								22,
								3,
								36
							],
							[
								23,
								3,
								21
							],
							[
								24,
								3,
								36
							],
							[
								25,
								3,
								24
							],
							[
								26,
								3,
								21
							],
							[
								27,
								3,
								10
							],
							[
								28,
								3,
								23
							],
							[
								29,
								3,
								12
							],
							[
								30,
								3,
								36
							],
							[
								0,
								2,
								"-"
							],
							[
								1,
								2,
								"-"
							],
							[
								2,
								2,
								"-"
							],
							[
								3,
								2,
								"-"
							],
							[
								4,
								2,
								"-"
							],
							[
								5,
								2,
								"-"
							],
							[
								6,
								2,
								"-"
							],
							[
								7,
								2,
								"-"
							],
							[
								8,
								2,
								"-"
							],
							[
								9,
								2,
								"-"
							],
							[
								10,
								2,
								"-"
							],
							[
								11,
								2,
								"-"
							],
							[
								12,
								2,
								"-"
							],
							[
								13,
								2,
								"-"
							],
							[
								14,
								2,
								"-"
							],
							[
								15,
								2,
								"-"
							],
							[
								16,
								2,
								37
							],
							[
								17,
								2,
								6
							],
							[
								18,
								2,
								20
							],
							[
								19,
								2,
								15
							],
							[
								20,
								2,
								37
							],
							[
								21,
								2,
								33
							],
							[
								22,
								2,
								36
							],
							[
								23,
								2,
								36
							],
							[
								24,
								2,
								34
							],
							[
								25,
								2,
								28
							],
							[
								26,
								2,
								37
							],
							[
								27,
								2,
								13
							],
							[
								28,
								2,
								17
							],
							[
								29,
								2,
								"-"
							],
							[
								30,
								2,
								9
							],
							[
								0,
								1,
								"-"
							],
							[
								1,
								1,
								"-"
							],
							[
								2,
								1,
								"-"
							],
							[
								3,
								1,
								"-"
							],
							[
								4,
								1,
								"-"
							],
							[
								5,
								1,
								"-"
							],
							[
								6,
								1,
								"-"
							],
							[
								7,
								1,
								"-"
							],
							[
								8,
								1,
								"-"
							],
							[
								9,
								1,
								"-"
							],
							[
								10,
								1,
								"-"
							],
							[
								11,
								1,
								"-"
							],
							[
								12,
								1,
								"-"
							],
							[
								13,
								1,
								"-"
							],
							[
								14,
								1,
								"-"
							],
							[
								15,
								1,
								"-"
							],
							[
								16,
								1,
								19
							],
							[
								17,
								1,
								6
							],
							[
								18,
								1,
								24
							],
							[
								19,
								1,
								10
							],
							[
								20,
								1,
								35
							],
							[
								21,
								1,
								18
							],
							[
								22,
								1,
								27
							],
							[
								23,
								1,
								8
							],
							[
								24,
								1,
								35
							],
							[
								25,
								1,
								6
							],
							[
								26,
								1,
								30
							],
							[
								27,
								1,
								1
							],
							[
								28,
								1,
								9
							],
							[
								29,
								1,
								4
							],
							[
								30,
								1,
								7
							],
							[
								0,
								0,
								"-"
							],
							[
								1,
								0,
								"-"
							],
							[
								2,
								0,
								"-"
							],
							[
								3,
								0,
								"-"
							],
							[
								4,
								0,
								"-"
							],
							[
								5,
								0,
								"-"
							],
							[
								6,
								0,
								"-"
							],
							[
								7,
								0,
								"-"
							],
							[
								8,
								0,
								"-"
							],
							[
								9,
								0,
								"-"
							],
							[
								10,
								0,
								"-"
							],
							[
								11,
								0,
								"-"
							],
							[
								12,
								0,
								"-"
							],
							[
								13,
								0,
								"-"
							],
							[
								14,
								0,
								"-"
							],
							[
								15,
								0,
								"-"
							],
							[
								16,
								0,
								35
							],
							[
								17,
								0,
								3
							],
							[
								18,
								0,
								11
							],
							[
								19,
								0,
								2
							],
							[
								20,
								0,
								31
							],
							[
								21,
								0,
								5
							],
							[
								22,
								0,
								8
							],
							[
								23,
								0,
								"-"
							],
							[
								24,
								0,
								18
							],
							[
								25,
								0,
								"-"
							],
							[
								26,
								0,
								10
							],
							[
								27,
								0,
								7
							],
							[
								28,
								0,
								20
							],
							[
								29,
								0,
								"-"
							],
							[
								30,
								0,
								8
							]
						],
						"label": {
							"show": false
						},
						"emphasis": {
							"itemStyle": {
								"shadowBlur": 10,
								"shadowColor": "rgba(0, 0, 0, 0.5)"
							}
						}
					}
				]
			},
			optionContactPie: {
				"title": {
					"text": "Registros vs Contactos comerciales"
				},
				"tooltip": {
					"trigger": "item",
					"formatter": "{a} <br/>{b}: {c} ({d}%)"
				},
				"legend": {
					"orient": "horizontal",
					"bottom": 0
				},
				"dataset": {
					"source": [
						{
							"name": "No da permiso",
							"value": 2673,
							"itemStyle": {
								"color": "#4d4d4d"
							}
						},
						{
							"name": "Da permiso",
							"value": 1000,
							"itemStyle": {
								"color": "#ceb780"
							}
						}
					]
				},
				"series": [
					{
						"name": "Registros",
						"type": "pie",
						"radius": [
							"30%",
							"70%"
						],
						"avoidLabelOverlap": false,
						"label": {
							"show": true,
							"position": "outside"
						},
						"labelLine": {
							"show": true
						},
						"encode": {
							"itemName": "name",
							"value": "value"
						}
					}
				],
				"graphic": {
					"type": "text",
					"left": "center",
					"top": "middle",
					"style": {
						"text": "3673",
						"textAlign": "center",
						"fill": "#000",
						"fontSize": 15,
						"fontWeight": "bold"
					}
				}
			},
			optionChannelAvgTicketBar: {
				"title": {
					"text": "Ticket Medio de cada canal",
					"subtext": "Solo medio de comida + Evolución"
				},
				"legend": {},
				"tooltip": {
					"trigger": "axis",
					"axisPointer": {
						"type": "shadow"
					}
				},
				"grid": {
					"top": "10%",
					"left": "5%",
					"containLabel": true
				},
				"label": {
					"show": true
				},
				"visualMap": {
					"min": 13.67,
					"max": 45.83,
					"dimension": 1
				},
				"dataset": {
					"source": [
						[
							"score",
							"amount",
							"product"
						],
						[
							13.67,
							13.67,
							"Beber"
						],
						[
							28.06,
							28.06,
							"gmap"
						],
						[
							45.83,
							45.83,
							"Instagram"
						],
						[
							28.93,
							28.93,
							"Mailing"
						],
						[
							26.99,
							26.99,
							"TheFork"
						],
						[
							17.8,
							17.8,
							"Uber Eats"
						],
						[
							20.88,
							20.88,
							"Walk-in"
						],
						[
							28.35,
							28.35,
							"Web"
						]
					]
				},
				"xAxis": {
					"type": "value",
					"axisLabel": {
						"formatter": "{value}€"
					}
				},
				"yAxis": {
					"type": "category"
				},
				"series": [
					{
						"type": "bar",
						"tooltip": {},
						"encode": {
							"x": "value",
							"y": "product"
						}
					}
				]
			},
			optionTicketGauge: {
				"title": {
					"text": "Ticket Medio",
					"subtext": "Mínimo, Medio y Máximo de comida + Evolución"
				},
				"series": [
					{
						"type": "gauge",
						"startAngle": 90,
						"endAngle": -270,
						"pointer": {
							"show": false
						},
						"progress": {
							"show": true,
							"overlap": false,
							"roundCap": true,
							"clip": false,
							"itemStyle": {
								"borderWidth": 0,
								"borderColor": "#464646"
							}
						},
						"axisLine": {
							"lineStyle": {
								"width": 40
							}
						},
						"splitLine": {
							"show": false,
							"distance": 0,
							"length": 10
						},
						"axisTick": {
							"show": false
						},
						"axisLabel": {
							"show": false,
							"distance": 50
						},
						"data": [
							{
								"value": 61.26,
								"name": "Máximo",
								"title": {
									"offsetCenter": [
										"0%",
										"-75"
									]
								},
								"detail": {
									"valueAnimation": true,
									"offsetCenter": [
										"0%",
										"-50"
									]
								}
							},
							{
								"value": 26.48,
								"name": "Medio",
								"title": {
									"offsetCenter": [
										"0%",
										"-15"
									]
								},
								"detail": {
									"valueAnimation": true,
									"offsetCenter": [
										"0%",
										"10"
									]
								}
							},
							{
								"value": 1.5,
								"name": "Mínimo",
								"title": {
									"offsetCenter": [
										"0%",
										"45"
									]
								},
								"detail": {
									"valueAnimation": true,
									"offsetCenter": [
										"0%",
										"70"
									],
								}
							}
						],
						"title": {
							"fontSize": 14
						},
						"detail": {
							"width": 50,
							"height": 14,
							"fontSize": 14,
							"color": "inherit",
							"borderColor": "inherit",
							"borderRadius": 20,
							"borderWidth": 1,
							"formatter": "{value} €"
						},
						"radius": "80%",
						"center": ["50%", "50%"]
					}
				]
			},
			optionChannelNestPie: {
				"title": {
					"text": "Ingresos por canal",
					"subtext": "Con IVA"
				},
				"tooltip": {
					"trigger": "item",
					"formatter": "{a} <br/>{b}: {c}€ ({d}%)"
				},
				"legend": {
					"type": "scroll",
					"data": [
						"WELKHOMEclub",
						"Delivery",
						"TheFork",
						"Gmaps",
						"Walk-in",
						"Mailing",
						"IG",
						"Beber",
						"JustEat",
						"Uber",
						"Glovo"
					],
					"top": 70
				},
				"series": [
					{
						"name": "Ventas por",
						"type": "pie",
						"radius": [
							0,
							"25%"
						],
						"label": {
							"formatter": "{b}\n {c}€",
							"position": "center",
							"fontWeight": "bold",
							"fontSize": 17
						},
						"labelLine": {
							"show": false,
							"length": 0
						},
						"data": [
							{
								"value": "43875.56",
								"name": "Total",
								"textColor": "#52657e",
								"itemStyle": {
									"color": "transparent"
								}
							}
						]
					},
					{
						"name": "Ventas por",
						"type": "pie",
						"selectedMode": "single",
						"radius": [
							"25%",
							"50%"
						],
						"label": {
							"position": "inner",
							"rotate": "tangential",
							"fontWeight": "bold",
							"fontSize": 12
						},
						"labelLine": {
							"show": false,
							"length": 0
						},
						"data": [
							{
								"value": "26850.10",
								"name": "WELKHOMEclub",
								"itemStyle": {
									"color": "#d7ad65"
								}
							},
							{
								"value": "213.57",
								"name": "Delivery",
								"itemStyle": {
									"color": "#2e3e5b"
								}
							},
							{
								"value": "16811.89",
								"name": "TheFork",
								"itemStyle": {
									"color": "#07a2a4"
								}
							}
						]
					},
					{
						"name": "Ventas por",
						"type": "pie",
						"radius": [
							"51%",
							"57%"
						],
						"labelLine": {
							"length": -5,
							"show": false
						},
						"label": {
							"formatter": "{b|{b}}\n {c}€  {per|{d}%}  ",
							"backgroundColor": "transparent",
							"borderColor": "inherit",
							"borderWidth": 0,
							"borderRadius": 4,
							"offset": [
								0,
								0
							],
							"rich": {
								"b": {
									"align": "center",
									"color": "#4C5058",
									"fontWeight": "bold",
									"lineHeight": 22
								},
								"c": {
									"align": "center",
									"color": "#4C5058",
									"fontSize": 14,
									"fontWeight": "bold",
									"lineHeight": 22
								},
								"per": {
									"align": "center",
									"color": "#fff",
									"backgroundColor": "#2e3e5b",
									"padding": [
										3,
										4
									],
									"lineHeight": 22,
									"borderRadius": 4
								}
							}
						},
						"data": [

							{
								"value": "19276.58",
								"name": "Web",
								"itemStyle": {
									"color": "#d49f1d"
								}
							},
							{
								"value": "982.13",
								"name": "Gmaps",
								"itemStyle": {
									"color": "#e3b239"
								}
							},
							{
								"value": "2631.12",
								"name": "Walk-in",
								"itemStyle": {
									"color": "#f4ca62"
								}
							},
							{
								"value": "1244.09",
								"name": "Mailing",
								"itemStyle": {
									"color": "#f2d58e"
								}
							},
							{
								"value": "91.66",
								"name": "IG",
								"itemStyle": {
									"color": "#fff4e8"
								}
							},
							{
								"value": "2624.52",
								"name": "Beber",
								"itemStyle": {
									"color": "#64ce99"
								}
							},
							{
								"value": 0,
								"name": "JustEat",
								"itemStyle": {
									"color": "#52657e"
								}
							},
							{
								"value": "213.57",
								"name": "Uber",
								"itemStyle": {
									"color": "#6a83a3"
								}
							},
							{
								"value": 0,
								"name": "Glovo",
								"itemStyle": {
									"color": "#85a4cd"
								}
							},
							{
								"value": "16811.89",
								"name": "TheFork",
								"itemStyle": {
									"color": "#fff"
								}
							}
						]
					}
				]
			},
			weeklyReservations: {
				title: {
					text: 'Control de reservas semanales'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					top: '7%',
					data: ['Reservas', 'Atendidas', 'Canceladas', 'Walkin', 'No show']
				},
				grid: {
					top: '25%',
					left: '1%',
					right: '4%',
					bottom: '2%',
					containLabel: true
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Reservas',
						type: 'line',
						data: [33, 40, 38, 63, 65, 70, 40],
						markPoint: {
							data: [
								{type: 'max', name: 'Punto más alto'}
							]
						}
					},
					{
						name: 'Atendidas',
						type: 'line',
						data: [30, 36, 36, 62, 65, 60, 30],
						markPoint: {
							data: [
								{type: 'max', name: 'Punto más alto'}
							]
						}
					},
					{
						name: 'Canceladas',
						type: 'line',
						data: [3, 1, 2, 0, 0, 5, 7],
						markPoint: {
							data: [
								{type: 'max', name: 'Punto más alto'}
							]
						}
					},
					{
						name: 'Walkin',
						type: 'line',
						data: [5, 4, 7, 2, 10, 12, 6],
						markPoint: {
							data: [
								{type: 'max', name: 'Punto más alto'}
							]
						}
					},
					{
						name: 'No show',
						type: 'line',
						data: [3, 1, 5, 2, 4, 7, 1],
						markPoint: {
							data: [
								{type: 'max', name: 'Punto más alto'}
							]
						}
					}
				]
			}
		};
	},
	methods: {
		...mapActions('ebitdata', ['updateReservationsForWeek']),
		addOneDay() {
			const newDate = new Date(this.selectedDate);
			newDate.setDate(newDate.getDate() + 1);
			store.commit('ebitdata/setSelectedDate', {selectedDate: newDate.toISOString().substr(0, 10)})
		},
		subtractOneDay() {
			const newDate = new Date(this.selectedDate);
			newDate.setDate(newDate.getDate() - 1);
			store.commit('ebitdata/setSelectedDate', {selectedDate: newDate.toISOString().substr(0, 10)})
		},

		async updateReservations() {
			await store.dispatch('ebitdata/updateReservations');
		},

	}
}
</script>
<style>
.ebitdata-chart {
	width: 100%;
	height: 70vh;
}
</style>

<template src="./navBarTop.html"></template>

<script>
export default {
	name: 'NavBarTop',
	data() {
		return {
			menuItems: [
				{href: "/copilot", icon: "fas fa-mail-bulk", text: "Copilot"},
				{href: "/pass", icon: "fas fa-address-card", text: "Pass"},
				{href: "/menu", icon: "fas fa-utensils", text: "Menu"},
				{href: "/reservar", icon: "fas fa-calendar", text: "Reservar"},
				{href: "/welcom-reserva-widget", icon: "fas fa-crop-alt", text: "Widget Reserva"},
				{href: "https://wewelcom.io/form", icon: "fas fa-clipboard", text: "Formulario"},
				{href: "/ebitdata", icon: "fas fa-chart-bar", text: "Ebitdata"},
				{href: "/legacy/welcom-pm-legacy", icon: "fas fa-tasks", text: "Product Manager"}
			]
		};
	},
	beforeMount() {
	},
	mounted() {
		document.addEventListener('click', function (event) {
			let navbarToggler = document.querySelector('.navbar-toggler');
			let navbarCollapse = document.querySelector('.navbar-collapse');

			if (!navbarCollapse.contains(event.target) && !navbarToggler.contains(event.target) && navbarCollapse.classList.contains('show')) {
				navbarCollapse.classList.remove('show');
				navbarToggler.setAttribute('aria-expanded', 'false');
				navbarToggler.classList.add('collapsed');
			}
		});
	},
	methods: {
		isActive(href) {
			const currentPath = window.location.pathname;
			return currentPath === href;
		}
	}
}
</script>

<template>
	<template v-if="!isLoadingMarkToPreparing">
		<div v-if="!(source !== 'hotTable' && isInHotTable)" :style="{ backgroundImage: pictureMenu ? `url(${pictureMenu})` : {} }" :class="{ 'product-card-employee-new': isNew,'finish': finishProduct, 'started': startedProduct, 'prepared': preparedProduct || isInHotTable, 'content-img': showPictureMenu, 'product-choice': product.choices.length}" @click="handleClickProductCard" class="product-card-employee" :id="`product-${product.productOrderId}`" >
			<span v-if="source !== 'hotTable' && !finishProduct" class="badge badge-time" :class="isNew && !(startedProduct && !isInHotTable) ? 'badge-secondary': 'badge-light'" style="text-shadow: none">
				<span class="me-1">{{ elapsedTime }}</span>

				<span v-if="source === 'hotTable'"><TableIcon style="width: 15px"/> {{ product.table }}</span>
			</span>
			<div class="product-card-body"  >
				<div class="product-card-info">
					<div class="product-card-data">
						<div class="product-card-title">
							<span v-if="product.units > 1"> {{ product.units }} (uds.)</span> {{ product.name }}
						</div>
					</div>
					<div class="product-card-choice" v-if="product.choices.length" >
						<span class="fw-bold" v-html="choicesText(product.choices)"></span>
						<!--						<span v-for="(choices, index) in product.choices" :key="index">-->
						<!--&lt;!&ndash;							<b>{{ choices.quantity }}</b>{{ choices.choice }}&ndash;&gt;-->
						<!--						</span>-->
					</div>
				</div>
			</div>
			<div class="position-absolute z-2" style="bottom: -10px; right: -10px">
				<span class="badge badge-warning" v-if="startedProduct && !isInHotTable"><ChefHatIcon style="width: 20px" fill-color="black"/></span>
				<!--			<span class="pulse text-warning text-uppercase" v-if="isInHotTable && source === 'hotTable'">En proceso</span>-->
				<span class="badge badge-success text-uppercase" v-if="isInHotTable && source !== 'hotTable'"><i class="fa-solid fa-check"></i></span>
				<span class="badge badge-success text-uppercase" v-if="preparedProduct && source !== 'hotTable'"><i class="fa-solid fa-check"></i> <SpinnerIcon/></span>
				<span class="badge badge-success text-uppercase" v-if="finishProduct && source !== 'hotTable'"><i class="fa-solid fa-check-double"></i></span>
			</div>
			<span  v-if="source === 'hotTable'" class="badge badge-light badge-time" style="text-shadow: none;top: 70%">
				<TableIcon style="width: 15px"/> {{ product.reservationInfo ? product.reservationInfo.table : product.table }}
				</span>
		</div>
	</template>
	<div v-else class="product-card-employee">
		<div class="product-card-body  placeholder-glow">
			<div class="product-card-info">
				<div class="product-card-data">
					<h5 class="product-card-title"><span class="placeholder col-12"></span></h5>
				</div>
				<div class="product-card-choice  placeholder-glow">
					<span class="placeholder col-3"></span>	<span class="placeholder col-3"></span>
				</div>
			</div>
		</div>
		<div class="product-card-footer p-2 placeholder-glow">
			<span class="placeholder col-5"></span>
		</div>
	</div>
</template>

<script>

import store from "@/store";
import {mapGetters} from "vuex";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: 'CopilotProductCardKitchen',
	components: {TableIcon, ChefHatIcon, SpinnerIcon},
	data() {
		return {
			isLoadingMarkToPreparing: false,
			showPictureMenu: false,
			pictureMenu: null,
			elapsedTime: '',

			isNew:true,
		};
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
		productOrderRepository: 'productOrderRepository',
	},
	props: {
		product: Object,
		source: {
			type: [String, null],
			default: null
		}
	},
	async mounted() {
		// this.updateElapsedTime();
		await this.checkPictureMenu();
		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);
	},
	beforeMount() {
		this.pictureMenu = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
	},
	beforeUnmount() {

	},
	computed: {
		...mapGetters('venue', ['resourceId']),
		...mapGetters('copilot', ['hotTable']),
		isInHotTable() {
			return this.hotTable.filter(product => product.productOrderId === this.product.productOrderId).length > 0;
		},
		startedProduct() {
			return this.product.statusProductOrder === 2;
		},
		preparedProduct() {
			return this.product.statusProductOrder === 3;
		},
		finishProduct() {
			return this.product.statusProductOrder === 100
		},

	},
	methods: {
		async checkPictureMenu() {
			const url = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
			if (!this.product.pictureMenu) {
				// Si no hay una imagen en el producto, no asignamos una URL y no mostramos la clase
				this.pictureMenu = null;
				this.showPictureMenu = false;
				return;
			}
			try {
				// Usa fetch para verificar si la imagen existe
				const response = await fetch(url);
				if (response.ok) { // Si la respuesta es 200-299
					this.pictureMenu = url;
					this.showPictureMenu = true;
				} else {
					this.pictureMenu = null;
					this.showPictureMenu = false;
				}
			} catch (error) {
				// En caso de error (por ejemplo, si la imagen no existe)
				this.pictureMenu = null;
				this.showPictureMenu = false;
			}
		},
		choicesText(choicesArray) {
			const resultArray = choicesArray
				.map(choice => {
					const unit = choice.quantity > 1 ? `<b>${choice.quantity}</b> ` : '';
					const name = choice.choice ? choice.choice : '';

					return unit + name;
				})
				.filter(text => text.trim() !== '');

			if (resultArray.length === 0) {
				return '';
			}

			const result = resultArray.join(', ');

			console.log(result);
			return result ? result + '.' : '';
		},
		updateElapsedTime() {
			if (!this.product || !this.product.createdDateTime) {
				return;
			}

			const now = new Date();

			const createdTime = new Date(this.product.createdDateTime);

			const differenceInMs = now - createdTime;

			if (differenceInMs > 300000) {
				this.isNew = false;
			}

			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.product.createdDateTime);
		},
		async handleClickProductCard() {
			console.log('Se ha hecho click en el producto', this.product.name, ' con status ', this.product.statusProductOrder, this.source, this.product);

			if (this.product.statusProductOrder === 1) {
				this.isLoadingMarkToPreparing = true;
				const data = { productOrderId: this.product.productOrderId };
				const resultMarkAsPreparing = await this.productOrderRepository.markAsPreparingProductOrder(data);

				if (resultMarkAsPreparing.result === 0) {
					// store.commit("copilot/setToast", {
					// 	toast: {
					// 		toastMessage: `Has marcado el pedido como que está en proceso.`,
					// 		showToast: true,
					// 		idToast: "correctlyToast"
					// 	}
					// });
					console.log(resultMarkAsPreparing)
				} else {
					// store.commit("copilot/setToast", {
					// 	toast: {
					// 		toastMessage: `Ha habido un error marcando el pedido como en proceso.`,
					// 		showToast: true,
					// 		idToast: "incorrectlyToast"
					// 	}
					// });
					console.error(resultMarkAsPreparing)
				}

				this.isLoadingMarkToPreparing = false;
				return;
			}

			if (this.product.statusProductOrder === 2 && this.source !== "hotTable") {
				if (this.isInHotTable) return;
				store.commit("copilot/addProductOrderToHotTable", { productOrder: this.product });
			}

			if (this.product.statusProductOrder === 2 && this.source === "hotTable") {
				store.commit("copilot/deleteProductOrderToHotTable", { productOrderId: this.product.productOrderId });
			}
		}

	},
}
</script>

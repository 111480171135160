<template>
	<svg :style="{fill: fillColor ? fillColor : 'black'}" class="table-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 20 140 80" width="200">
		<rect x="10" y="30" width="120" height="20" rx="5" ry="5" fill="inherit"/>
		<rect x="20" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>
		<rect x="100" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>
	</svg>
</template>

<script>
export default {
	name: 'TableIcon',
	props: {
		fillColor: String
	}
}
</script>

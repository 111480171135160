<template src="./menu.html"></template>
<script>
import * as bootstrap from 'bootstrap';
import MenuProductCard from "@/components/menuProductCard/MenuProductCard";
import MenuCartProduct from "@/components/menuCartProduct/MenuCartProduct";
import NavBottom from "@/components/navBottom/NavBottom";
import ModalMessage from "@/components/mods/modalMessage/ModalMessage";
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import NavBarTop from "@/components/navBarTop/NavBarTop";
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import {
	arraysAreEqualIgnoringOrder,
	groupItemsBySkuAndChoices, showModal,
} from "@/utils";
import {WebSocketClient} from "@/service/webSocketClient";
import NavbarScrollCategories from '@/components/navbarScrollCategories/NavbarScrollCategories'
import HeaderComponent from "@/core/HeaderComponent";
import MenuSearcher from "@/components/menuSearcher/MenuSearcher";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import DismissButton from "@/core/DismissButton";
import BagOrdersIcon from "@/core/icons/BagOrdersIcon";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import store from "@/store";
import menuOffcanvasAssessment from "@/components/menuOffcanvasAssessment/menuOffcanvasAssessment";
import MenuModalRequestAccount from "@/components/modalRequestAccount/ModalRequestAccount";
import ModalPrivacy from "@/components/mods/modalPrivacy/ModalPrivacy";

export default {
	name: 'MenuWelcom',
	data() {
		return {
			webSocketClient: WebSocketClient,
			activeMenu: null,
			cartClientMessage: '',
			messageCart: {
				clientName: '',
				products: []
			},
			reservationDate: '',
			reservationTime: '',
			reservationInfo: {
				isRealReservation: false,
				pax: null,
				id: null,
				promotion: null,
			},
			isLoad: false,
			isLoading: true,
			idQr: '',

			menuButtonContentOrder: 'Hacer pedido',
			isSpinnerIconLoading: false,

			isRequestAccount: false,
			isModalOpen: null,
			isTypeFood: true,
			menuCategories: [],
			isCreator: false,
			invitedUsers: [],
			copilotProducts: null,
			viewMode: 'unified',

			showLeftArrow: false,
			showRightArrow: true,
		};
	},
	inject: {
		notificationRepository: 'notificationRepository',
		cartSessionRepository: 'cartSessionRepository',
		userRepository: 'userRepository',
		venueRepository: 'venueRepository',
		menuRepository: 'menuRepository',
		cookieManager: 'cookieManager',
		reservationRepository: 'reservationRepository',
		dateFormatterService: 'dateFormatterService',
	},
	computed: {
		...mapGetters('config', ['websocketUrl', 'websocketPort', 'weWelcomUrl']),
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'weOrderClientComments']),
		...mapGetters('menu', ['menusVenue', 'productsCart']),
		menus(){
			return this.menusVenue
		},
		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},
		linkShareMenuWhatsapp() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/whatsappmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		linkShareMenuQr() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/qrmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		isValidReservation() {
			console.log('cambia la reserva', this.reservationInfo.finishedDateTime)
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
		isCopilotRoute() {
			return this.$route.path.includes('/copilot')
		},

		backdropAccount() {
			return !this.isCopilotRoute;
		},
		matchingProductUnits() {
			return (product) => {
				if (!this.product || !this.product.sku) {
					console.error('Product or product SKU is not defined');
					return {cartItemCount: 0};
				}
				return product.find(unit => unit.sku === this.product.sku) || {cartItemCount: 0};
			};
		},
		recommendedProducts() {
			const topChoiceProducts = [];

			this.menus.forEach(menu => {
				if (Array.isArray(menu.menuCategories)) {
					menu.menuCategories.forEach(category => {
						if (Array.isArray(category.products)) {
							category.products.forEach(product => {
								if (product.topChoice === "SI") {
									topChoiceProducts.push(product);
								}
							});
						}
					});
				}
			});

			return topChoiceProducts;
		},
		cartSessionOrders() {
			return this.reservationInfo.cartSession ? this.reservationInfo.cartSession.productOrders : [];
		},
		processedCartSessionOrders() {
			return this.cartSessionOrders.filter(order => order.cartSessionStatus > 0);
		},
		historicUnification() {
			const groupedItems = groupItemsBySkuAndChoices(this.processedCartSessionOrders);

			const arrayProducts = Object.values(groupedItems).map(item => ({
				productSku: item.productSku,
				name: item.name,
				quantity: item.count,
				category: item.category,
				pictureGeneral: item.pictureGeneral,
				pictureMhd: item.pictureMhd,
				type: item.type,
				choices: item.choices.map(choice => ({
					...choice,
					quantity: choice.quantity || 1
				})),
				choicesKey: item.choicesKey
			}));

			console.log('arrayProducts:', arrayProducts);

			return arrayProducts;
		},
	},
	components: {
		'menu-product-card': MenuProductCard,
		'menu-cart-product': MenuCartProduct,
		'nav-bottom': NavBottom,
		'modal-message': ModalMessage,
		'generator-qr': GeneratorQr,
		'navbar-top': NavBarTop,
		'footer-welcom': FooterWelcom,
		HeaderComponent,
		NavbarScrollCategories,
		MenuSearcher,
		SpinnerIcon,
		DismissButton,
		BagOrdersIcon,
		OrdersProductCard,
		ChefHatIcon,
		menuOffcanvasAssessment,
		MenuModalRequestAccount,
		ModalPrivacy,
	},
	async beforeMount() {
		if (!this.user) {
			// this.$router.push('/reservar');
			console.log('no hay usuario en el menu')
		} else {
			this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
			this.webSocketClient.connect(this.user.id);
		}

		const localStorageCart = localStorage.getItem('cart');
		if (localStorageCart) {
			store.commit('menu/setProductsCart', {productsCart: JSON.parse(localStorageCart)})
		}

		await this.loadInfoReservation();

		if (this.reservationInfo && this.reservationInfo.reservationUsers) {
			this.invitedUsers = this.reservationInfo.reservationUsers.filter(user => user.userId && user.type !== 1);
		} else {
			this.invitedUsers = []; // Asegurar que invitedUsers no es undefined
		}

		this.isCreator = this.isValidReservation ? this.reservationInfo.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0 : false;

		this.setupData();
	},
	async mounted() {

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		this.activeMenu = this.menus[0];

		const localStorageCart = localStorage.getItem('cart');
		if (localStorageCart) {
			store.commit('menu/setProductsCart', {productsCart: JSON.parse(localStorageCart)})
		}

		this.$nextTick(() => {
			this.updateArrows();

			this.productsCart.forEach(product => {
				const element = document.getElementById(product.sku);
				if (element) {
					element.classList.add('addProduct');
				}
			});
		});


		this.selectMenuCategories()

		this.isLoad = true;
	},
	watch: {
		productsCart: {
			handler(newCart, oldCart) {
				const oldCartSkus = oldCart.map(product => product.sku);
				const newCartSkus = newCart.map(product => product.sku);

				// Add 'addProduct' class for new items in the cart
				newCart.forEach(product => {
					if (!oldCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.add('addProduct');
						}
					}
				});

				// Remove 'addProduct' class for items no longer in the cart
				oldCart.forEach(product => {
					if (!newCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.remove('addProduct');
						}
					}
				});

				// Update local storage with the new cart state
				localStorage.setItem('cart', JSON.stringify(newCart));
			},
			deep: true
		},
	},
	methods: {
		ncodeCredentials(username, password) {
			const credentials = `${username}:${password}`;
			return btoa(credentials);
		},
		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
		async loadInfoReservation() {
			const values = window.location.search;
			const urlParams = new URLSearchParams(values);
			let reservationId = urlParams.get('r');

			const cookieReservationId = this.cookieManager.getReservationMenuId();

			if (!reservationId && cookieReservationId) {
				reservationId = cookieReservationId;
			}

			if (reservationId) {
				const reservationResponse = await this.reservationRepository.find(reservationId);
				const reservation = reservationResponse.reservation

				if (reservation && !reservation.finishedDateTime && reservation.status === 1 && reservation.menuAccessEnabled === 1) {
					this.reservationInfo = reservation;
					console.log('Es una reserva valida: ', this.reservationInfo)
					this.cookieManager.setReservationMenuId(this.reservationInfo.id);
				} else {
					if (reservation) {
						this.reservationInfo = reservation;
					}

					console.log('No es una reserva valida: ', this.reservationInfo);
					// this.cookieManager.clearReservationMenuId();
					localStorage.setItem('cart', JSON.stringify([]));
					this.productsCart = [];
					// window.location.href = this.urlVenue;
				}

			} else {
				console.log('No es una reserva real: ', this.reservationInfo)
				// this.cookieManager.clearReservationMenuId();
				localStorage.setItem('cart', JSON.stringify([]));
				this.productsCart = [];
				// this.$router.push('/reservar')
				console.log('no hay usuario en el menu')
			}

			this.removeQueryParams();
		},
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		addUnit(product) {
			store.commit('menu/addUnit', {product})
		},
		subtractUnit(product) {
			store.commit('menu/subtractUnit', {product})
		},

		async handleConfirmCart() {

			this.menuButtonContentOrder = 'Solicitando...';
			this.isSpinnerIconLoading = true;
			const orderInfo = {
				name: this.user.name,
				welKey: this.user.welKey,
				userId: this.user.id,
				pax: this.reservationInfo.pax,
				source: 1,
				clientMessage: this.cartClientMessage,
				orderReservationName: this.user.name,
				reservationId: this.reservationInfo.id,
				av: 0,
			}

			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {

					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount
					};
				} else {

					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions
					});
				}
			});

			const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
			this.isRequestAccount = false;
			console.log(resultOrder)
			if (resultOrder.result === 0) {
				this.menuButtonContentOrder = 'Solicitando...'
				this.isSpinnerIconLoading = true;

				const modal = document.getElementById('orders');
				const modalOrders = bootstrap.Modal.getInstance(modal);
				modalOrders.hide();

				this.menuButtonContentOrder = 'Hacer pedido';
				this.isSpinnerIconLoading = false;

				this.messageCart = {clientName: this.user.name, products: this.productsCart};
				showModal('messageModal')
				// const modalCheckInbox = new bootstrap.Modal(document.getElementById('messageModal'), {
				// 	backdrop: true,
				// });
				// modalCheckInbox.show();
				store.commit('menu/setProductsCart', {productsCart: []})
				this.cartClientMessage = '';
				await this.loadInfoReservation()
			}
		},

		setupData() {
			const dateTime = new Date(this.reservationInfo.startDateTime);

			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const nameDay = daysOfWeek[dateTime.getDay()];
			const translator = {
				Sunday: "Dom.",
				Monday: "Lun.",
				Tuesday: "Mar.",
				Wednesday: "Mié.",
				Thursday: "Jue.",
				Friday: "Vie.",
				Saturday: "Sáb.",
			};
			const nameDayTranslated = translator[nameDay];

			const months = {
				Jan: 'Ene',
				Feb: 'Feb',
				Mar: 'Mar',
				Apr: 'Abr',
				May: 'May',
				Jun: 'Jun',
				Jul: 'Jul',
				Aug: 'Ago',
				Sep: 'Sep',
				Oct: 'Oct',
				Nov: 'Nov',
				Dec: 'Dic'
			};
			const month = months[dateTime.toLocaleString('en', {month: 'short'})];
			this.reservationDate = `${nameDayTranslated} ${dateTime.getDate()} ${month}`;

			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			this.reservationTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
		},

		selectMenuCategories(menus = null) {
			const foodMenu = {
				id: 'food',
				name: 'Comida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories)
					: this.menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories),
			};
			const drinkMenu = {
				id: 'drink',
				name: 'Bebida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories)
					: this.menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories),
			};
			this.menuCategories = [foodMenu, drinkMenu];
			console.log(this.menuCategories);
		},

		filterMenuCategoriesByProducts(menusFiltered) {
			console.log('desde menu', menusFiltered)
			this.selectMenuCategories(menusFiltered)
		},

		shouldRemoveProductCart(productCart, product) {
			if (productCart.sku !== product.sku) {
				return false;
			}
			return arraysAreEqualIgnoringOrder(productCart.chosenOptions, product.chosenOptions);
		},

		groupedItems(order) {
			return groupItemsBySkuAndChoices([order]);
		},

		scrollLeft() {
			this.scroll('left');
		},
		scrollRight() {
			this.scroll('right');
		},
		scroll(direction) {
			const scrollContainer = this.$refs.scrollContainer;
			const cardWidth = scrollContainer.querySelector('.product-card-client-vertical').offsetWidth;
			if (direction === 'left') {
				scrollContainer.scrollBy({ left: -cardWidth, behavior: 'smooth' });
			} else if (direction === 'right') {
				scrollContainer.scrollBy({ left: cardWidth, behavior: 'smooth' });
			}
			this.updateArrows();
		},
		updateArrows() {
			const scrollContainer = this.$refs.scrollContainer;
			if (!scrollContainer) return;  // Prevent error if ref is not defined
			const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
			this.showLeftArrow = scrollContainer.scrollLeft > 0;
			this.showRightArrow = scrollContainer.scrollLeft < maxScrollLeft;
		},

	},
}
</script>
<style>
.scroll-arrow-welcom {
	position: absolute;
	bottom: 0;
	color: var(--bs-white);
	border: none;
	padding: 10px;
	cursor: pointer;
	z-index: 10;
	height:100%;
	font-size: 1.2rem;
	background: transparent;
}

.left-arrow {
	left: 0;
}

.right-arrow {
	right: 0;
}
</style>

<template  src="./iframeReserve.html"></template>

<script>

import {mapGetters} from "vuex";

import NavBarTop from "@/components/navBarTop/NavBarTop";
import FooterWelcom from "@/core/FooterWelcom";
import HeaderComponent from "@/core/HeaderComponent";
import WidgetReserve from "@/views/widgetReserve/WidgetReserve";
import ReservationComponent from "@/components/reservationComponent/ReservationComponent";

export default {
	name: 'IframeReserveWelcom',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	data() {
		return {
		};
	},
	components: {
		'navbar-top': NavBarTop,
		'footer-welcom': FooterWelcom,
		ReservationComponent,
		HeaderComponent,
		WidgetReserve
	},
	async beforeMount() {


	},
	async mounted() {


	},
	beforeUnmount() {

	},
	watch: {

	},
	computed: {
		...mapGetters('config' , ['websocketUrl','websocketPort', 'weWelcomUrl']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName','reservationAgent','reservationManagerUrl' ]),
	},
	methods: {

	},
}
</script>

<template>
	<div class="copilot-reservation-kitchen hotTable">
		<div class="reservation-kitchen-content scroll-x hinting py-2">
			<CopilotProductCardKitchen
				v-for="product in hotTable"
				:key="product.productOrderId"
				source="hotTable"
				:product="product"/>
		</div>
		<div class="reservation-wrapper-kitchen-data ">
			<button :disabled="hotTable.length === 0" @click="handleProductOrdersReady" class="btn btn-secondary w-100 btn-welcom h-100">
				<span v-if="!isLoadingMarkToReady"><ServiceBellIcon fill-color="black"/></span><span v-else>Enviando...<SpinnerIcon/></span>
			</button>
		</div>
	</div>

	<copilot-reservation-kitchen :reservation="reservation" v-for="reservation in sortedReservationsWithProducts" :key="reservation.id"></copilot-reservation-kitchen>

	<div class="copilot-reservation-kitchen hotTable position-fixed bottom-0" style="top: auto">

		<div class="reservation-wrapper-kitchen-data ">
			<button @click="handleServeProductOrders()" :class="{'disabled': !serviceEventPendingService.length}" class="btn btn-secondary w-100 h-100 btn-welcom">
				<span class="process-button"  v-html="isRequestInLoading ? buttonTextOrderLoading : buttonTextOrder "></span></button>
		</div>
		<div class="reservation-kitchen-content scroll-x hinting py-2" v-if="serviceEventPendingService && serviceEventPendingService.length > 0">
			<template v-for="order in unifiedProducts" >
				<CopilotProductCardKitchen
					v-for="product in order.products" :key="product.productOrderId"
					source="hotTable"
					:product="product"/>
			</template>
		</div>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
import CopilotProductCardKitchen from "@/components/copilotProductCardKitchen/CopilotProductCardKitchen";
import store from "@/store";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import CopilotReservationKitchen from "@/components/CopilotReservationKitchen/CopilotReservationKitchen";
import ServiceBellIcon from "@/core/icons/serviceBellIcon";

export default {
	name: 'CopilotContainerKitchen',
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	components: {ServiceBellIcon, CopilotReservationKitchen, SpinnerIcon, CopilotProductCardKitchen },
	data() {
		return {
			isLoadingMarkToReady:false,

			isRequestInLoading: false,
			buttonTextOrderLoading: `Sirviendo pedido <i class="fas fa-spinner fa-spin"></i>`

		}
	},
	mounted() {
		store.commit('copilot/clearHotTable');
	},
	beforeUnmount() {
		store.commit('copilot/clearHotTable');
		console.log({services: this.serviceEventPendingService});

	},
	computed: {
		...mapGetters('copilot', ['reservationsWithProducts','allOrderProducts', 'hotTable', 'selectedRole','serviceEventPendingService','selectedProductOrdersToServe']),
		buttonTextOrder(){
			if(this.serviceEventIsDrinkOrDish === 'Cocina'){
				return this.selectedProductOrdersToServe.length === 0 ? 'Servir' : `Servir ${this.selectedProductOrdersToServe.length} platos`
			}else{
				return this.selectedProductOrdersToServe.length === 0 ? 'Servir' : `Servir ${this.selectedProductOrdersToServe.length} bebidas`
			}

		},
		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => !reservation.finishedDateTime)
				.slice()
				.sort((a, b) => {
					const aPriority = this.getReservationPriority(a);
					const bPriority = this.getReservationPriority(b);

					// Ordenar primero por sortOrder (menor sortOrder primero)
					if (aPriority.sortOrder < bPriority.sortOrder) return -1;
					if (aPriority.sortOrder > bPriority.sortOrder) return 1;

					// Si sortOrder es el mismo, ordenar por earliestCreatedDateTime (el más antiguo primero)
					if (aPriority.earliestCreatedDateTime < bPriority.earliestCreatedDateTime) return -1;
					if (aPriority.earliestCreatedDateTime > bPriority.earliestCreatedDateTime) return 1;

					return 0;
				});
		},
		unifiedProducts() {
			const items = this.serviceEventPendingService[0].items.filter(productOrder => productOrder.statusProductOrder < 100);
			const groupedProducts = {};

			items.forEach(item => {
				const reservationId = item.reservationInfo.reservationId;
				if (!groupedProducts[reservationId]) {
					groupedProducts[reservationId] = {
						reservationInfo: item.reservationInfo,
						products: []
					};
				}
				groupedProducts[reservationId].products.push(item);
			});

			return Object.values(groupedProducts).map(group => ({
				reservationInfo: group.reservationInfo,
				products: group.products
			}));
		},
	},
	methods: {
		async handleProductOrdersReady(){
			this.isLoadingMarkToReady = true;
			const data = this.hotTable.map(productOrder => productOrder.productOrderId)
			const resultFinishPreparation = await this.productOrderRepository.finishPreparation({productOrderIds: data, roleId: this.selectedRole});
			console.log(resultFinishPreparation)
			if(resultFinishPreparation.result === 0){
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: `Has marcado el pedido como listo.`,
				// 		showToast: true,
				// 		idToast: "correctlyToast"
				// 	}
				// })
				store.commit('copilot/clearHotTable');
			}else{
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: `Ha habido un error marcando el pedido como listo.`,
				// 		showToast: true,
				// 		idToast: "incorrectlyToast"
				// 	}
				// });
			}

			this.isLoadingMarkToReady = false;
		},
		getReservationPriority(reservation) {
			if (!reservation.orderedItems || reservation.orderedItems.length === 0) {
				return { sortOrder: Number.MAX_SAFE_INTEGER, earliestCreatedDateTime: Number.MAX_SAFE_INTEGER };
			}

			// Filtrar productos con statusProductOrder igual a 1 o 2
			const orderedProducts = reservation.orderedItems.filter(item => item.statusProductOrder === 1 || item.statusProductOrder === 2);

			if (orderedProducts.length === 0) {
				return { sortOrder: Number.MAX_SAFE_INTEGER, earliestCreatedDateTime: Number.MAX_SAFE_INTEGER };
			}

			// Obtener el sortOrder más bajo de dishClass entre los productos
			const minSortOrder = Math.min(...orderedProducts.map(item => item.dishClass?.sortOrder || Number.MAX_SAFE_INTEGER));

			// Obtener la hora de creación más antigua entre los productos con el mismo sortOrder
			const earliestCreatedDateTime = Math.min(...orderedProducts
				.filter(item => item.dishClass?.sortOrder === minSortOrder)  // Solo considerar productos con el sortOrder mínimo
				.map(item => new Date(item.createdDateTime).getTime()));

			return {
				sortOrder: minSortOrder,
				earliestCreatedDateTime: earliestCreatedDateTime
			};
		},

		async handleServeProductOrders() {
			console.log('Vamos a procesar los pedidos como servidos');
			this.isRequestInLoading = true;
			console.log("Este es el serviceEvent", this.serviceEventPendingService[0])
			if(!this.selectedProductOrdersToServe.length){
				const allProductsOrders = this.serviceEventPendingService[0].items.filter(productOrder => productOrder.statusProductOrder === 3).map(productOrder => productOrder.productOrderId);
				console.log(allProductsOrders);
				const data = {
					productOrderIds: allProductsOrders,
					serviceEventId: this.serviceEventPendingService[0].serviceEventId,
				};
				const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
				console.log(resultServeProductOrders)
			}else{
				const data = {
					productOrderIds: this.selectedProductOrdersToServe,
					serviceEventId: this.serviceEventPendingService[0].serviceEventId,
				};
				const resultServeProductOrders = await this.productOrderRepository.serveProductOrders(data);
				console.log(resultServeProductOrders);
				store.commit('copilot/setSelectedProductOrdersToServe', { selectedProductOrdersToServe: [] });
			}
			this.isRequestInLoading = false;
		},
	}
}
</script>


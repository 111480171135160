<div id="widget" :data-bs-theme="resourceId">
<!--	<header class="header-container header-image" v-if="resourceId" :style="{ 'background-image': 'url(/assets/img/custom/' + resourceId + '/header/menu.jpg)' }">-->
<!--		<div class="header-content">-->
<!--			<h1 class="light">WIDGET<br>RESERVA</h1>-->
<!--		</div>-->
<!--	</header>-->

	<div id="welcom-reserva-externa">
		<section id="booking">
			<div class="container">
				<div class="row">
					<div class="col">
						<iframe ref="restooBookingIframe" loading="lazy" id="restooBookingIframe" :src="updatedUrl"
								 height="800" width="100%" frameborder="0"></iframe>
					</div>
				</div>
			</div>
		</section>
	</div>

</div>

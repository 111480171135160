<template>
	<!-- Botón que utiliza tanto computed properties para sus atributos como un método para manejar eventos -->
	<button type="button" class="btn btn-outline-primary" :data-bs-dismiss="dismissAction" @click="handleClick">
		{{ buttonText }}
	</button>
</template>

<script>
export default {
	name: 'DismissButton',
	props: {
		isModal: {
			type: Boolean,
			default: true
		},
		contentBtnText: {
			type: String,
			default: 'Cerrar'
		},
		onClick: {
			type: Function,
			default: null
		}
	},
	computed: {
		dismissAction() {
			return this.isModal ? 'modal' : 'offcanvas';
		},
		buttonText() {
			return this.contentBtnText;
		}
	},
	methods: {
		handleClick() {
			if (this.onClick) {
				this.onClick();
			}
		}
	}
}
</script>

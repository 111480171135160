import store from "@/store";

export class FaviconService {

	constructor() {
	}

	changeFavicon(weWelcomUrl) {
		console.log("Podemos cambiar el favicon? "+weWelcomUrl)

		let src = "/assets/img/favicon/favicon-32x32-prod.png";
		if (weWelcomUrl === "https://test.wewelcom.io"){
			src = "/assets/img/favicon/favicon-32x32-test.png";
		} else if (weWelcomUrl === "https://dev.wewelcom.io"){
			src = "/assets/img/favicon/favicon-32x32-dev.png";
		}

		const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
		link.type = 'image/png';
		link.rel = 'icon';
		link.href = src;
		document.getElementsByTagName('head')[0].appendChild(link);
	}

	setFavicon(faviconUrl) {

		const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
		link.type = 'image/png';
		link.rel = 'icon';
		link.href = faviconUrl;
		document.getElementsByTagName('head')[0].appendChild(link);
	}

	changeFaviconByRoute(routePath) {
		let faviconSrc = "/world/img/default/favicons/wewelcom/favicon.ico";
		let appleTouchIconSrc = "/world/img/default/favicons/wewelcom/apple-touch-icon.png";
		switch (routePath) {
			// case '/home':
			// 	faviconSrc = "/assets/img/favicon/home-favicon.png";
			// 	appleTouchIconSrc = "/assets/img/apple-touch-icon/home-apple-touch-icon.png";
			// 	break;
			case '/menu':
				faviconSrc = "/world/img/default/favicons/weorders/favicon.ico";
				appleTouchIconSrc = "/world/img/default/favicons/weorders/apple-touch-icon.png";
				break;
			case '/reservar':
				console.log(routePath);
				console.log(faviconSrc)
				console.log(appleTouchIconSrc)
				break;
			case '/login':
				faviconSrc = "/world/img/default/favicons/wewelcom/favicon.ico";
				appleTouchIconSrc = "/world/img/default/favicons/wewelcom/apple-touch-icon.png";
				break;
			case '/copilot':
				faviconSrc = "/world/img/default/favicons/wecopilot/favicon.ico";
				appleTouchIconSrc = "/world/img/default/favicons/wecopilot/apple-touch-icon.png";
				break;
			case '/kiosk':
				faviconSrc = "/world/img/default/favicons/wekiosk/favicon.ico";
				appleTouchIconSrc = "/world/img/default/favicons/wekiosk/apple-touch-icon.png";
				break;
			case '/home':
				if (store.getters['venue/resourceId'] === "elcapirotedegranada"){
					faviconSrc = `/world/img/${store.getters['venue/resourceId']}/favicon/favicon.ico`
					appleTouchIconSrc =  `/world/img/${store.getters['venue/resourceId']}/favicon/favicon.ico`
				}
				break;
			default:
				break;
		}

		this.updateIcons(faviconSrc, appleTouchIconSrc);
		console.log("Favicon cambiado según la ruta: " + routePath);
	}

	updateIcons(faviconHref, appleTouchIconHref) {
		this.updateLinkAttributes('icon', 'image/png', faviconHref, '32x32');
		this.updateLinkAttributes('icon', 'image/png', faviconHref, '16x16');
		this.updateLinkAttributes('apple-touch-icon', null, appleTouchIconHref, '180x180');
	}

	updateLinkAttributes(rel, type, href, sizes = null) {
		const link = document.querySelector(`link[rel="${rel}"][sizes="${sizes}"]`) || document.createElement('link');
		if (type) link.type = type;
		link.rel = rel;
		link.href = href;
		if (sizes) link.sizes = sizes;
		document.getElementsByTagName('head')[0].appendChild(link);
	}
}

export const faviconService = new FaviconService();

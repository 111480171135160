<template>
	<svg height="20" viewBox="0 0 19 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.75818 10.2912L3.22318 3.99243H16.2442L17.8322 10.0266V21.0892H4.86404L4.75818 10.2912Z" stroke="none" stroke-width="1.8" stroke-linejoin="round"></path>
		<path d="M1 10.2915L3.22311 3.99268L4.81104 10.2915V21.0894H1V10.2915Z" stroke="none" stroke-width="1.8" stroke-linejoin="round"></path>
		<rect x="2.05853" y="1.45166" width="15.2442" height="2.54069" rx="0.635173" stroke="none" stroke-width="1.8"></rect>
		<rect x="1.95282" y="1.45166" width="2.43483" height="2.54069" rx="0.635173" stroke="none" stroke-width="1.8"></rect>
	</svg>
</template>

<script>
export default {
	name: 'BagOrdersIcon',
	props: {
		fillColor: String
	}
}
</script>

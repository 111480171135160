<template>
	<!--Modal Reserva correcta-->
	<div class="modal fade" id="modalCameraScanSuccess" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="exampleModalLabel">¡ Hola {{ nameUser }} !</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-success animate">
										<span class="f-modal-line f-modal-tip animateSuccessTip"></span><span class="f-modal-line f-modal-long animateSuccessLong"></span>
										<div class="f-modal-placeholder"></div>
										<div class="f-modal-fix"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="row row-margin-x">
							<div class="col-12">
								Dispone de una reserva para hoy a nombre de <b> {{ nameReservation }} </b> a las
								<b>{{ hourReservation }}H</b> para <b>{{ paxReservation }}</b> personas en
								<b>{{ roomReservation }}</b>.
							</div>
						</div>
						<div class="row row-margin-x">
							<div class="col-12">
								ya está activado el acceso a nuestra carta digital para que usted pueda pedir y ser atendido a través de su dispositivo móvil.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>
	<!--Modal Tiene usuario pero no reserva-->
	<div class="modal fade" id="modalCameraScanNotReservation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="exampleModalLabel">¡ Hola {{ nameUser }} !</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-warning scaleWarning">
										<span class="f-modal-body pulseWarningIns"></span>
										<span class="f-modal-dot pulseWarningIns"></span>
									</div>
								</div>
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								UPS! parece que no tenemos una reserva para hoy a su nombre.
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								Ha sido avisado nuestro encargado e intentaremos solucionar esta incidencia.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>
	<!--Modal Error con el QR-->
	<div class="modal fade" id="modalCameraScanError" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="exampleModalLabel">¡ Lo sentimos !</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-error animate">
									<span class="f-modal-x-mark">
										<span class="f-modal-line f-modal-left animateXLeft"></span>
										<span class="f-modal-line f-modal-right animateXRight"></span>
									</span>
										<div class="f-modal-placeholder"></div>
										<div class="f-modal-fix"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								No reconocemos el código QR.
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								Ha sido avisado nuestro encargado e intentaremos solucionar esta incidencia.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>
	<!--Modal Error hoy no es el día de la reserva-->
	<div class="modal fade" id="modalCameraScanErrorReservation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="exampleModalLabel">¡ Hola {{ nameUser }} !</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-warning scaleWarning">
										<span class="f-modal-body pulseWarningIns"></span>
										<span class="f-modal-dot pulseWarningIns"></span>
									</div>
								</div>
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								UPS! parece que no tenemos una reserva para hoy a su nombre.
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								Tenemos una anotada a nombre de <b>{{ nameReservation }}</b> para el día
								<b>{{ hourReservation }}</b> a las <b>{{ hourReservation }}</b> para
								<b>{{ paxReservation }}</b> personas.
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								Ha sido avisado nuestro encargado e intentaremos solucionar esta incidencia.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as bootstrap from "bootstrap";
import {mapGetters} from "vuex";
import {hideAllModals} from "@/utils";
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalNotificationWelcom',
	components: {DismissButton},
	emits: [],
	computed: {
		...mapGetters('kiosk', ["modalScannerQr"]),
		firstReservation() {
			return this.modalScannerQr.firstReservation || {}
		},
		user() {
			return this.modalScannerQr.user || {}
		},

		hourReservation() {
			return this.firstReservation.startDate
		},
		nameReservation() {
			return this.firstReservation.name
		},
		paxReservation() {
			return this.firstReservation.pax
		},
		roomReservation() {
			return this.firstReservation.roomName
		},

		nameUser() {
			return this.user.name
		}
	},
	data() {
		return {}
	},
	mounted() {

	},
	beforeUnmount() {

	},
	watch: {
		modalScannerQr(newValue, oldValue) {
			console.log('Cambiando el valor de la valiable computada', newValue, oldValue)
			if (newValue.showModal === true) {
				this.showModal();
				hideAllModals();
			}
			if (newValue === false) {
				// this.showModal('scannerIncorrectly');
			}
		},
	},
	methods: {
		// showToast() {
		// 	const toastElement = document.getElementById(this.toast.idToast);
		// 	const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
		// 	toastInstance.show();
		// },
		showModal() {
			const modalElement = document.getElementById(this.modalScannerQr.idModal);
			if (modalElement) {
				let modalInstance = bootstrap.Modal.getInstance(modalElement);
				if (!modalInstance) {
					modalInstance = new bootstrap.Modal(modalElement, {
						backdrop: 'static',
						keyboard: false
					});
				}
				modalInstance.show();

				setTimeout(() => {
					modalInstance.hide();
				}, 20000);

			} else {
				console.error('Modal element not found:', this.modalScannerQr.idModal);
			}
		}
	}
}
</script>

<template>
	<div  v-if="pendingNotifications !== null"  class="modal fade" id="pending-notifications-copilot-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">Notificaciones pendientes</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-for="notification in pendingNotifications" :key="notification.id" class="notification-copilot" :id="notification.id">
					<div class="notification-copilot-header">
						<div>
							<i class="fas fa-clock"></i> {{ this.dateFormatterService.formattedTime(notification.dateCreated) }}
						</div>
						<button class="btn btn-primary btn-rounded active"  @click="replayNotification(notification)">
							<i class="fas fa-play"></i>
						</button>
						<button class="btn btn-primary btn-rounded active"  @click="handleMarkAsRead(notification)">
							Marcar como leida
						</button>
					</div>
					<div class="notification-copilot-body">
						<div>{{ notification.message }}</div>
					</div>
				</div>

				<div class="modal-footer">

					<button type="button" class="btn btn-primary" @click="handleMarkAsRead()">Limpiar todas las notificaciones</button>
				</div>
			</div>
		</div>
	</div>

</template>
<script>


import {mapGetters, mapMutations} from "vuex";

export default {
	name: 'CopilotPendingNotifications',
	inject: {
		speakService: 'speakService',
		dateFormatterService: 'dateFormatterService',
	},
	computed: {
		...mapGetters('copilot', ['pendingNotifications', 'notificationMode']),
	},
	data() {
		return {
			notificationsInterval: null
		}
	},
	mounted() {

	},
	beforeUnmount() {

	},
	watch: {
		notificationMode(){
			if(this.notificationMode > 0){
				this.notificationsInterval = setInterval(async ()=>{
					if(this.pendingNotifications.length > 0){
						// this.speakService.speak(this.generateNotificationsMessage())
						await this.speakService.playHavePendingNotifications(this.getPendingNotificationAudioSrc())
					}
				}, Number(this.notificationMode ) * 60 * 1000)
			}else{
				clearInterval(this.notificationsInterval)
			}
		}
	},
	methods: {
		...mapMutations('copilot', ['deleteNotificationToPendingNotifications']),
		replayNotification(notification) {
			console.log(this.pendingNotifications)
			this.speakService.playNotification(notification)
		},
		summarizePendingNotifications(){
			const actionsDictionary = {
				2: "mesas que han pedido la cuenta,",
				"2-S": " una mesa que ha pedido la cuenta,",
				11: "pedidos listos para servir",
				"11-S": " un pedido listo para servir",
				13: "pedidos para anotar",
				"13-S": " un pedido para anotar",
				9: "pedidos para cocinar",
				"9-S": " hay un pedido para cocinar"
			}

			const countByType = this.pendingNotifications.reduce((acc, { type }) => {
				acc[type] = (acc[type] || 0) + 1;
				return acc;
			}, {});

			const summaryNotification = Object.entries(countByType).map(([type, count]) => {
				const description = actionsDictionary[type];
				const descriptionSingular = actionsDictionary[`${type}-S`];
				return count >1 ? `hay ${count} ${description}` : `${descriptionSingular}`;
			});

			return summaryNotification.join(", ");
		},

		summarizePendingNotificationsNumber(){
			const now = new Date();
			const nowTime = now.getTime();
			const pastTime = nowTime - ( Number(this.notificationMode) * 1000);

			const lastNotifications = this.pendingNotifications.filter(notification => {
				const notificationTime = new Date(notification.receivedAt).getTime();
				console.log('Notification Time:', notificationTime, '>= Past Time:', pastTime, '&&', notificationTime, '<=', nowTime);
				return notificationTime >= pastTime && notificationTime <= nowTime;
			});
			return lastNotifications.length
		},

		handleMarkAsRead(notification = null){
			const payload = notification ? { notificationId: notification.id } : {};
			this.deleteNotificationToPendingNotifications(payload);
		},

		generateNotificationsMessage(){
			const newNotifications = this.summarizePendingNotificationsNumber();
			const interval = Number(this.notificationMode);

			let message = interval > 1 ? `En los últimos ${interval} minutos, ` : 'En el último minuto, '

			if(this.pendingNotifications.length === newNotifications){
				message += newNotifications > 1
					? `Tienes ${newNotifications} notificaciones nuevas`
					: 'Tienes una notificación nueva'

				return message
			}

			if(newNotifications === 0){
				return this.pendingNotifications.length > 1
					? `Tienes ${this.pendingNotifications.length} notificaciones pendientes.`
					: `Tienes una notificación pendiente.`
			}

			message += newNotifications > 1
				? `Tienes ${newNotifications} notificaciones nuevas, de un total de ${this.pendingNotifications.length}`
				: `Tienes una notificación nueva, de un total de ${this.pendingNotifications.length}`

			return message

		},

		getPendingNotificationAudioSrc(){
			const newNotifications = this.summarizePendingNotificationsNumber();
			return newNotifications > 0 ? "https://wewelcom.io/world/snd/we-notifications-available.mp3" : "https://wewelcom.io/world/snd/we-notifications-pending.mp3"
		}
	}
}
</script>


<template src="./copilot.html"></template>

<script>
import {DateTime} from 'luxon';
import * as bootstrap from 'bootstrap';
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import {WebSocketClient} from "@/service/webSocketClient";
import store from "@/store";
import {hideAllModals, hideAllOffcanvas} from "@/utils";
import CheckCopilotModal from "@/components/checkCopilotModal/CheckCopilotModal";
import WebSocketWorker from '@/service/worker/wsWorker.worker.js'
import DismissButton from "@/core/DismissButton";
import CopilotMainComponent from "@/components/copilotMainComponent/CopilotMainComponent";
import CopilotContainerKitchen from "@/components/copilotContainerKitchen/copilotContainerkitchen";
import CopilotDailyMenu from "@/components/copilotDailyMenu/CopilotDailyMenu";

export default {
	name: 'CopilotWelcom',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		speakService: 'speakService',
		listenService: 'listenService',
		harvisService: 'harvisService',
		cameraService: 'cameraService',
		copilotRepository: 'copilotRepository',
		dateFormatterService: 'dateFormatterService',
		menuRepository: 'menuRepository',
	},
	data() {
		return {
			webSocketClient: WebSocketClient,
			notificationModeLocal: "0",
			selectedRoleLocal: this.selectedRole,
			isNotificationPlaying: false,
			inputs: [{id: 1, placeholder: "Nombre del plato:"}],
		};
	},
	computed: {
		...mapGetters('menu', ['menuProducts']),
		...mapGetters('user', ['user']),
		...mapGetters('config', ['websocketUrl', 'websocketPort', 'isProductionEnvironment']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'venueId', 'reservationManagerUrl', 'menuServices']),
		...mapGetters('copilot', ['view', 'availableRoles', 'pendingOrders', 'selectedRole', 'selectedDate', 'reservations', 'accounts', 'notifications', 'assistantNotifications', 'isPlayNotificationSound', "isLoadingGetReservations", "isShowToastScanQr", "newNotification", "isKitchenSelectedRole", "commanderReservation", 'notificationMode', 'tables']),
		roleFilteredNotifications() {
			let selectedRoleObject = this.availableRoles.find(role => role.id === this.selectedRole);
			return this.assistantNotifications.filter(notification => {
				// If selectedRole is 0, include the notification by default
				if (Number(this.selectedRole) === 0 && notification.type !== 9 && notification.type !== 6) {
					return true;
				}

				if (selectedRoleObject == null) {
					return false;
				}
				let selectedRoleNotificationTypes = selectedRoleObject.notificationTypes;

				if (selectedRoleNotificationTypes == null) {
					return false;
				}

				// Extract the IDs of the selectedRole's notificationTypes
				const selectedRoleNotificationTypeIds = selectedRoleNotificationTypes.map(nt => nt.id);

				// Check if any of the notification's notificationTypes match any of the selectedRole's notificationTypes
				return notification.notificationTypes && Array.isArray(notification.notificationTypes)
					? notification.notificationTypes.some(notificationType =>
						selectedRoleNotificationTypeIds.includes(notificationType.id)
					)
					: false;
			});

		},
		latestNotification() {
			return this.roleFilteredNotifications.length > 0 ? this.roleFilteredNotifications[0] : null;
		},
		notificationHistory() {
			return this.roleFilteredNotifications.length > 1 ? this.roleFilteredNotifications.slice(1) : [];
		},
		rolSelectedName() {
			const role = this.availableRoles.find(i => i.id === this.selectedRole);
			hideAllOffcanvas();
			return role ? role.name : 'Todos los mensajes';
		},
	},
	watch: {
		async newNotification() {
			console.log('Se debe de escuchar la notificacion???', this.isNotificationPlayable(this.newNotification))
			if (this.isNotificationPlayable(this.newNotification)) {
				await this.speakService.enqueueNotification(this.newNotification);
				store.commit('copilot/setIsPlayNotificationSound', {isPlayNotificationSound: false});
			}
		},
		selectedRole: {
			handler(newValue) {
				this.selectedRoleLocal = newValue;
			},
			immediate: true // Esto asegura que se establece el valor inicial al montar el componente
		},
		selectedRoleLocal(newValue) {
			if (newValue !== this.selectedRole) {
				store.commit('copilot/setSelectedRole', {selectedRole: newValue});
			}
		},
		isShowToastScanQr(newValue, oldValue) {
			console.log('Cambiando el valor de la valiable computada', newValue, oldValue)
			if (newValue === true) {
				this.showToast('scannerCorrectly');
				hideAllModals();
				store.commit('copilot/setIsShowToastScanQr', {isShowToastScanQr: null})
			}
			if (newValue === false) {
				this.showToast('scannerIncorrectly');
				store.commit('copilot/setIsShowToastScanQr', {isShowToastScanQr: null})
			}
		},
		notificationModeLocal(newValue, oldValue) {
			console.log('Ha cambiado el modo notificacion de ', oldValue, ' a ', newValue);
			store.commit('copilot/setNotificationMode', {notificationMode: newValue})
		},

	},
	components: {
		FooterWelcom,
		CheckCopilotModal,
		DismissButton,
		CopilotMainComponent,
		CopilotContainerKitchen,
		CopilotDailyMenu
	},
	created() {
		this.worker = new WebSocketWorker();
		this.worker.onmessage = async (event) => {
			console.log('Mensaje recibido del worker:', event.data);
			switch (event.data.type) {
				case 'response':
					console.log('Respuesta del worker:', event.data.payload);
					break;

				case 'pong':
					await this.speakService.playNotificationAlertSound();
					break;
			}
		}

		this.worker.postMessage({type: 'check', payload: "Esto es una prueba"});
	},
	async beforeMount() {
		let uidCopilot = 'COPILOT' + '_' + this.venueId;
		console.log("Iniciando conexión al websocket con ", uidCopilot, 'le vamos a pasar el component')
		this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
		this.webSocketClient.connect(uidCopilot);

		this.worker.postMessage({
			type: 'connect',
			payload: {url: this.websocketUrl, port: this.websocketPort, clientId: uidCopilot}
		});

	},
	async mounted() {

		if (this.isProductionEnvironment) {
			this.showInitialCheckModal();
		}

		this.isLoad = true;

		this.setHeightTo100vh();

	},
	methods: {
		showInitialCheckModal() {
			const modalElement = document.getElementById('check-copilot-modal');
			let myModal = bootstrap.Modal.getInstance(modalElement);
			myModal = new bootstrap.Modal(modalElement);
			myModal.show();
		},
		formatNotificationDate(notification) {
			let dateTime = DateTime.fromFormat(notification.dateCreated, "dd/MM/yyyy HH:mm:ss");

			return dateTime.toFormat("HH:mm:ss");
		},

		showToast(toastId) {
			const toastElement = document.getElementById(toastId);
			const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
			toastInstance.show();
		},

		async updateReservations() {
			this.isLoading = true;
			await store.dispatch('copilot/updateReservations');
			this.isLoading = false;
		},

		isNotificationPlayable(notification) {
			console.log('Vamos a comprobar que devuelve la funcion isNotificationPlayable')
			if (notification.creatorUserId === this.user.id) return false

			let selectedRoleObject = this.availableRoles.find(role => role.id === this.selectedRole)
			const user = JSON.parse(localStorage.getItem('user'));
			// TODO Temporal desarrollo, donde no se accede a copilot con usuario
			if (user != null && user.copilotRoles) {
				console.log("El usuario tiene roles asignados", user.copilotRoles);
				/*this.assistantNotifications = result.we.filter(notification =>  {
					return notification.roles.some(role => user.copilotRoles.some(copilotRole => copilotRole.roleId === role.id));
				});*/
			} else {
				console.log("El usuario no tiene roles")
			}

			console.log('Anrtes de: ', notification)
			if (Number(this.selectedRole) === 0 && notification.type !== 9 && notification.type !== 6) {
				return true;
			}
			console.log('El selectedRoleObject', selectedRoleObject)
			if (selectedRoleObject == null) {
				return false;
			}
			let selectedRoleNotificationTypes = selectedRoleObject.notificationTypes;


			if (selectedRoleNotificationTypes == null) {
				return false;
			}

			// Extract the IDs of the selectedRole's notificationTypes
			const selectedRoleNotificationTypeIds = selectedRoleNotificationTypes.map(nt => nt.id);

			console.log('selectedRoleNotificationTypeIds', selectedRoleNotificationTypeIds, 'la notificacion', notification)
			const notificationRoles = notification.roles.map(role => role.id)
			console.log('Los rolesNotifications', notificationRoles);


			// Check if any of the notification's notificationTypes match any of the selectedRole's notificationTypes
			return notificationRoles.includes(selectedRoleObject.id);
		},

		async updateNotifications() {
			await store.dispatch('copilot/updateOrdersAndNotifications')
		},

		replayNotification(notification) {
			this.speakService.playNotification(notification)
		},

		setHeightTo100vh() {
			var appElement = document.getElementById('welcom-copilot');
			if (appElement) {
				appElement.style.height = '100vh';
				appElement.style.display = 'flex';
				appElement.style.flexDirection = 'column';
			}

			var footerElement = document.getElementsByTagName('footer')[0];
			if (footerElement) {
				footerElement.style.marginTop = 'auto';
			}
		},

	},
}
</script>

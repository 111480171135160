<template src="./modalScannerQR.html"></template>

<script>
import * as bootstrap from "bootstrap";

import {mapGetters} from "vuex";
import {useRoute} from "vue-router";
import {hideAllModals} from "@/utils";
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalScannerQR',

	data() {
		return {}
	},
	watch: {},
	props: {},
	inject: {
		cameraService: 'cameraService',
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName']),
		...mapGetters('copilot', ['reservationId']),
		...mapGetters('config',['weWelcomUrl']),

		isInKiosksRoute() {
			const route = useRoute();
			return route.path === '/kiosk';
		},
	},
	components: {
		DismissButton
	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {
		// showToast(toastId) {
		// 	const toastElement = document.getElementById(toastId);
		// 	const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
		// 	toastInstance.show();
		// },

		showModalAlert(modalId) {
			const modalCheckInbox = new bootstrap.Modal(document.getElementById(modalId), {
				backdrop: 'static',
				keyboard: false
			});
			modalCheckInbox.show();
		},
		openQRInvitation() {
			this.cameraService.stopCamera()

			console.log(this.reservationId)
			hideAllModals();
			const collapseElement = document.getElementById('modalQr' + this.reservationId);
			if (collapseElement) {
				const collapseInstance = new bootstrap.Modal(collapseElement, {
					toggle: false
				});
				collapseInstance.show();
			}
		},
		stopCameraScanner(){
			this.cameraService.stopCamera()
		}

	}
}
</script>

<template>
	<nav id="navbar-scroll-categories" class="navbar-container-scroll-categories gap-3 justify-content-center" :class="{'position-absolute':isCopilotRoute}">
		<menu-searcher
			v-if="menus.length"
			:menus="menus"
			:is-copilot-route="isCopilotRoute"
			:reservation-promotion="reservationPromotion"
			:is-Valid-Reservation="isValidReservation"
			:product-unit="productUnit"
			:small-buttons="true"
			@subtract-unit="$emit('subtract-unit', $event)"
			@add-product="$emit('add-product', $event)"
			@add-unit="$emit('add-unit', $event)"
			@filter-menus="$emit('filter-menus', $event)"
			@search-expand="handleSearchToggle"
		></menu-searcher>
		<!--		<menu-searcher v-if="menus.length" :menus="menus" :is-copilot-route="isCopilotRoute" :reservation-promotion="reservationPromotion" :is-Valid-Reservation="isValidReservation" @subtract-unit="subtractUnit" @add-product="addProductToCart" @add-unit="addUnit" @filter-menus="filterMenuCategoriesByProducts" :product-unit="productsCart"></menu-searcher>-->

		<template v-for="(menu, index) in activeMenu" :key="menu.id">
			<a
				class="link-menu btn btn-light"
				:class="{ 'active': menu.id + menu.name === activeCategory, 'hidden-text': isSearchExpanded || (index === 0 && activeCategory),'link-position-end hidden-text': isSearchExpanded || (index === 1 && isFirstActive)}"
				@click.prevent="scrollTo(menu.id + menu.name)"
				:id="menu.id + '-' + menu.name"
				style="flex-shrink: 0"

			>
				<i :class="getIconClass(menu.id)"></i>
				<span>{{ menu.name }}</span>
			</a>
			<div v-if="expandedCategory === menu.id + menu.name && !isSearchExpanded" :class="{'overflow-hidden': true, 'flex-grow-1': true, 'd-flex': true}">
				<div class="scroll-x p-0 d-flex" :ref="'productContainer-' + menu.id ">
					<a
						v-for="category in menu.menuCategories"
						:key="category.id"
						:id="'link-' + category.id"
						:class="{ 'active': isActiveCategory(category) }"
						style="scroll-snap-align: start;flex-shrink: 0;"
						class="link-item"
						@click.prevent="scrollTo(category.id + '-' + category.name)"
						:data-bs-section="category.id + '-' + category.name"
					>
						{{ category.name }}
					</a>
				</div>
			</div>
		</template>
	</nav>
</template>

<script>
// import VueScrollTo from 'vue-scrollto';

import MenuSearcher from "@/components/menuSearcher/MenuSearcher";

export default {
	name: "NavbarScrollCategories",
	components: {MenuSearcher},
	emits: ['add-product', 'add-unit', 'subtract-unit', 'filter-menus'],
	props: {
		activeMenu: {
			type: Array,
			default: () => []
		},
		isCopilotRoute: {
			type: null,
		},
		isValidReservation: {
			type: null,
		},
		reservationPromotion: {
			type: null,
		},
		productUnit: {
			type: null
		},
		menus: {
			type: null
		}
	},
	computed: {},
	data() {
		return {
			activeCategory: null,
			expandedCategory: null,
			activeProduct: null,
			isFirstActive: false,
			scrollContainer: null,
			isSearchExpanded: false,
		};
	},
	mounted() {
		this.initializeScrollListener();
	},
	beforeUnmount() {
		if (this.scrollContainer) {
			this.scrollContainer.removeEventListener('scroll', this.handleScroll);
		}
	},
	methods: {
		initializeScrollListener() {
			let offcanvasBody = document.querySelector('#offcanvas-body-commander');
			// Verifica si el navbar está dentro de un offcanvas
			if (offcanvasBody && offcanvasBody.contains(this.$el)) {
				console.log('Es un offcanvas')
				this.scrollContainer = offcanvasBody;
			} else {
				console.log('Es un body')
				this.scrollContainer = window;  // Caso contrario, usa la ventana
			}
			this.scrollContainer.addEventListener('scroll', this.handleScroll);

		},
		handleScroll() {
			let currentActiveCategory = null;
			const tolerance = 0;
			let scrollY = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop;

			this.activeMenu.forEach((menu, index) => {
				const categoryElement = document.getElementById(menu.id + menu.name);
				if (categoryElement) {
					const bounding = categoryElement.getBoundingClientRect();
					if (bounding.top <= tolerance && bounding.bottom >= tolerance) {
						currentActiveCategory = menu.id + menu.name;
						this.expandedCategory = menu.id + menu.name;
						this.isFirstActive = index === 0;
						const linkMenu = document.getElementById(menu.id + '-' + menu.name);
						linkMenu.classList.add('hidden-text')

						menu.menuCategories?.forEach(category => {
							const elementId = category.id;
							const element = document.getElementById(`link-${elementId}`);

							if (element) {
								const targetSection = document.getElementById(elementId + '-' + category.name);

								if (targetSection) {
									const rect = targetSection.getBoundingClientRect();
									const sectionTop = rect.top + window.pageYOffset - tolerance;

									if (scrollY >= sectionTop && scrollY < sectionTop + rect.height) {
										this.activeProduct = elementId;
										element.classList.add('active');

										// Asegurarse de que el elemento activo se alinee al inicio
										let container = document.querySelector('.scroll-x');
										let scrollLeft = element.offsetLeft - container.offsetLeft;
										container.scrollTo({
											left: scrollLeft,
											behavior: 'smooth'
										});

									} else {
										element.classList.remove('active');
									}
								}
							}
						});
					}
				}
			});

			if (this.activeCategory !== currentActiveCategory) {
				this.activeCategory = currentActiveCategory;
				if (!currentActiveCategory) {
					this.expandedCategory = null;
					this.isFirstActive = false;
				}
			}
		},
		handleSearchToggle(isExpanded) {
			this.isSearchExpanded = isExpanded;
		},
		scrollTo(targetId) {
			const targetSection = document.getElementById(targetId);
			if (targetSection) {
				// Calcula la posición del elemento dentro del contenedor
				const rect = targetSection.getBoundingClientRect();
				const scrollContextTop = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop;
				const absoluteTopPosition = rect.top + scrollContextTop;

				// Considera el offset de una barra de navegación, si existe
				const navbar = document.querySelector('.navbar');
				const navbarOffset = navbar ? -10 : -10;

				// Calcula la posición final del scroll, asegurando que el elemento esté alineado al inicio
				const finalScrollPosition = absoluteTopPosition - navbarOffset;

				if (this.scrollContainer === window) {
					window.scrollTo({
						top: finalScrollPosition,
						behavior: 'smooth'
					});
				} else {
					this.scrollContainer.scrollTo({
						top: finalScrollPosition,
						behavior: 'smooth',
					});
				}
			}
		},
		isActiveCategory(category) {
			return this.activeProduct === category.id + '-' + category.name;
		},
		getIconClass(type) {
			return type === 'food' ? 'fas fa-utensils' : 'fas fa-wine-glass'
		},
	}
};
</script>

<template>
	<div :class="['input-group', { 'hidden-input': !isSearchExpanded }]" @click="toggleSearchExpand">
		<span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
		<input
			ref="searchInput"
			type="text"
			class="form-control"
			placeholder="Escribe aquí para buscar tu producto"
			v-model="searchQuery"
			@input="search"
		>
		<span v-if="isSearchExpanded" class="input-group-text" @click="handleInputAction" style="cursor: pointer;">
			<i class="fa-solid fa-circle-xmark"></i>
		</span>
	</div>
<!--	<ul v-if="filteredProducts.length > 0" class="p-0">-->
<!--		<li v-for="product in filteredProducts" :key="product.sku">-->
<!--			<menu-product-card-->
<!--				:product="product"-->
<!--				:is-copilot-route="isCopilotRoute"-->
<!--				:product-unit="productUnit"-->
<!--				:is-valid-reservation="isValidReservation"-->
<!--				@add-product="addProductToCart"-->
<!--				@add-unit="addUnit"-->
<!--				@subtract-unit="subtractUnit"-->
<!--			></menu-product-card>-->
<!--		</li>-->
<!--	</ul>-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: 'MenuSearcher',
	emits: ['add-product', 'add-unit', 'subtract-unit', 'filter-menus', 'search-expand'],
	props: {
		menus: {
			type: Array,
			required: true,
			validator(value) {
				return value.every(item => typeof item === 'object' && item !== null);
			}
		},
		isValidReservation: {
			type: Boolean,
			required: false
		},
		reservationPromotion: {
			type: [Object, null],
			required: false
		},
		isCopilotRoute: {
			type: Boolean,
			required: true
		},
		productUnit: {
			type: Object,
		},
		smallButtons: {
			type: Boolean
		}
	},
	data() {
		return {
			searchQuery: '',
			filteredProducts: [],
			isSearchQueryContent: false,
			isSearchExpanded: false,
			isClosingFromButton: false,
			searchTimeout: null
		};
	},
	computed: {
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('venue', ['businessName', 'urlVenue', 'resourceId']),
		products() {
			return this.menus
				.flatMap(item => item.menuCategories)
				.flatMap(menuCategory => menuCategory.products);
		}
	},
	methods: {
		highlightText(query) {
			this.clearHighlights();

			const normalizedQuery = this.normalizeText(query);
			const regex = new RegExp(`(${normalizedQuery})`, 'gi');

			document.querySelectorAll('.product-card-client .product-card-title, .product-card-client .product-card-text, .product-card-client .food-desc').forEach(element => {
				element.innerHTML = this.normalizeText(element.textContent).replace(regex, '<mark>$1</mark>');
			});
		},
		clearHighlights() {
			document.querySelectorAll('.product-card-client mark').forEach(mark => {
				const parent = mark.parentNode;
				parent.replaceChild(document.createTextNode(mark.textContent), mark);
				parent.normalize();
			});
		},
		normalizeText(text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
		},
		toggleSearchExpand() {
			if (this.isSearchExpanded && (this.searchQuery.length > 0 || document.activeElement === this.$refs.searchInput)) {
				return;
			}

			if (this.isClosingFromButton) {
				this.isClosingFromButton = false;
				return;
			}

			this.isSearchExpanded = !this.isSearchExpanded;
			this.emitSearchExpand(this.isSearchExpanded);

			if (this.isSearchExpanded) {
				this.$nextTick(() => {
					this.$refs.searchInput.focus();
				});
			}
		},
		emitSearchExpand(isExpanded) {
			this.$emit('search-expand', isExpanded);
		},
		search() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				if (this.searchQuery.length > 1) {
					this.filteredProducts = this.filterMenus();
					this.highlightText(this.searchQuery);
					this.isSearchQueryContent = true;
				} else {
					this.clearSearchResults();
				}
			}, 300); // 300ms de debounce
		},
		clearSearchResults() {
			this.filteredProducts = [];
			this.$emit('filter-menus', null);
			this.isSearchQueryContent = false;
		},
		handleInputAction() {
			// if (this.searchQuery.length > 0) {
			// 	this.clearInputSearch();
			// 	this.clearHighlights();
			// } else {
				this.isClosingFromButton = true;
				this.isSearchExpanded = false;
				this.emitSearchExpand(false);
				this.clearInputSearch();
				this.clearHighlights();
			// }
		},
		clearInputSearch() {
			this.searchQuery = '';
			this.search();
			// this.$refs.searchInput.focus();
		},
		filterMenus() {
			const query = this.normalizeText(this.searchQuery);
			const filteredMenus = this.menus.map(category => this.filterCategory(category, query)).filter(Boolean);

			this.$emit('filter-menus', filteredMenus);
			this.$nextTick(() => {
				this.scrollToFirstProduct(filteredMenus);
			});

			return filteredMenus.flatMap(category => category.menuCategories.flatMap(subCategory => subCategory.products));
		},
		filterCategory(category, query) {
			const filteredSubCategories = category.menuCategories
				.map(subCategory => this.filterSubCategory(subCategory, query))
				.filter(Boolean);

			return filteredSubCategories.length > 0 ? { ...category, menuCategories: filteredSubCategories } : null;
		},
		filterSubCategory(subCategory, query) {
			const filteredProducts = subCategory.products
				.map(product => this.filterProduct(product, query))
				.filter(Boolean);

			return filteredProducts.length > 0 ? { ...subCategory, products: filteredProducts } : null;
		},
		filterProduct(product, query) {
			const regex = new RegExp(`(${query})`, 'gi');
			const highlightedName = product.name.replace(regex, '<mark>$1</mark>');
			const highlightedDescription = product.description ? product.description.replace(regex, '<mark>$1</mark>') : '';

			return this.normalizeText(product.name).includes(query) ||
			(product.description && this.normalizeText(product.description).includes(query))
				? { ...product, highlightedName, highlightedDescription }
				: null;
		},
		scrollToFirstProduct(menus) {
			if (menus.length > 0) {
				const firstProduct = menus[0];

				let productElement;

				if (firstProduct.menuTypeName === 'Bebida') {
					productElement = document.getElementById(`drink${firstProduct.menuTypeName}`);
				} else {
					productElement = document.getElementById(`food${firstProduct.menuTypeName}`);
				}

				if (productElement) {
					const elementPosition = productElement.getBoundingClientRect().top + window.scrollY;
					window.scrollTo({
						top: elementPosition,
						behavior: 'smooth'
					});
				}
			}
		},
		addProductToCart(product, units, options) {
			this.$emit('add-product', product, 1, options);
		},
		addUnit(product) {
			this.$emit('add-product', product);
		},
		subtractUnit(product) {
			this.$emit('subtract-unit', this.matchingProductUnits(product));
		},
		matchingProductUnits(product) {
			return this.productUnit.find(unit => unit.sku === product.sku) || { cartItemCount: 0 };
		}
	}
}
</script>

<style scoped>
li {
	list-style: none;
	margin: 0;
	padding: 0;
}
</style>

<div v-if="reservation.status !== 0" class="service-reservation-container" :id="'reservation-' + reservation.id" :data-bs-parent="`childrenCollapseService${serviceName}`">
	<div class="service-reservation" :class="{[reservationStatus(reservation)]:true,'table-dubbing': hasDubbing}" @click="openCollapse(reservation.id)" role="button">
		<!--  primera linea de datos de la reserva	-->
		<div class="row service-reservation-data">
			<div class="col-3">
				<div class="service-meta">
					<span class="icon-reservation">
						<i v-if="isWalkin" class="fas fa-walking"></i>
						<SittingIcon fill-color="white" v-if="isSitting"/>
						<i v-if="isReserve" class="fas fa-qrcode"></i>
					</span>
					<div>{{ reservation.startDate }}h</div>
				</div>
			</div>
			<div class="col-2">
				<button :disabled="paxUpdateDisabled" class="btn btn-light w-100 btn-sm" @click="openModalUpdatePax('offcanvasUpdatePax-' + reservation.id)">
					<i class="fas fa-user"></i> {{ reservation.pax }}
				</button>
			</div>
			<div class="col-7">
				<copilot-card-select-table :reservation="reservation"></copilot-card-select-table>
			</div>
		</div>

		<div  class="row service-reservation-data">
			<div class="col-3" >
				<div class="service-meta" v-if="reservationReview">
					<i class="fas fa-star" style="color: var(--bs-secondary)"></i>
					<b>{{ reservationReview }}</b>
				</div>
			</div>
			<div class="col-7">
			</div>
			<div class="col-2">
			</div>
			<div class="col-12" v-if="reservations.status === 7">
				La reserva se moverá a 'Finalizadas' automáticamente.
			</div>
		</div>

		<span v-if="pendingOrders && pendingOrders.length > 0 && isReservationRequest" class="alert-reserve order">
			Pedido
		</span>

		<span v-if="notifications[0] && notifications[0].reservation.id === reservation.id" class="alert-reserve process-pay">
			Cuenta pedida
		</span>
<!--		<span class="alert-reserve account" v-if="reservation.isPaid">-->
<!--			Pagado con-->
<!--		</span>-->
<!--		<span v-if="reservation.status === 10" class="alert-reserve account">-->
<!--			Pagado Tarjeta-->
<!--		</span>-->

		<div class="collapse" :id="'collapse' + reservation.id">
			<div class="collapse-body mb-0 gap-3 container">
				<!--Pedidos-->
				<div v-if="pendingOrders && pendingOrders[0] && isReservationRequest" class="order-container order-container-copilot">
					<div class="order-data">
						<div class="order-data-body">
							<span>
								<i class="fas fa-utensils"></i> <b>{{ pendingOrders[0].userName }}</b>
							</span>
							<span>
								<i class="fas fa-clock"></i> {{ elapsedTime }}
							</span>
						</div>
					</div>
					<div class="products-list-content">
						<orders-product-card :order="order" v-for="order in pendingOrders[0].items" :key="order.productSku"></orders-product-card>
					</div>
					<button @click="handleProcessOrder(pendingOrders[0].cartSessionOrderId)" class="btn btn-success w-100">
						<span class="process-button" v-html="buttonTextOrder"></span></button>
				</div>

				<!--  segunda linea de datos de la reserva	-->
				<div class="row service-reservation-data">
					<div class="col-12">
						<i class="fas fa-users me-1"></i>
						<span v-if="reservation.reservationUsers">
							<span v-html="invitationText"></span>
						</span>
						<span v-else>No hay invitados</span>
					</div>

				</div>
				<div class="row g-2">
					<div class="col-6">
						<button v-if="isReservationStatusStart && !isReservationCancelled && reservation.finishedDateTime === null && !isReservationFinish" class="btn btn-light w-100 " @click.stop="openComanderMenu" type="button">
							Comandero
						</button>
					</div>
					<div class="col-6">
						<button
							class="btn btn-secondary w-100"
							@click.stop="reservation.id.includes('WALKIN') ? openQRInvitation(reservation.id, $event) : handleOptionClick('camera', reservation.id)">
							<span v-if="reservation.id.includes('WALKIN')">Ver</span>
							<span v-else><i class="fas fa-camera"></i></span>QR
						</button>
					</div>
					<div class="col-6">
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 0" class="btn btn-light w-100" @click="handelFinishReservation(1)">no show</button>
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 1 && !isReservationFinish" class="btn btn-light w-100" data-bs-toggle="modal" :data-bs-target="'#modalConfirmClose' + reservation.id">Cuenta pedida</button>
						<button v-else v-if="isReservationStatusStart || isNoShow" class="btn btn-light w-100" @click="handelReactivateReservation">
							<span v-html="reactivateReservationTextButton"></span></button>
					</div>
					<div class="col-6">
						<button class="btn btn-light w-100" v-if="isReservationStatusStart && !isReservationCancelled" type="button" @click="openOffcanvasHistoricAccount(reservation.id)">
							Ver Cuenta
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="copilot-arrow">
			<div class="arrow btn btn-light" :id="'containerArrow'+reservation.id">
				<div class="service-meta guests text-truncate">
					<template v-if="viewListUsers">
						<i class="fas fa-users me-1"></i>
						<span v-if="reservation.reservationUsers">
								<span v-html="invitationText"></span>
							</span>
						<span v-else>No hay invitados</span>
					</template>
				</div>
				<i :id="'iconArrow'+reservation.id" class="fas fa-chevron-down"></i>
			</div>
		</div>
	</div>
</div>

<!--  Offcanvas historico de pedidos	-->
<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas-historic-account-' + reservation.id" :aria-labelledby="'collapseHistoricOrders' + reservation.id">
	<div class="offcanvas-header mb-0">
		<div class="offcanvas-title h4" id="offcanvasBottomLabel">Cuenta de la mesa</div>
		<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>

	<div class="offcanvas-body p-3">
		<div class="row mb-4 row-gap-2">
			<div class="col-12">
				<button v-if="showTicketButton"  @click="handlePrintAccount()" class="btn btn-light w-100">
					<span class="process-button"><i class="fa-regular fa-file-pdf"></i> Ver Ticket</span></button>
			</div>
			<div class="col-5">
				<button class="btn btn-light btn-outline-primary w-100" :class="{ active: viewMode === 'unified' }" @click.stop="viewMode = 'unified'">Cuenta total</button>
			</div>
			<div class="col-7">
				<button class="btn btn-light btn-outline-primary w-100" :class="{ active: viewMode === 'user' }" @click.stop="viewMode = 'user'">Cuenta por pedido</button>
			</div>
		</div>

		<transition v-if="processedCartSessionOrders.length" name="fade" :duration="300" mode="out-in">
			<div v-if="viewMode === 'user'" key="user">
				<div class="orders-history " style="background-color: var(--bs-body-bg)" v-for="cartSessionOrder in processedCartSessionOrders" :key="cartSessionOrder.cartSessionOrderId">
					<div class="list-name">
						<div>
							<i class="fas fa-utensils"></i>{{ cartSessionOrder.userName }}
						</div>
						<div>
              <span>
                <i class="fas fa-clock"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.createdDateTime) }} &nbsp; <i class="fas fa-check"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.acknowledgedDateTime) }}
              </span>
						</div>
						<div>
              <span v-if="cartSessionOrder.cartSessionStatus === 2">
                <ChefHatIcon fill-color="black"/>
              </span>
						</div>
					</div>
					<div class="extras-products-list">
						<orders-product-card :order="order" v-for="order in  groupedItems(cartSessionOrder)" :key="order.cartSessionOrderId"></orders-product-card>
					</div>
					<div class="vr-horizontal"></div>
				</div>
			</div>
			<div v-else key="unified">
				<div class="extras-products-list">
					<orders-product-card :order="order" v-for="order in historicUnification" :key="order.productSku"></orders-product-card>
				</div>
			</div>
		</transition>
		<div v-else>
			No hay historial de pedidos
		</div>
	</div>

	<div class="offcanvas-footer">
		<DismissButton :is-modal="false" />
	</div>
</div>

<!-- Modal confirm Finish-->
<div class="modal fade" :id="'modalConfirmClose' + reservation.id" tabindex="-1" :aria-labelledby="'modalConfirmCloseLabel' + reservation.id" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title h4" :id="'modalConfirmClose' + reservation.id">
					Cuenta pedida
				</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12">
						<div class="service-meta">
							<span>Mesa:</span>
							<span>
							<table-icon fill-color="black"/>
							{{ reservation.table === null ? '' : reservation.table }}
							<div class="vr mx-2"></div>
							{{ reservation.roomName === null ? '' : reservation.roomName }}
							</span>
						</div>
					</div>
					<div class="col-12" v-if="reservation.name">
						<div class="service-meta">
							<span>Nombre:</span>
							<span>
								 <i class="fas fa-walking"></i> {{ reservation.name }}
							</span>
						</div>
					</div>
					<div class="col-12">
						<div class="service-meta">
							<span>Pax:</span>
							<span>
								<i class="fas fa-users"></i> {{ reservation.pax }}
							</span>
						</div>
					</div>
					<div class="col-12">
						<div class="service-meta">
							Hora: <i class="fas fa-clock"></i> {{ reservation.startDate }}h
						</div>
					</div>
				</div>

				<div class="text-center my-2 fw-bold">Asegúrate de que sea la reserva que ha pedido la cuenta.</div>

				<div v-if="pdfUrl">
					<iframe :src="pdfUrl" width="100%" height="600px"></iframe>
				</div>
			</div>
			<div class="modal-footer">
				<DismissButton content-btn-text="Cancelar"/>

				<button @click="handelFinishReservation(0)" class="btn btn-success">
					<span class="process-button" v-html="finishReservationTextButton"></span></button>
				<button v-if="!isReservationStatusStart" @click="handelFinishReservation(1)" class="btn btn-success">
					<span class="process-button">No show</span></button>
			</div>
		</div>
	</div>
</div>

<!-- Modal qr -->
<div class="modal fade" :id="'modalQr' + reservation.id" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title h4" id="exampleModalLabel">
					<i class="fas fa-qrcode"></i> Invitación
				</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-6">
						<div v-if="reservation.name"><b>Reserva de:</b> {{ reservation.name }}</div>
					</div>
					<div class="col-6">
						<div v-if="reservation.table"><b>Mesa:</b> {{reservation.table}}</div>
					</div>
				</div>

				<div id="'modalQr' + reservation.id" class="content-center">
					<generator-qr :link="getLinkShareMenuQr(reservation)" :id="reservation.id"></generator-qr>
				</div>

				<p class="text-center text-md-start">Pide al cliente escanear este QR para ver el menú.</p>

<!--				<p>Lista de invitados</p>-->

<!--				<div v-if="reservation.reservationUsers.some(diner => diner.type !== 1)" class="reservation-guests">-->
<!--					<i class="fas fa-users me-1"></i>-->
<!--					<span v-for="(diner, index) in reservation.reservationUsers" :key="diner.userId">-->
<!--							{{ diner.type !== 1 ? diner.name + (index !== reservation.reservationUsers.length - 1 ? ',' : '.') : '' }}-->
<!--						</span>-->
<!--				</div>-->
<!--				<div class="text-center text-md-start" v-else>-->
<!--					No hay invitados-->
<!--				</div>-->
			</div>
			<div class="modal-footer justify-content-between">
				<button v-if="isReservationStatusStart && !isReservationCancelled" class="btn btn-light" @click.stop="openComanderMenu" type="button">
					Comandero
				</button>

				<DismissButton/>
			</div>
		</div>
	</div>
</div>

<!-- Offcanvas actualizar pax -->
<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvasUpdatePax-' + reservation.id" aria-labelledby="offcanvasBottomLabel">
	<div class="offcanvas-header mb-0">
		<div class="offcanvas-title h4" id="offcanvasBottomLabel">Actualizar reserva</div>
		<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>

	<div class="offcanvas-body p-3">
		<form>
			<div class="input-group">
				<span class="input-group-text" id="basic-addon1"><i class="fas fa-user-edit"></i></span>
				<input type="number" class="form-control" placeholder="Número de pax" v-model="paxTuUpdate">
			</div>
			<div class="content-center">
				<button class="btn btn-primary" @click="updatePax" :disabled="paxTuUpdate<1">Actualizar</button>
			</div>
		</form>
	</div>

	<div class="offcanvas-footer">
		<DismissButton :is-modal="false" content-btn-text="No cambiar"/>
	</div>
</div>

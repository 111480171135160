<div class="col-12">
	<div class="product-card-client product-card-client-list">
		<div class="product-card-body">
			<img v-if="showPictureMenu" class="product-card-img" :src="productImg" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">{{ product.name }}</div>
				</div>
				<div v-for="(choice, index) in product.chosenOptions" :key="`product_option_${index}`" class="card-extras text-start">
					<!--					<span>{{ getOptionDescription(choice.optionId) }}:</span>-->
					<span v-for="(chosenId, idx) in choice.chosenIds" :key="`chosen_option_${idx}`">
            				{{ getOptionName(choice.optionId, chosenId) }}<span v-if="idx !== choice.chosenIds.length - 1">, </span>
          </span>
				</div>
			</div>
			<div class="product-card-btns">
				<div class="btns">
					<button @click="subtractUnit" class="btn btn-secondary btn-circle">
						<i class="fas" :class="getMinusButtonIcon()"></i>
					</button>
					<div class="product-card-unit">{{ product.cartItemCount }}
						<span v-if="!isCopilotRoute && product.isUnitary === 'SI'">&nbsp;uds.</span></div>
					<button @click="addUnit" class="btn btn-secondary btn-circle">
						<i class="fas fa-plus"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

import store from "@/store";

export class SpeakService {

	constructor() {
		this.weNotificationAlertPath = 'https://wewelcom.io/world/snd/notification-we.mp3';
		this.checkCopilotMessagePath = 'https://wewelcom.io/world/snd/we-connection-check.mp3';
		this.currentAudio = null;
		this.notificationQueue = [];
		this.isPlaying = false;
	}

	playAudio (audioUrl){
		return new Promise((resolve, reject) => {
			if (this.currentAudio) {
				this.currentAudio.pause();
				this.currentAudio.currentTime = 0;
			}

			this.currentAudio = new Audio(audioUrl);
			//this.currentAudio.volume = 0.7;
			this.currentAudio.onended = () => {
				resolve();
				this.currentAudio = null;
			};

			this.currentAudio.onerror = (err) => {
				reject(err);
			};

			this.currentAudio.play();
		})
	}

	async processQueue() {
		if (this.isPlaying || this.notificationQueue.length === 0) {
			return;
		}
		this.isPlaying = true;
		const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

		while (this.notificationQueue.length > 0) {
			const notificationMode = store.getters['copilot/notificationMode'];
			const msDelay = Number(notificationMode) * 60 * 1000;
			const { notification, playAlertSound } = this.notificationQueue.shift();
			try {
				console.log(notificationMode);
				await this.playNotification(notification, playAlertSound);
				await delay(msDelay);
			} catch (error) {
				console.error('Error playing notification sound:', error);
			}
		}

		this.isPlaying = false;
	}

	async enqueueNotification(notification, playAlertSound = true) {
		this.notificationQueue.push({ notification, playAlertSound });
		await this.processQueue();
	}

	async playNotificationAlertSound() {
		await this.playAudio(this.weNotificationAlertPath);
	}

	async playCheckCopilotSound() {
		await this.playAudio(this.checkCopilotMessagePath);
	}

	async playNotificationMessageAudio(notification) {
		let url = store.getters["config/weWelcomUrl"];
		let audioSource = url+"/world/notification/audio/"+notification.id;
		console.log("queremos reproducir "+audioSource);
		await this.playAudio(audioSource);
	}

	async playHavePendingNotifications(audioSource){
		console.log(audioSource)
		await this.playAudio(audioSource);
	}

	async playNotification(notification, playAlertSound = true) {
		if (playAlertSound) {
			await this.playNotificationAlertSound()
		}
		await this.playNotificationMessageAudio(notification);
	}

	async speak(message, playAlertSound = true) {
		let msg = new SpeechSynthesisUtterance(message);
		msg.rate = 1.0;
		msg.pitch = 1;
		msg.lang = 'es-ES';
		if (playAlertSound) {
			await this.playNotificationAlertSound()
		}
		setTimeout(function () {
			window.speechSynthesis.speak(msg);
		}, 1000)
	}
}

export const speakService = new SpeakService();

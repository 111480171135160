<template src="./modalMessage.html"></template>

<script>
import {hideAllModals} from "@/utils";
import { scrollTo  } from 'vue-scrollto';
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalMessage',
	emits: [],
	props: ['message'],
	data() {
		return {};
	},
	beforeMount() {
	},
	mounted() {
		console.log('Mesage al pedir',this.message)
	},
	components: {DismissButton},
	methods: {
		closeModal() {
			hideAllModals()
			scrollTo('html', 300);
		},
		normalizeChoices(choices) {
			if (typeof choices !== 'object' || choices === null) return [];
			return Object.entries(choices).map(([key, value]) => ({
				optionId: key,
				chosenIds: Array.isArray(value) ? value : [value]
			}));
		},
		getOptionDescription(product, optionId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			return productOption ? productOption.description : '';
		},
		getOptionName(product, optionId, chosenId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		}
	}

}
</script>

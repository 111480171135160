<nav class="navbar navbar-expand-lg navbar-custom">
	<div class="container"><a class="navbar-brand logo" href="/" title="WEWELCOM | Tecnología de Bienvenida"> </a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li v-for="(item, index) in menuItems" :key="index" class="nav-item">
					<a class="nav-link" :class="{ 'active': isActive(item.href) }" :href="item.href"><i :class="item.icon"></i> {{ item.text }}</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<template src="./menuCartProduct.html"></template>

<script>
import {normalizeChoices} from "@/utils";
import {mapGetters} from "vuex";

export default {
	name: 'MenuCartProduct',
	emits: ['add-unit', 'subtract-unit'],
	props: ['product', 'isCopilotRoute'],
	data() {
		return {
			showPictureMenu: true,
		};
	},
	computed:{
		...mapGetters('venue', ['resourceId']),
		productImg() {
			return `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
		},
	},
	beforeMount() {
		this.checkImage();
		console.log({product:this.product})

	},
	mounted() {
		console.log('Montando el carrito', this.product);
	},
	methods: {
		handleImageError() {
			this.showPictureMenu = null;
		},
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		getMinusButtonIcon() {
			return this.product.cartItemCount == 1 ? 'fa-trash' : 'fa-minus';
		},
		addUnit() {
			console.log({products:this.product})
			const normalizedChosenOptions = normalizeChoices(this.product.chosenOptions);
			console.log('Adding unit in cart for product:', this.product.sku, 'with options:', normalizedChosenOptions);
            this.$emit('add-unit', { ...this.product, chosenOptions: normalizedChosenOptions });
		},
		subtractUnit() {
			const normalizedChosenOptions = normalizeChoices(this.product.chosenOptions);
			console.log('Subtracting unit in cart for product:', this.product.sku, 'with options:', normalizedChosenOptions);
            this.$emit('subtract-unit', { ...this.product, chosenOptions: normalizedChosenOptions });
		},
		/*getOptionName(productOption, chosenOptionId) {
			const option = productOption.options.find(opt => opt.id === chosenOptionId);
			return option ? option.name : '';
		},*/
		formatChoices() {
			return Object.entries(this.product.chosenOptions).map(([optionId, chosenIds]) => {
				const productOption = this.product.productOptions.find(option => option.id === optionId);
				if (!productOption) {
					return '';
				}
				const chosenOptionNames = chosenIds
					.map(id => productOption.options.find(opt => opt.id === id)?.name)
					.filter(name => name);
				return `${productOption.description}: ${chosenOptionNames.join(', ')}`;
			}).join(' | ');
		},
		getOptionDescription(optionId) {
			const productOption = this.product.productOptions.find(option => option.id === optionId);
			return productOption ? productOption.description : '';
		},
		getOptionName(optionId, chosenId) {
			const productOption = this.product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		}
	}
}
</script>

<template>
	<i class="fa-solid fa-spinner fa-spin-pulse"></i>
</template>

<script>
export default {
	name: 'SpinnerIcon',
	props: {
	}
}
</script>

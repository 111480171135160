<!--<main :data-bs-theme="resourceId" class="content-bg-img-full" :style="{ 'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.667)), url(/world/img/${resourceId}/header/header.jpg ` }">-->
<!--		<section class="bg-transparent">-->
<!--			<div class="container">-->
<!--				<img class="logo-float" :src="`/world/img/${resourceId}/logo/logo.png`" :alt="`logotipo de la empresa ${businessName}`">-->

<!--				<div class="row row-column-gap" id="dynamic-orientation">-->
<!--					<div class="col-6">-->
<!--						<button data-bs-toggle="modal" data-bs-target="#modalInformationScannerQR" class="btn btn-primary btn-welcom btn-xxl w-100">-->
<!--							<span class="row">-->
<!--								<span class="col-12">accede</span>-->
<!--								<span class="col-12"><b>con</b></span>-->
<!--								<span class="col-12">reserva QR</span>-->
<!--							</span>-->
<!--						</button>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<button class="btn btn-light btn-welcom btn-xxl w-100" data-bs-toggle="modal" data-bs-target="#modal-walkin">-->
<!--							<span class="row">-->
<!--								<span class="col-12">accede</span>-->
<!--								<span class="col-12"><b>sin</b></span>-->
<!--								<span class="col-12">reserva</span>-->
<!--							</span>-->
<!--						</button>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</section>-->
<!--</main>-->
<main :data-bs-theme="resourceId" class="main-container-kiosk" :style="{ 'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.667)), url(/world/img/${resourceId}/header/header.jpg ` }">
	<div class="header-kiosk">
		<!-- <div class="title-kiosk">Nombre del Restaurante</div> -->
		<div class="content-img" >
			<img :src="`/world/img/${resourceId}/logo/logo.png`" :alt="`logotipo de la empresa ${businessName}`">
		</div>
	</div>
	<div class="container-btns">
		<button data-bs-toggle="modal" data-bs-target="#modalInformationScannerQR" class="btn btn-kiosk btn-primary">
			<i class="fas fa-qrcode icon"></i>
			Entrar con reserva QR
		</button>
<!--		<div class="separator">O</div>-->
		<button data-bs-toggle="modal" data-bs-target="#modal-walkin" class="btn btn-kiosk btn-light">
			<i class="fas fa-walking icon"></i>
			Entrar sin reserva
		</button>
	</div>
</main>


<div class="modal fade modal-kiosk" id="modalInformationScannerQR" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title h4" id="exampleModalLabel">¡Listo para tu experiencia!</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body text-center justify-content-center d-flex flex-column">
				<p>
					Accede a tu reserva a través del enlace que te hemos enviado por email y prepárate para mostrar tu código QR.
				</p>
			</div>
			<div class="modal-footer">
				<div class="row w-100">
					<div class="col-4">
						<button type="button" class="btn btn-outline-primary w-100" data-bs-dismiss="modal">Cerrar</button>
					</div>
					<div class="col-8">
						<button type="button" @click="cameraService.showModalCameraScan" class="btn btn-primary w-100">todo listo escanear</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<modal-scanner-qr/>

<modal-kiosk-walkin></modal-kiosk-walkin>

<template>
	<!-- Modal -->
	<div class="modal fade modal-kiosk" id="modal-walkin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4 fs-4" id="exampleModalLabel">Consigue tu mesa</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column justify-content-center">
					<div class="row row-column-gap form-row-content">
						<div class="col-12">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-user"></i></span>
								<input id="input-name" v-model="name" type="text" class="form-control" aria-label="" placeholder="Nombre (sin apellidos):">
							</div>
						</div>
						<div class="col-12">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-user-plus"></i></span>
								<input id="input-pax" v-model="pax" type="number" class="form-control" aria-label="" placeholder="Nº de personas:">
							</div>
						</div>
						<div v-if="showErrorMessageWalkIn" class="col-12">
							<div id="walk-in-response-collapse" class="alert alert-danger" role="alert">
								{{errorMessageWalkIn}}
							</div>
						</div>
						<div class="col-12">
							<div class="content-center">
								<button class="btn btn-primary w-100 btn-xxl" @click="handleNewWalkinButton" :disabled="walkInButtonDisabled">{{ createWalkInButtonText }}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-primary w-100" data-bs-dismiss="modal">Cerrar</button>
				</div>
			</div>
		</div>
	</div>

	<!--Modal Walkin correcta Escanea el QR-->
	<div class="modal fade modal-kiosk" id="modalWalkinSuccessFirstStep" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="staticBackdropLabel">¡Tenemos sitio para ti!</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase d-flex flex-column justify-content-center">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-success animate">
										<span class="f-modal-line f-modal-tip animateSuccessTip"></span><span class="f-modal-line f-modal-long animateSuccessLong"></span>
										<div class="f-modal-placeholder"></div>
										<div class="f-modal-fix"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="row row-margin-x">
							<div class="col-12">
								<p>
									<strong>Para continuar, simplemente escanea el código QR que aparecerá a continuación.</strong>
								</p>
								<p>
									Ten tu móvil listo con <b>Google Lens</b> o cualquier lector de QR.
								</p>
							</div>
						</div>

						<div class="row">
							<button class="btn btn-primary"  @click="handleScanQrButton">Escanear QR</button>
						</div>
					</div>
				</div>
<!--				<div class="modal-footer">-->
<!--					<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Salir</button>-->
<!--				</div>-->
			</div>

		</div>
	</div>

	<!--Modal Walkin correcta-->
	<div class="modal fade modal-kiosk" id="modalWalkinSuccess" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="exampleModalLabel">¡Hola {{ walkInName }}!</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase d-flex flex-column justify-content-center">
					<div class="container">
<!--						<div class="row row-margin-x">-->
<!--							<div class="col-12">-->
<!--								<div class="f-modal-alert">-->
<!--									<div class="f-modal-icon f-modal-success animate">-->
<!--										<span class="f-modal-line f-modal-tip animateSuccessTip"></span><span class="f-modal-line f-modal-long animateSuccessLong"></span>-->
<!--										<div class="f-modal-placeholder"></div>-->
<!--										<div class="f-modal-fix"></div>-->
<!--									</div>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
						<div class="row row-margin-x">
							<div class="col-12">
								<generator-qr :link="menuUrl"></generator-qr>
							</div>
						</div>
						<div class="row row-margin-x">
							<div class="col-12">
								Escanee el código QR mostrado para acceder a nuestra carta digital.
							</div>
						</div>
					</div>
				</div>
<!--				<div class="modal-footer">-->
<!--					<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Salir</button>-->
<!--				</div>-->
			</div>
		</div>
	</div>

	<!--Modal Walkin incorrecta-->
	<div class="modal fade modal-kiosk" id="modalWalkinError" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="exampleModalLabel">¡Lo sentimos!</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center text-uppercase d-flex flex-column justify-content-center">
					<div class="container">
						<div class="row row-margin-x">
							<div class="col-12">
								<div class="f-modal-alert">
									<div class="f-modal-icon f-modal-warning scaleWarning">
										<span class="f-modal-body pulseWarningIns"></span>
										<span class="f-modal-dot pulseWarningIns"></span>
									</div>
								</div>
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								No hemos podido asignarle una mesa.
							</div>
						</div>

						<div class="row row-margin-x">
							<div class="col-12">
								Ha sido avisado nuestro encargado e intentaremos solucionar esta incidencia.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton />
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import * as bootstrap from "bootstrap";
import {mapGetters} from "vuex";
import {hideAllModals} from "@/utils";
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import store from "@/store";
import DismissButton from "@/core/DismissButton";

export default {
	name: 'ModalKioskWalkin',
	components: {
		DismissButton,
		GeneratorQr
	},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	computed: {
		...mapGetters('kiosk', ["modalWalkin"]),
		walkInName(){
			return this.modalWalkin.user ?  this.modalWalkin.user.name : "Error"
		},
		menuUrl(){
			return this.modalWalkin.menuUrl ||""
		},
		walkinHour(){
			return this.modalWalkin.reservation ? this.modalWalkin.reservation.startDate : "Error"
		},
		walkinPax(){
			return this.modalWalkin.reservation ? this.modalWalkin.reservation.pax : "Error"
		},
		walkInButtonDisabled() {
			return !this.name || !this.pax;
		},
	},
	data() {
		return {
			name: null,
			pax: null,
			roomId: null,
			showErrorMessageWalkIn: false,
			errorMessageWalkIn: null,
			createWalkInButtonText: 'Consultar',
		}
	},
	mounted() {
		console.log('Montando e modal WALKIN!!!', this.modalWalkin)
	},
	watch: {
		modalWalkin(newValue, oldValue) {
			console.log('Cambiando el valor de la valiable computada', newValue, oldValue)
			if (newValue.showModal === true) {
				this.showModal();
				// hideAllModals();
			}
		},
	},
	methods: {
		async handleNewWalkinButton() {
			this.showErrorMessageWalkIn = false;
			this.errorMessageWalkIn = "";
			this.createWalkInButtonText = 'Consultando disponibilidad...';

			let data = {
				name: this.name,
				pax: this.pax,
				roomId: this.roomId,
				source: 'Kiosk'
			}

			let createWalkInResult = await this.copilotRepository.createKioskoWalkin(data);
			console.log(createWalkInResult);

			if (createWalkInResult.result === 0) {
				store.commit('kiosk/setModalWalkin', {
					modalWalkin: {
						showModal: true,
						// idModal: 'modalWalkinSuccess',
						idModal: 'modalWalkinSuccessFirstStep',
						reservation: createWalkInResult.reservation,
						menuUrl: createWalkInResult.menuUrl,
						user: createWalkInResult.user
					}
				});

				// await store.dispatch('copilot/updateReservations');
			}

			if (createWalkInResult.result === -1) {
				store.commit('kiosk/setModalWalkin', {
					modalWalkin: {
						showModal: true,
						idModal: 'modalWalkinError',
						reservation: null,
						menuUrl: null,
						user: null
					}
				});

				await store.dispatch('copilot/updateReservations');
			}

			this.clearForm();
		},
		showModal() {
			const modalElement = document.getElementById(this.modalWalkin.idModal);
			if (modalElement) {
				let modalInstance = bootstrap.Modal.getInstance(modalElement);
				if (!modalInstance) {
					modalInstance = new bootstrap.Modal(modalElement, {
						backdrop: 'static',
						keyboard: false
					});
				}
				modalInstance.show();

				// setTimeout(() => {
				// 	modalInstance.hide();
				// }, 20000);

			} else {
				console.error('Modal element not found:', this.modalWalkin.idModal);
			}
		},
		clearForm(){
			this.name = null;
			this.pax = null;
			this.createWalkInButtonText = "Consultar";
		},
		handleScanQrButton() {
			const firstModalElement = document.getElementById('modalWalkinSuccessFirstStep');
			const firstModalInstance = bootstrap.Modal.getInstance(firstModalElement);
			firstModalInstance.hide();

			const secondModalElement = document.getElementById('modalWalkinSuccess');
			let secondModalInstance = bootstrap.Modal.getInstance(secondModalElement);
			if (!secondModalInstance) {
				secondModalInstance = new bootstrap.Modal(secondModalElement, {
					backdrop: 'static',
					keyboard: false
				});
			}
			secondModalInstance.show();

			setTimeout(() => {
				secondModalInstance.hide();
				hideAllModals();
			}, 30000);
		}
	}
}
</script>

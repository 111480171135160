<template>
	<div v-if="productsList.length"  >
		<div class="order-data">
			<div class="order-data-header">
				<div v-if="reservation.name">
					<i class="fa-solid fa-qrcode"></i>
					{{ reservation.name }}
				</div>
				<div v-if="reservation.table">
					<TableIcon fill-color="black"></TableIcon>
					{{ reservation.table }}
					<div class="vr mx-1"></div>
					{{ reservation.roomName }}
				</div>

			</div>
		</div>

		<div class="products-list-content">
			<OrdersProductCard class="bg-white" v-for="order in productsList" source="hybridOffcanvas" :order="order" :key="order.productOrderId"></OrdersProductCard>

			<button class="btn btn-primary w-100 my-3" v-html="buttonTextOrder" @click="handleProductOrdersReady(reservation.orderedItems)"></button>
		</div>

		<div class="vr-horizontal"></div>
	</div>
</template>

<script>
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import TableIcon from "@/core/icons/TableIcon";
import store from "@/store";
import {mapGetters} from "vuex";
import {hideAllOffcanvas} from "@/utils";
export default {
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	components: {TableIcon, OrdersProductCard},
	props: {
		reservation: {
			type: Object,
			required: true
		},
	},
	data() {
		return {}
	},
	computed:{
		...mapGetters('copilot', ['selectedProductOrdersToPrepared', 'selectedRole']),
		productsList(){
			const orderedItems = this.reservation.orderedItems;

			if (!orderedItems || !Array.isArray(orderedItems)) {
				return [];
			}

			const filteredAndSortedItems = orderedItems
				.filter(item =>
					item.statusProductOrder > 0 &&
					item.statusProductOrder < 3 &&
					item.statusProductOrder < 100 &&
					item.requirementProductCopilotRoles.some(role => role.name.includes('Barra'))
				)
				.sort((a, b) => {
					if (a.statusProductOrder === 2 && b.statusProductOrder !== 2) {
						return -1;
					}
					if (a.statusProductOrder !== 2 && b.statusProductOrder === 2) {
						return 1;
					}
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});

			return filteredAndSortedItems.length > 0 ? filteredAndSortedItems : [];
		},
		drinkProductOrdersAllOfReservation(){
			return this.reservation.orderedItems
				? this.reservation.orderedItems
					.filter(item => {
						return item.statusProductOrder > 0 &&
						item.statusProductOrder < 3 &&
						item.statusProductOrder < 100 &&
						item.requirementProductCopilotRoles.some(role => role.name.includes('Barra'))
					})
					.map(item => item.productOrderId)
				: []
		},
		selectedProductOrdersToPreparedOfReservation(){
			return this.drinkProductOrdersAllOfReservation.filter(productOrder => this.selectedProductOrdersToPrepared.includes(productOrder));
		},
		buttonTextOrder(){
			const readyProducts = this.selectedProductOrdersToPreparedOfReservation.length;
			const tableInfo = `mesa ${this.reservation.table}`;

			if (readyProducts === 0) {
				return `Todo listo de la ${tableInfo}`;
			}

			return `Servir ${readyProducts} bebida${readyProducts > 1 ? 's' : ''} de la ${tableInfo}`;
		},
	},
	watch: {

	},
	beforeMount() {

	},
	mounted() {
	},
	methods: {
		async handleProductOrdersReady(){
			this.isLoadingMarkToReady = true;
			const data = this.selectedProductOrdersToPreparedOfReservation.length
				? this.selectedProductOrdersToPreparedOfReservation
				: this.drinkProductOrdersAllOfReservation;

			const resultFinishPreparation = await this.productOrderRepository.finishPreparation({productOrderIds: data, roleId: this.selectedRole});
			if(resultFinishPreparation.result === 0){
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: `Has marcado el pedido como listo.`,
				// 		showToast: true,
				// 		idToast: "correctlyToast"
				// 	}
				// })
				store.commit('copilot/clearHotTable');
				store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
				store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: []});
				hideAllOffcanvas()
			}else{
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `Ha habido un error marcando el pedido como listo.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
			}

			this.isLoadingMarkToReady = false;
		},
	}
}
</script>

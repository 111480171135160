import {sendRequestJson} from '../../utils'

export class AllergenRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('Allergen con urlBase: ', this.urlBase)
	}

	async allergensList() {
		const url = this.urlBase + "/world/api/allergens/list"
		try {
			return await sendRequestJson({data:null, url, method:"GET"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los alergenos.");
		}
	}

}

export const allergenRepository = new AllergenRepository();

import { createStore } from 'vuex';

import configModule from "@/store/configModule";
import userModule from "@/store/userModule";
import venueModule from "@/store/venueModule";
import reservationFormModule from "@/store/reservationFormModule";
import copilotModule from "@/store/copilotModule";
import ebitdataModule from "@/store/ebitdataModule";
import kioskModule from "@/store/kioskModule";
import globalModule from "@/store/globalModule";
import menuModule from "@/store/menuModule";

export default createStore({
    modules: {
        user: userModule,
		reservationForm: reservationFormModule,
        venue: venueModule,
		config: configModule,
		ebitdata: ebitdataModule,
		copilot: copilotModule,
		kiosk: kioskModule,
		global: globalModule,
		menu: menuModule
    }
});

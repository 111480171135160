<template>
	<svg class="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
		<polygon points="10,10 20,20"></polygon>
		<polygon points="10,50 20,50"></polygon>
		<polygon points="20,80 30,70"></polygon>
		<polygon points="90,10 80,20"></polygon>
		<polygon points="90,50 80,50"></polygon>
		<polygon points="80,80 70,70"></polygon>
	</svg>
</template>

<script>
export default {
	name: 'starIcon',
	props: {
	}
}
</script>

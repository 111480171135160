<template>
	<div class="copilot-reservation-kitchen"  :class="{'copilot-reservation-kitchen-finish': reservation.finishedDateTime}">
		<div class="reservation-wrapper-kitchen-data" >
<!--			#367FC3-->
			<div class="reservation-kitchen-data" :class="{'reservation-kitchen-finish': !productsOrderAcceptedGroupedByDishClass.length && reservation.orderedItems.length}">
				<div class="service-meta text-truncate"><i class="fa-solid fa-clock"></i> {{ reservation.startDate }} </div>
				<div class="service-meta"><i class="fas fa-user"></i> {{ reservation.pax }} </div>
				<div class="service-meta"><TableIcon :widthProp="100" :fill-color="reservation.finishedDateTime || !productsOrderAcceptedGroupedByDishClass.length && reservation.orderedItems.length ? 'black': 'white'"/> {{ reservation.table }}</div>
				<div class="service-meta" v-if="reservation.roomName"><i class="fas fa-house-user"></i>{{ reservation.roomName }}</div>
			</div>
		</div>

		<div class="reservation-kitchen-content scroll-x py-2">
			<copilot-products-kitchen :key="productsDishClass.dishClass.sortOrder" v-for="productsDishClass in productsOrderAcceptedGroupedByDishClass" :dish-class="productsDishClass.dishClass" :products-order="productsDishClass.products"></copilot-products-kitchen>

			<CopilotProductsCollapseKitchen v-if="productsOrderFinished.length" :reservation-id="reservation.id" :products-order="productsOrderFinished"/>
		</div>
	</div>
</template>

<script>
import TableIcon from "@/core/icons/TableIcon";
import {mapGetters} from "vuex";
import CopilotProductsCollapseKitchen from "@/components/copilotProductsCollapseKitchen/CopilotProductsCollapseKitchen";
import CopilotProductsKitchen from "@/components/copilotProductsKitchen/CopilotProductsKitchen";
export default {
	name: 'CopilotReservationKitchen',
	components: {CopilotProductsKitchen, CopilotProductsCollapseKitchen, TableIcon },
	props:{
		reservation: {
			type: Object,
			required: true
		}
	},
	data() {
		return {

		}
	},
	watch: {

	},
	mounted() {

	},
	computed: {
		...mapGetters('copilot', ['selectedRole']),
		productsOrderAccepted(){
			if (!this.reservation.orderedItems) {
				return [];
			}
			const productsAccepted = this.reservation.orderedItems
				.filter(item => {
					const requirementProductCopilotRoles = item.requirementProductCopilotRoles?.map(role => role.id);
					return item.statusProductOrder > 0 && item.statusProductOrder < 3 && requirementProductCopilotRoles.includes(this.selectedRole);
				})
				.sort((a, b) => {
					if (a.statusProductOrder === 2 && b.statusProductOrder !== 2) {
						return -1;
					}
					if (a.statusProductOrder !== 2 && b.statusProductOrder === 2) {
						return 1;
					}
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});
			return productsAccepted
		},
		productsOrderFinished(){
			if (!this.reservation.orderedItems) {
				return [];
			}
			const productsFinished = this.reservation.orderedItems
				.filter(item => {
					const requirementProductCopilotRoles = item.requirementProductCopilotRoles?.map(role => role.id);
					return item.statusProductOrder >= 3 && requirementProductCopilotRoles.includes(this.selectedRole);
				})
				.sort((a, b) => {
					if (a.statusProductOrder === 3 && b.statusProductOrder !== 3) return -1;
					if (a.statusProductOrder !== 3 && b.statusProductOrder === 3) return 1;
					if (a.statusProductOrder === b.statusProductOrder) {
						return new Date(a.createdDateTime) - new Date(b.createdDateTime);
					}
					return a.statusProductOrder - b.statusProductOrder;
				});
			return productsFinished;
		},
		productsOrderAcceptedGroupedByDishClass() {
			// Encuentra el menor sortOrder en los productos con dishClass no nulo
			const minSortOrder = this.productsOrderAccepted
				.filter(product => product.dishClass !== null) // Filtrar productos con dishClass no nulo
				.map(product => product.dishClass.sortOrder);

			// Calcular el sortOrder ajustado para dishClass null
			const adjustedSortOrder = minSortOrder.length ? Math.min(...minSortOrder) -1 : 0

			// Agrupar productos por dishClass
			const groupedByDishClass = this.productsOrderAccepted.reduce((acc, product) => {
				// Si dishClass es null, usa el sortOrder ajustado
				if (product.dishClass === null) {
					product.dishClass = { id: null, name: null, sortOrder: adjustedSortOrder };
				}

				const key = product.dishClass.id;

				if (!acc[key]) {
					acc[key] = {
						dishClass: product.dishClass,
						products: [],
					};
				}

				acc[key].products.push(product);
				return acc;
			}, {});

			// Convertir el objeto agrupado en un array y ordenarlo
			return Object.values(groupedByDishClass).sort((a, b) => a.dishClass.sortOrder - b.dishClass.sortOrder);
		},


	},
	methods: {

	}
}
</script>


<template>
	<img v-if="fillColor === 'black'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR4nO3VTQqCUBSG4bcgGtSgpgW1tQhaQwRB62kQ7UFpUiANJYLaQb/zE8EJwq6Yea9C+MI3EfRBRYSyf6kCbAGJzLcNVYHAAHk47IVYrwkMgTkQvkGhHhsAjSxADZgAV8Mjk8guwFjPSVU35uUnLQA63yJ94PgDIroD0EtC6sAmAyK6tV4rtpkFRHTTOKQNnC1CJ6BlgkYWEdE9P4uPlg6ghQnaO4B2JujuALpRRCsHdyKm34nkNPwcEK+Qd1VGmh5HVBYsW3KOqAAAAABJRU5ErkJggg==">
	<img v-else src="https://img.icons8.com/?id=7557&format=png&color=ffffffff" alt="">
	<!--	<img v-else src="https://img.icons8.com/?size=40&id=7557&format=png&color=ffffffff" alt="">-->
</template>

<script>
export default {
	name: 'serviceBellIcon',
	props: {
		fillColor: String
	}
}
</script>

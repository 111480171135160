<div class="modal fade" id="privacy-terms-modal" tabindex="-1" aria-labelledby="privacy-terms-modal-label"
	 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="exampleModalLabel">Política
					de Privacidad</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					La Política de Privacidad forma parte de las Condiciones Generales que rigen la presente
					Web
					www.wewelcom.io
					Última versión de enero 2024
				</p>
				<h4>¿Quién es el responsable del tratamiento del tratamiento de los datos?</h4>
				<p>
					Todos los datos facilitados por los Usuarios en los diferentes formularios, solicitudes
					de
					información, inscripción a Newsletter, para ponernos en contacto contigo o bien
					facilitarnos tu
					Curriculum que se encuentran en serán incorporados y tratados, tanto a través de medios
					telemáticos, informáticos y no automatizados, siendo el responsable de dichos
					tratamientos:
				</p>
				<ul>
					<li>Responsable: Verywel Tech S.L.</li>
					<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
					<li>CIF: B44990042</li>
				</ul>
				<p>
					Para cualquier duda o consulta, puedes ponerte en contacto con nosotros a través del
					siguiente E-mail: rgpd@wewelcom.io
				</p>
				<h4>
					¿Quién puede facilitarnos datos?
				</h4>
				<p>
					El acceso de los Usuarios a la página web puede implicar el tratamiento de datos de
					carácter
					personal. Al acceder a nuestra página, manifiestas que eres mayor de edad y que todos
					los
					datos que nos facilites, bien sean obligatorios para prestarte el servicio o información
					o bien
					datos adicionales que completan tu información y solicitud, son veraces y correctos.
					Estos
					datos nos los proporcionas voluntariamente.
					Para determinados tratamientos de datos te solicitaremos que nos otorgues tu
					consentimiento
					y para determinadas finalidades, te solicitaremos tu autorización a través de la
					activación de
					las casillas de validación específicas.
				</p>
				<h4>
					¿Qué datos recopilamos?
				</h4>
				<p>
					Al enviar una consulta de información, ponerte en contacto con nosotros a través de
					correo
					electrónico o por teléfono, que respondamos a tus solicitudes, nos envíes tu candidatura
					espontánea de empleo, contrates nuestros servicios, podemos solicitarle que nos
					facilites una
					serie de datos e información personal tal como tu nombre, apellidos, dirección, email,
					teléfono
					contacto, entre otros.
				</p>
				<h4>
					¿Cómo se usa la información y datos que nos facilitas?
				</h4>
				<p>
					Verywel Tech S.L. usará la información que nos proporcionas para las siguientes
					finalidades:
				</p>
				<h5>Tratamiento de datos de usuario para responder a tus solicitudes de información.</h5>
				<p>
					Trataremos tus datos para responder a tus solicitudes de información y ponernos en
					contacto
					contigo para remitirte información sobre nuestros productos y servicios, hacer un
					seguimiento
					de las mismas.

					La base que legitima este tratamiento es tu consentimiento.
					Tus datos serán conservados mientras se mantenga vigente el consentimiento; una vez
					producida tu baja voluntaria, serán bloqueados por un plazo de 2 años, siendo
					posteriormente
					eliminados.
				</p>
				<h5>
					Tratamiento de datos para contratación de servicios a través de la web www.wewelcom.io
				</h5>
				<p>
					Trataremos sus datos, con tu consentimiento expreso, para la tramitación y contratación
					de

					productos y servicios de Verywel Tech S.L., así como la prestación de nuestros
					servicios.
					La base que legitima el tratamiento es tu consentimiento; tus datos serán conservados,
					una
					vez finalizados los servicios contratados, por un plazo mínimo de 2 años;
					posteriormente,
					previo proceso de disociación, la información sobre servicios prestados será tratada a
					efectos
					estadísticos.
				</p>
				<h5>
					Tratamiento de datos para comunicaciones de productos y servicios de pertenecientes a la
					web www.wewelcom.io.
				</h5>
				<p>
					Trataremos sus datos, con tu consentimiento expreso, para remitirte comunicaciones de
					productos y servicios de pertenecientes a la web www.wewelcom.io.
					La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrás
					darte
					de baja siguiendo las instrucciones que aparecen al final de todas las comunicaciones
					electrónicas o solicitándolo por escrito, como se describe en el apartado Derechos.
				</p>
				<h5>
					Tratamiento de datos CV de candidatos.
				</h5>
				<p>
					Trataremos tus datos, con tu consentimiento expreso, para finalidades de participación
					en
					procesos de selección de personal realizados por Verywel Tech S.L. El consentimiento es
					otorgado directamente por el candidato al remitir sus datos y Curriculum Vitae a la
					dirección:
					rgpd@wewelcom.io
					La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrá
					revocar este consentimiento.
				</p>
				<h5>
					Contactos de la o del correo electrónico.
				</h5>
				<p>
					Trataremos los datos identificativos y de contacto que el usuario proporcione al ponerse
					en
					contacto con Verywel Tech S.L. mediante correo electrónico o telefónicamente, así como
					otros
					datos que el usuario incluya. Los datos serán tratados con las siguientes finalidad:
				</p>
				<ul>
					<li>Contestar consultas, solicitudes o peticiones.</li>
					<li>Darle de alta y registrarte como Usuario</li>
					<li>Gestionar el servicio solicitado, contestar solicitudes, o tramitar peticiones.</li>
					<li>Información por medios electrónicos, que versen sobre una solicitud.</li>
					<li>Realizar análisis y mejoras en la Web, sobre los servicios de Verywel Tech S.L.</li>
					<li>Información comercial por medios electrónicos, siempre que exista autorización
						expresa.
					</li>
				</ul>
				<p>
					Nuestro formulario de contacto cuenta con el símbolo * en los campos obligatorios. Si no
					se
					facilitan esos campos, no nos será posible contestar a la petición y por tanto, no se
					permitirá
					el envío del formulario.
					La base que legitima el tratamiento es tu consentimiento, que podrá ser revocado en
					cualquier
					momento.
				</p>
				<h5>
					Contactos de Redes Sociales.
				</h5>
				<p>
					Los datos personales disponibles en los perfiles de RRSS, así como aquellos que el
					usuario
					proporcione al Responsable al contactar con el mediante esta vía, serán tratados con la
					finalidad de:
				</p>
				<ul>
					<li>Contestar a consultas, solicitudes o peticiones.</li>
					<li>Gestionar el servicio solicitado, contestar a la solicitud, o tramitar una
						petición.
					</li>
					<li>Establecer una relación usuario-responsable, y crear una comunidad de seguidores.
					</li>
				</ul>
				<p>
					En este caso el tratamiento, se basa en la aceptación de una relación contractual en el
					entorno
					de la red social que corresponda y conforme a sus políticas de Privacidad, por lo que es
					recomendable que el usuario consulte las mismas.
					Verywel Tech S.L. únicamente podrá consultar o dar de baja los datos de forma
					restringida al
					tener un perfil específico. Estos serán tratados tanto tiempo como el usuario lo permita
					mediante las distintas interacciones que cada RRSS permita. Cualquier rectificación de
					los
					datos o restricción de información o de publicaciones, el usuario debe realizarla a
					través de la
					configuración de su perfil en la propia red social.
				</p>
				<h5>
					Tratamiento de datos de clientes y proveedores.
				</h5>
				<p>
					Trataremos los datos identificativos, de contacto y de facturación, así como todos los
					datos del
					cliente/proveedor estrictamente necesarios para el correcto desarrollo de la relación
					contractual, con las siguientes finalidades:
				</p>
				<ul>
					<li>Elaboración del presupuesto y seguimiento del mismo mediante comunicaciones entre
						las partes.
					</li>
					<li>Si realiza una orden de nuestros productos y servicios, o si proporciona servicios a
						Verywel Tech S.L., a nuestros empleados, clientes o socios como proveedor,
						procesaremos la información personal con el objetivo de completar las transacciones
						pertinentes, así como de administrarlas (por ejemplo, mediante el envío de facturas
						y
						la formalización de pagos o la entrega del producto o prestación del servicio), y
						gestionar correctamente el producto o servicio solicitado.
					</li>
					<li>Información por medios electrónicos, para el correcto desarrollo de la relación
						contractual o que puedan guardar relación con el producto/servicio objeto de la
						misma, en base al interés legítimo del responsable.
					</li>
					<li>Facturación y declaración de los impuestos oportunos. Los datos bancarios serán
						utilizados para la ejecución del servicio solicitado, como pueda ser el pago/cobro
						de
						un producto/servicio.
					</li>
					<li>Gestiones de control y recobro.</li>
				</ul>
				<p>
					La base legal es la ejecución o desarrollo de una relación contractual, o en su defecto
					el
					consentimiento al contactar con nosotros u ofrecernos productos. En caso de no
					proporcionar

					alguno de los datos necesarios para el correcto desarrollo del contrato, no podremos
					proceder
					a la prestación del servicio solicitado.
				</p>
				<h4>
					¿Incluimos datos personales de terceras personas?
				</h4>
				<p>
					No, como norma general sólo tratamos los datos que nos facilitan los titulares. Si se
					aportasen
					datos de terceros, se deberá con carácter previo, informar y solicitar su consentimiento
					a
					dichas personas, o de lo contrario Verywel Tech S.L. quedará exento de cualquier
					responsabilidad por el incumplimiento de este requisito.
				</p>
				<h4>
					¿Y datos de menores?
				</h4>
				<p>
					No tratamos datos de menores de 14 años. Por tanto, absténgase de facilitarlos si no
					tiene esa
					edad o, en su caso, de facilitar datos de terceros que no tengan la citada edad. Verywel
					Tech
					S.L. se exime de cualquier responsabilidad por el incumplimiento de esta previsión.
				</p>
				<h4>
					¿Cómo y dónde se comparte la información?
				</h4>
				<p>
					Salvo que el usuario autorice a la recepción de comunicaciones de productos y servicios
					por las
					Entidades que conforman el Grupo para que puedan remitirte información de los productos
					y
					servicios, Verywel Tech S.L. te informa, de manera expresa, que únicamente comunicará
					los
					datos a aquellos organismos y entes de la Administración Pública con competencia en la
					materia de acuerdo con la normativa legal vigente.
					Los datos personales podrán ser comunicados a encargados del tratamiento necesarios para
					la
					prestación del servicio, quienes tendrán firmado un contrato de prestación de servicios
					que les
					obliga a mantener el mismo nivel de privacidad que el del responsable.
				</p>
				<h4>
					Seguridad de tus datos
				</h4>
				<p>
					Verywel Tech S.L. da gran importancia a la seguridad de toda la información de
					identificación
					personal. Desafortunadamente, la transmisión de información a través de Internet no es
					completamente segura; por ello, hacemos esfuerzos y destinamos recursos a mejorar cada
					día
					nuestra página y que las funcionalidades que ponemos a tu disposición sean más seguras.
					Una vez que hayamos recibido tu información, sobre dichos datos hemos implementado
					medidas de seguridad para garantizar el acceso, confidencialidad, integridad y evitar
					cualquier
					pérdida, mal uso y/o alteración de los datos que están bajo nuestro control. Por
					ejemplo,
					nuestras políticas de seguridad y privacidad son revisadas periódicamente y mejoradas
					según
					sea necesario y sólo el personal autorizado tiene acceso a la información del usuario.
				</p>
				<h4>
					Datos de navegación
				</h4>
				<p>
					Verywel Tech S.L. solamente registrará datos anónimos con respecto a la navegación de
					los
					usuarios, con el fin de obtener datos estadísticos sobre fecha y hora de acceso al Web
					Site,
					navegación, visitas, uso de funcionalidades, descargas, visualizaciones. Además, con el
					fin de
					conocer la eficacia de los banners y links que apuntan a nuestro sitio, identificaremos
					también
					el link de procedencia de la visita. Toda la información obtenida es completamente
					anónima, y
					en ningún caso podría ser utilizada para identificar a un usuario concreto ni es posible
					asociarla
					con una persona en particular, simplemente nos servirá para mejorar el servicio que
					ofrecemos a nuestros usuarios.

					Te informamos, asimismo, que durante la navegación y visita a las páginas de Verywel
					Tech S.L.
					podemos utilizar balizas de seguimiento y cookies, que nos permiten adecuar nuestros
					servicios y productos a las preferencias e intereses de los usuarios y visitantes. En
					caso de
					visitantes de la página, los datos que nos proporcionan estas balizas de seguimiento no
					pueden
					ser asociados a una persona concreta ni nos permiten obtener una identificación directa
					del
					usuario.
				</p>
				<h4>
					Actualización en la contratación de servicio prestado
				</h4>
				<p>
					Para aquellas situaciones donde Verywel Tech S.L. deba prestar al Cliente unos servicios
					de
					naturaleza y características distintas a los propios de Verywel Tech S.L. es posible que
					requieran la adaptación de la relación entre ambas partes a efectos de privacidad. En
					este
					sentido, Verywel Tech S.L. y el Cliente acordarán la preparación de un acuerdo en que
					queden
					reguladas las correspondientes obligaciones, responsabilidades y derechos aplicables a
					cada
					una de las partes en materia de Protección de Datos de Carácter Personal.
				</p>
				<h4>
					Acuerdo de confidencialidad
				</h4>
				<p>
					Verywel Tech S.L. y el Cliente se comprometen a mantener reservada y confidencial la
					existencia y el contenido de toda la documentación e información que se facilite,
					transmita o
					divulgue con independencia del método, forma o soporte utilizado (en adelante, &quot;Información
					Confidencial&quot;), comprometiéndose a no realizar ninguna divulgación a terceros o
					comunicación pública sin la previa autorización por escrito de la otra parte.
					De forma enunciativa pero no limitativa, se entenderá como Información Confidencial la
					información referida a datos de Clientes, su existencia, su estructura, planes de
					promoción y
					venta, códigos fuente y objeto de programas informáticos, sistemas, técnicas, Propiedad
					Industrial e Intelectual, datos técnicos y no técnicos, dibujos, bocetos, datos
					financieros,
					planes relativos a nuevos productos, datos relativos a clientes o potenciales Clientes
					así como
					cualquier otra información utilizada en el ámbito empresarial de Verywel Tech S.L.
					La obligación de confidencialidad subsistirá incluso después de la resolución, por
					cualquier
					causa, de la relación contractual entre las partes.
					El incumplimiento de la obligación de confidencialidad asumida en estos Términos y
					Condiciones o la devolución de la Información Confidencial establecida anteriormente
					dará
					derecho a cualquiera de las partes a reclamar los daños y perjuicios que dicho
					incumplimiento
					hubiera generado según los límites establecidos en la normativa vigente.
					La obligación de confidencialidad no será de aplicación en los casos en los que:
					Después de haber sido facilitada como Información Confidencial, deviniese accesible
					públicamente, sin que en dicha circunstancia hubiese intervenido incumplimiento alguno
					de la
					presente cláusula; o
					Se encontrare legalmente en posesión de la parte receptora en el momento en que hubiese
					sido facilitada por la parte emisora, o que hubiese sido obtenida por aquella de manera
					independiente y anterior a haberle sido facilitada por esta; o
					Que la parte receptora demuestre haberla obtenido legalmente de modo no restringido de
					cualquier tercero que no estuviese sujeto por obligaciones de confidencialidad similares
					con la
					parte emisora; o

					Que deba ser obligatoriamente facilitada en virtud de disposición legal o por resolución
					válidamente emitida por cualquier autoridad administrativa competente, tribunal u órgano
					jurisdiccional, legalmente facultado para obligar a tal disponibilidad, siempre y cuando
					la parte
					receptora así requerida notifique inmediatamente a la parte emisora la recepción de tal
					requerimiento, a fin de que la parte emisora pueda evaluar si existe posibilidad de
					eludir el
					mismo o pueda prestar cualquier apoyo razonablemente solicitado por la parte receptora.
				</p>
				<h4>
					Tus Derechos y el acceso a la información
				</h4>
				<p>
					De acuerdo con la normativa vigente, Reglamento UE 679/2016, de 27 de abril, General de
					Protección de Datos y a con la Ley Orgánica 3/2018, de Protección de Datos Personales y
					Garantía de los Derechos Digitales tienes la posibilidad de ejercitar los siguientes
					derechos
					personalísimos, acreditando de manera fehaciente tu identidad, bien a través de medios
					electrónicos (en el caso de Newsletter, comunicaciones y correos electrónicos) o bien a
					través
					de comunicación por escrito dirigida a nuestro Responsable de Privacidad. Tus derechos
					son
					los siguientes:
				</p>
				<ul>
					<li>Derecho a revocar tu consentimiento y solicitarnos que no procesemos tus datos.</li>
					<li>Derecho de acceso a la información que disponemos referente a tu persona, con
						especificación de las finalidades del tratamiento que realizamos y las
						comunicaciones
						que hemos realizado a terceras administraciones y empresas, bien por encontrarse
						autorizadas por ley o por ser necesarias para la prestación del servicio que nos has
						solicitado, bien por haber otorgado tu consentimiento.
					</li>
					<li>Derecho de rectificación de tus datos, por el que actualizaremos y pondremos al día
						la
						información y datos que disponemos según la información que nos facilites.
					</li>
					<li>Derecho a la supresión de tus datos, una cumplidos los plazos legales de
						conservación
						fijados por la normativa de aplicación.
					</li>
					<li>Derecho a oponerte a que realicemos cualquier actividad de tratamiento de tus datos,
						a través de la revocación de tu autorización/consentimiento.
					</li>
					<li>Derecho a limitar el tratamiento de tus datos, como en caso de que interpongas una
						reclamación o quieras que conservemos tus datos por un plazo superior, evitando su
						supresión.
					</li>
					<li>Derecho a la portabilidad de tus datos, por el que te facilitaremos en un formato de
						archivo de lectura común aquellos datos que nos has facilitado en el momento de tu
						registro como usuario.
					</li>
				</ul>
				<p>
					Además, te informamos que siempre podrás acudir a la Autoridad de Control Española de
					Protección de Datos (Agencia Española de Protección de Datos, www.agpd.es) para
					solicitar su
					tutela o interponer una reclamación en relación al tratamiento de datos personales.
				</p>
				<h4>
					Responsable de Privacidad
				</h4>
				<p>
					En cualquier momento nos podrás solicitar, de manera totalmente gratuita, bien por carta
					o a
					través de correo electrónico, el ejercicio de sus derechos. Tus solicitudes deberás
					enviarlas a
					nuestro Responsable de Privacidad en la siguiente dirección:
				</p>
				<ul>
					<li>Responsable: Verywel Tech S.L.</li>
					<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
					<li>CIF: B44990042</li>
					<li>Email: rgpd@wewelcom.io</li>
				</ul>
				<h4>
					Cambios en la Política de Privacidad
				</h4>
				<p>

					De vez en cuando podremos modificar o adaptar nuestra Política de Privacidad y
					Tratamiento
					de datos de Carácter Personal. Las modificaciones serán comunicadas a nuestros usuarios.
				</p>

			</div>
			<div class="modal-footer">
				<DismissButton/>
			</div>
		</div>
	</div>
</div>

import {sendRequestJson} from '@/utils'

export class NotificationRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('NotificationRepository con urlBase: ', this.urlBase)
	}

	async requestTicket(data) {
		const url = this.urlBase + "/world/notification/new"
		try {
			return await sendRequestJson({data, url, method :"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal solicitando la cuenta.");
		}
	}

}

export const notificationRepository = new NotificationRepository();

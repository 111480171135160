<template>
	<div v-if="props.storeModule !== 'copilot'" class="input-group">
		<span class="input-group-text"><i class="fas fa-calendar"></i></span>
		<input :id="`input-date-${props.storeModule}`" type="text" class="form-control" style="border-top-right-radius: 50rem;border-bottom-right-radius: 50rem" placeholder="Día:" v-model="selectedDate">
	</div>
	<input v-else :id="`input-date-${props.storeModule}`" type="text" class="form-control rounded-end-0 text-center" placeholder="Día:" v-model="selectedDate">
</template>

<script setup>
import Picker from '../amsul/pickadate/picker'
import $ from 'jquery';
import datePicker from '../amsul/pickadate/picker.date';
import {onMounted, computed, inject,} from 'vue';
import {useStore} from 'vuex';
import {settingsDatePicker} from "@/utils";

// eslint-disable-next-line no-undef
const props = defineProps({
	startDate: {
		type: String,
		default: null
	},
	storeModule: {
		type: String
	}
});

const dateFormatterService = inject('dateFormatterService');
let reservationDate = null;
let datePickerObject = null;

const store = useStore();
const selectedDate = computed(() => {
	const moduleState = store.state[props.storeModule];
	return moduleState ? dateFormatterService.formattedDate(moduleState.selectedDate) : null;
});
const disableDay = computed(() => store.state.venue.disableDay);
const onSetDatePicker = () => {
	store.commit(props.storeModule + '/setSelectedDate', {selectedDate: datePickerObject.get('select', 'yyyy-mm-dd') + ""});
}


onMounted(() => {
	let settingsComponentDatePicker = settingsDatePicker;
	if (props.startDate) {
		store.commit(props.storeModule + '/setSelectedDate', {selectedDate: props.startDate.slice(0, 10)});
	}

	const picker = Picker($)

	datePicker(picker, $);

	if(props.storeModule === 'copilot'){
		settingsComponentDatePicker = {...settingsComponentDatePicker, min:undefined}
	}

	const inputId = `input-date-${props.storeModule}`;
	reservationDate = $(`#${inputId}`).pickadate({...settingsComponentDatePicker, onSet: onSetDatePicker, disable: [...disableDay.value]});

	datePickerObject = reservationDate.pickadate('picker');
});

</script>

<style>
@import '../amsul/pickadate/pickadate-wewelcom/default.css';
@import '../amsul/pickadate/pickadate-wewelcom/default.date.css';
</style>

<div class="modal fade" id="legal-terms-modal" tabindex="-1" aria-labelledby="legal-terms-modal-label"
	 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="exampleModalLabel">Aviso Legal</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					Te agradecemos que hayas decidido visitarnos. Queremos que tu experiencia en nuestra web y app sea lo mejor posible.
				</p>
				<p>
					Para acceder a nuestros servicios, declaras que tienes la capacidad legal suficiente para usar nuestros servicios, de acuerdo con tu ley nacional.
				</p>
				<p>
					El acceso y la navegación en el sitio web, o el uso de los servicios del mismo, implican la aceptación expresa e íntegra de todas y cada una de las presentes Condiciones Generales, entre las que se encuentran incluidas tanto las Condiciones Particulares fijadas para ciertas promociones, así como las Políticas de Privacidad y de Cookies, relacionadas con la normativa vigente en la materia de Protección de Datos Personales. Por favor, te recomendamos que las leas con atención para saber qué tipo de datos se recogen a través de nuestra web, con qué finalidades y qué derechos relacionados con ello te conciernen.
				</p>
				<h4>1. Información Legal</h4>
				<p>
					En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, los datos identificativos del titular del portal web son:
				</p>
				<ul>
					<li>Responsable: Verywel Tech S.L.</li>
					<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
					<li>CIF: B44990042</li>
				</ul>
				<p>
					Para cualquier duda o consulta, puedes ponerte en contacto con nosotros a través del
					siguiente E-mail: rgpd@wewelcom.io
				</p>
				<h4>
					2. Condiciones Generales de Uso.
				</h4>
				<p>
					Las siguientes Condiciones Generales regulan el uso y acceso al presente portal web y app, así como a todas aquellas aplicaciones, contenido y herramientas ofrecidas por el responsable del tratamiento. La finalidad es establecer pautas de buen uso y comportamiento dentro de las mismas. Verywel Tech S.L, pone a disposición de los usuarios información sobre el uso, los servicios y los contenidos que se alojan en la web y en sus distintos productos/servicios.
				</p>
				<p>
					Los datos e informaciones sobre productos, servicios, precios y características u otros datos relevantes ofrecidos a través de la página web pueden ser realizados, proporcionados y actualizados por proveedores y terceras empresas. En tales casos, Verywel Tech S.L. no será responsable de dichos datos ni asume ninguna obligación sobre los mismos.
				</p>
				<p>
					El Usuario se compromete a hacer un uso adecuado de los contenidos, servicios, aplicaciones y herramientas que están accesibles, con sujeción a la Ley y a las presentes Condiciones Generales de Uso y, en su caso a las Condiciones Particulares que puedan establecerse para el acceso a determinados servicios y aplicaciones, respetando en todo momento a los demás usuarios de la misma.
				</p>
				<p>
					En caso de incumplimiento total y/o parcial por el Usuario de las presentes condiciones Generales de Uso, Verywel Tech S.L. se reserva el derecho de denegar el acceso a la web, sin necesidad de previo aviso al Usuario.
				</p>
				<h4>
					3. Obligaciones Generales del Usuario
				</h4>
				<p>
					El Usuario, al aceptar las presentes condiciones Generales de Uso, se obliga expresamente a:
				</p>
				<p>
					No realizar ninguna acción destinada perjudicar, bloquear, dañar, inutilizar, sobrecargar, de forma temporal o definitiva, las funcionalidades, herramientas, contenidos y/o la infraestructura de la página web, de manera que impida su normal utilización.
				</p>
				<p>
					Custodiar y mantener la confidencialidad de las claves de acceso asociadas a su nombre de Usuario, siendo responsable de la utilización de dichas claves de acceso personales e intransferibles por parte de terceros.
				</p>
				<p>
					No introducir ni realizar contenidos injuriosos o calumniosos, tanto de otros Usuarios como de terceras empresas ajenas a Verywel Tech S.L.
				</p>
				<p>
					No utilizar cualquiera de los materiales e informaciones contenidos en este sitio web con fines ilícitos y/o expresamente prohibidos en las presentes Condiciones Generales de Uso, así como a las condiciones particulares que, en su caso, se establezcan para determinadas aplicaciones y/o utilidades y que resulten contrarios a los derechos e intereses de Verywel Tech S.L., sus usuarios y/o terceros.
				</p>
				<p>
					No ofertar ni distribuir productos y servicios, ni realizar publicidad o comunicaciones comerciales a otros Usuarios y visitantes de Verywel Tech S.L.
				</p>
				<p>
					El Usuario responderá de los todos los daños y perjuicios de toda naturaleza que Verywel Tech S.L. o cualquier tercero pueda sufrir como consecuencia del incumplimiento de cualesquiera de las obligaciones a las que queda sometido por virtud de estas Condiciones Generales de Uso o de la ley en relación con el acceso y/o utilización de la página.
				</p>
				<h4>
					4. Propiedad Intelectual e Industrial
				</h4>
				<p>
					El sitio web, las páginas que comprende y la información o elementos contenidos en las mismas (incluyendo textos, documentos, fotografías, dibujos, representaciones gráficas, logotipos, marcas, nombres comerciales u otros signos distintivos), se encuentran protegidos por derechos de propiedad intelectual y/o industrial, de los que Verywel Tech S.L. es titular u ostenta autorización para su utilización y comunicación pública de los legítimos titulares de las mismas.
				</p>
				<p>
					El Usuario se obliga a usar los contenidos de forma diligente y correcta, de acuerdo a la ley, la moral y el orden público. Verywel Tech S.L. autoriza al Usuario para visualizar la información que se contiene en este sitio web, así como para efectuar reproducciones privadas (simple actividad de descarga y almacenamiento en sus sistemas informáticos), siempre y cuando los elementos sean destinados únicamente al uso personal. En ningún caso, ello significará una autorización o licencia sobre los derechos de propiedad de Verywel Tech S.L. o de los legítimos titulares de las mismas.
				</p>
				<p>
					El Usuario no está autorizado para proceder a la distribución, modificación, cesión o comunicación pública de la información contenida en esta web en cualquier forma y cualquiera que sea su finalidad.
				</p>
				<h4>5. Enlaces.</h4>
				<p>
					Las conexiones y enlaces a sitios o páginas web de terceros se han establecido únicamente como una utilidad para el Usuario. Verywel Tech S.L. no es, en ningún caso, responsable de las mismas ni de su contenido.
				</p>
				<p>
					Verywel Tech S.L no asume ninguna responsabilidad derivada de la existencia de enlaces entre los contenidos de este sitio y contenidos situados fuera del mismo o de cualquier otra mención de contenidos externos a este sitio. Tales enlaces o menciones tienen una finalidad exclusivamente informativa y, en ningún caso, implican el apoyo, aprobación, comercialización o relación alguna entre Verywel Tech S.L y las personas o entidades autoras y/o gestoras de tales contenidos o titulares de los sitios donde se encuentren.
				</p>
				<p>
					Para realizar enlaces con la página web será necesaria la autorización expresa y por escrito de los titulares del portal.
				</p>
				<h4>
					6. Responsabilidad.
				</h4>
				<p>
					Verywel Tech S.L no garantiza el acceso continuado, ni la correcta visualización, descarga o utilidad de los elementos e información contenidos en las páginas del portal que pueden verse impedidos, dificultados o interrumpidos por factores o circunstancias fuera de su control o ajenas a su voluntad, ni de aquellos que sean producidos por la existencia de virus informáticos en Internet.
				</p>
				<p>
					Verywel Tech S.L no asume responsabilidad alguna por daños, perjuicios, pérdidas, reclamaciones o gastos, producidos por:
				</p>
				<p>
					Interferencias, interrupciones, fallos, omisiones, retrasos, bloqueos o desconexiones, motivadas por errores en las líneas y redes de telecomunicaciones o por cualquier otra causa ajena al control de Verywel Tech S.L.
				</p>
				<p>
					Intromisiones ilegítimas mediante el uso de programas malignos de cualquier tipo y a través de cualquier medio de comunicación, tales como virus informáticos o cualesquiera otros;
				</p>
				<p>
					Uso indebido o inadecuado de la página web de Verywel Tech S.L
				</p>
				<p>
					Errores de seguridad o navegación producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas.
				</p>
				<p>
					Verywel Tech S.L no es responsable ni en ningún caso responderá frente a los usuarios y terceros sobre actos de cualquier tercero ajeno a Verywel Tech S.L que conlleve o pueda suponer la realización de actos de competencia desleal y publicidad ilícita o la infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero por razón de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.
				</p>
				<h4>
					7. Protección de datos de carácter personal.
				</h4>
				<p>
					Toda la política de tratamiento de datos personales la encontrarás en la Política de Privacidad, que forma parte integrante de estas Condiciones Generales pero que, por simplicidad, hemos puesto en un documento aparte: <a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="f-no-utm terms link" href="#termsPrivacy">Política de Privacidad.</a>
				</p>
				<h4>
					8. Legislación.
				</h4>
				<p>
					El presente aviso legal se encuentra redactado en castellano y sometido a la legislación española vigente. En caso de disputa o controversia relacionada con la aplicación o interpretación de las cláusulas contenidas en el aviso legal, las Partes se someterán a los Juzgados y Tribunales que resulten territorialmente competentes según la legislación vigente.
				</p>
				<p>
					Si alguna de las cláusulas descritas en el aviso legal fuese declarada nula o sin efecto, continuarán siendo de aplicación las restantes, sin que deban quedar afectadas por la declaración de nulidad.
				</p>
			</div>
			<div class="modal-footer">
				<DismissButton/>
			</div>
		</div>
	</div>
</div>

<div :class="{'modal-kiosk':isInKiosksRoute}" class="modal fade" id="modalCameraScan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog w-100 modal-dialog-centered modal-fullscreen">
		<div class="modal-content">
			<div class="modal-body overflow-hidden position-relative d-flex flex-column">

				<img v-if="isInKiosksRoute" class="logo-float" :src="`/world/img/${resourceId}/logo/logo.png`" :alt="`logotipo de la empresa ${businessName}`">

				<video id="preview" class="modal-scanner-qr"></video>
				<div class="scanner-decoration">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>

				<div class="row row-margin-x">
					<div class="col-12" v-if="isInKiosksRoute">
						<div class="h5">Escanea el código QR de su reserva, por favor.</div>
					</div>
					<div v-else class="col-12">
						<div class="h5">Escanea el código QR del cliente, por favor.</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
<!--				<button type="button" class="btn btn-outline-primary" @click="cameraService.stopCamera"-->
<!--						data-bs-dismiss="modal">-->
<!--					Cerrar-->
<!--				</button>-->
				<DismissButton :onClick="stopCameraScanner"/>

				<button v-if="!isInKiosksRoute && this.reservationId" @click="openQRInvitation" class="btn btn-outline-primary"><i class="fas fa-eye"></i> QR</button>
			</div>
		</div>

	</div>
</div>

<template src="../../views/menu/menu.html"></template>

<script>
import * as bootstrap from 'bootstrap';
import MenuProductCard from "@/components/menuProductCard/MenuProductCard";
import MenuCartProduct from "@/components/menuCartProduct/MenuCartProduct";
import NavBottom from "@/components/navBottom/NavBottom";
import ModalMessage from "@/components/mods/modalMessage/ModalMessage";
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import NavBarTop from "@/components/navBarTop/NavBarTop";
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import {hideAllModals} from "@/utils";

import HeaderComponent from "@/core/HeaderComponent";
import {dateFormatterService} from "@/service/dateFormatterService"
import NavbarScrollCategories from "@/components/navbarScrollCategories/NavbarScrollCategories";
import MenuSearcher from "@/components/menuSearcher/MenuSearcher";
import DismissButton from "@/core/DismissButton";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import BagOrdersIcon from "@/core/icons/BagOrdersIcon";
import store from "@/store";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import menuOffcanvasAssessment from "@/components/menuOffcanvasAssessment/menuOffcanvasAssessment";
import MenuModalRequestAccount from "@/components/modalRequestAccount/ModalRequestAccount";

export default {
	name: 'CommanderWelcom',
	data() {
		return {
			menus: [],
			activeMenu: true,
			cartClientMessage: '',
			messageCart: {
				clientName: '',
				products: []
			},
			reservationDate: '',
			reservationTime: '',
			isLoad: true,
			isLoading: false,
			idQr: '',
			menuButtonContentAccount: 'Solicitar cuenta',
			menuButtonContentOrder: 'Hacer pedido',
			isRequestAccount: false,
			isModalOpen: null,
			isTypeFood: true,
			menuCategories: [],
			isSpinnerIconLoading:false,
			viewMode: 'comander',
		};
	},
	inject: {
		notificationRepository: 'notificationRepository',
		cartSessionRepository: 'cartSessionRepository',
		userRepository: 'userRepository',
		venueRepository: 'venueRepository',
		menuRepository: 'menuRepository',
		cookieManager: 'cookieManager',
		reservationRepository: 'reservationRepository',
	},
	computed: {
		...mapGetters('config', ['websocketUrl', 'websocketPort','weWelcomUrl']),
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'weOrderClientComments']),
		...mapGetters('copilot', ['commanderReservation']),
		...mapGetters('menu', ['menusVenue', 'productsCart']),
		reservationInfo(){
			return this.commanderReservation
		},
		totalProducts() {
			return this.productsCart.reduce((acumulator, product) => {
				return acumulator + product.cartItemCount;
			}, 0);
		},
		linkShareMenuWhatsapp() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/whatsappmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		linkShareMenuQr() {
			if (this.reservationInfo && this.user) {
				return this.urlVenue + `/world/api/reservation/qrmenulink/${this.reservationInfo.id}/${this.user.id}`;
			} else {
				return ''
			}
		},
		isValidReservation(){
			return true
		},
		isCopilotRoute() {
			return this.$route.path.includes('/copilot')
		},
		matchingProductUnits() {
			return (product) => {
				if (!this.product || !this.product.sku) {
					console.error('Product or product SKU is not defined');
					return { cartItemCount: 0 };
				}
				return product.find(unit => unit.sku === this.product.sku) || { cartItemCount: 0 };
			};
		},
	},
	components: {
		'menu-product-card': MenuProductCard,
		'menu-cart-product': MenuCartProduct,
		'nav-bottom': NavBottom,
		'modal-message': ModalMessage,
		'generator-qr': GeneratorQr,
		'navbar-top': NavBarTop,
		'footer-welcom': FooterWelcom,
		HeaderComponent,
		NavbarScrollCategories,
		MenuSearcher,
		DismissButton,
		OrdersProductCard,
		SpinnerIcon,
		BagOrdersIcon,
		menuOffcanvasAssessment,
		MenuModalRequestAccount
	},
	async beforeMount() {
		this.activeMenu = this.menusVenue[0]
		this.setupData();
	},
	async mounted() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		this.menus = this.menusVenue
		this.selectMenuCategories()

		const self = this;
		const modalElement = document.getElementById('modalMenuRequestAction');

		modalElement.addEventListener('shown.bs.modal', function (e) {
			if (e.relatedTarget) {
				const action = e.relatedTarget.getAttribute('data-action');
				self.isRequestAccount = action === 'requestAccount';
			}
		});

		new bootstrap.Modal(modalElement, {
			backdrop: false // Esto elimina el backdrop
		});


		this.isLoad = true;
	},

	watch: {
		productsCart: {
			handler(newCart, oldCart) {
				const oldCartSkus = oldCart.map(product => product.sku);
				const newCartSkus = newCart.map(product => product.sku);

				// Add 'addProduct' class for new items in the cart
				newCart.forEach(product => {
					if (!oldCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.add('addProduct');
						}
					}
				});

				// Remove 'addProduct' class for items no longer in the cart
				oldCart.forEach(product => {
					if (!newCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.remove('addProduct');
						}
					}
				});

				// Update local storage with the new cart state
				localStorage.setItem('cart', JSON.stringify(newCart));
			},
			deep: true
		},
		menusVenue: {
			handler(menusVenue) {
				this.menus = menusVenue;
				this.selectMenuCategories()
			},
			deep: true
		}
	},
	methods: {
		ncodeCredentials(username, password) {
			const credentials = `${username}:${password}`;
			return btoa(credentials);
		},
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		addUnit(product) {
			store.commit('menu/addUnit', {product})
		},
		subtractUnit(product) {
			store.commit('menu/subtractUnit', {product})
		},
		async handleConfirmCart() {
			this.menuButtonContentOrder = 'Solicitando...&nbsp;&nbsp;<i class="fas fa-spinner fa-spin"></i>';

			const orderInfo = {
				name: this.user.name,
				welKey: this.user.welKey,
				userId: this.user.id,
				source: 2,
				pax: this.reservationInfo.pax,
				clientMessage: this.cartClientMessage,
				orderReservationName: this.user.name,
				reservationId: this.reservationInfo.id,
				av: 0,
			}

			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {

					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount
					};
				} else {

					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions
					});
				}
			});


			const resultOrder = await this.cartSessionRepository.createCartSessionOrder(orderInfo, products);
			this.isRequestAccount = false;
			console.log(resultOrder)
			if (resultOrder.result === 0) {
				this.menuButtonContentOrder = 'Solicitando&nbsp;&nbsp;<i class="fas fa-check"></i>'
				const modal = document.getElementById('orders');
				const offCanvasComander = document.getElementById('offcanvasCopilotCommander');
				const modalOrders = bootstrap.Modal.getInstance(modal);
				const offCanvas = bootstrap.Offcanvas.getInstance(offCanvasComander);
				modalOrders.hide();
				offCanvas.hide();

				this.menuButtonContentOrder = 'Hacer pedido';

				store.commit('menu/setProductsCart', {productsCart: []})
				this.cartClientMessage = '',

				hideAllModals();
			}
		},
		setupData() {
			const dateTime = new Date(this.reservationInfo.startDateTime);

			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const nameDay = daysOfWeek[dateTime.getDay()];
			const translator = {
				Sunday: "Dom.",
				Monday: "Lun.",
				Tuesday: "Mar.",
				Wednesday: "Mié.",
				Thursday: "Jue.",
				Friday: "Vie.",
				Saturday: "Sáb.",
			};
			const nameDayTranslated = translator[nameDay];

			const months = {
				Jan: 'Ene',
				Feb: 'Feb',
				Mar: 'Mar',
				Apr: 'Abr',
				May: 'May',
				Jun: 'Jun',
				Jul: 'Jul',
				Aug: 'Ago',
				Sep: 'Sep',
				Oct: 'Oct',
				Nov: 'Nov',
				Dec: 'Dic'
			};
			const month = months[dateTime.toLocaleString('en', {month: 'short'})];
			this.reservationDate = `${nameDayTranslated} ${dateTime.getDate()} ${month}`;

			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			this.reservationTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
		},
		formattedPrice (price) {
			return dateFormatterService.formattedPrice(price)
		},
		selectMenuCategories(menus = null) {
			const foodMenu = {
				id: 'food',
				name: 'Comida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories)
					: this.menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories),
			};
			const drinkMenu = {
				id: 'drink',
				name: 'Bebida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories)
					:this.menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories),
			};
			this.menuCategories = [foodMenu,drinkMenu];
			console.log(this.menuCategories);
		},
		filterMenuCategoriesByProducts(menusFiltered){
			console.log('desde menu',menusFiltered)
			this.selectMenuCategories(menusFiltered)
		},
	},

}
</script>

const state = {
	modalScannerQr: {
		showModal: false,
		modalTittle: "",
		idModal:"",
		user: null,
		firstReservation:null
	},

	modalWalkin: {
		showModal: true,
		modalTittle: "prueba",
		idModal:"",
		user: {name: "Error"},
		reservation: {pax: "Error"},
		menuUrl: null
	}
};

const getters = {
	modalScannerQr: state => {
		return state.modalScannerQr;
	},
	modalWalkin: state => {
		return state.modalWalkin;
	},
};

const mutations = {
	setModalScannerQr(state, payload) {
		state.modalScannerQr = payload.modalScannerQr;
	},
	setModalWalkin(state, payload) {
		state.modalWalkin = payload.modalWalkin;
	}
};

const actions = {


}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};

<template src="./kiosk.html"></template>

<script>
import {mapGetters} from "vuex";
import ModalScannerQR from "@/components/modalScannerQR/ModalScannerQR.vue";
import ModalKioskWalkin from "@/components/modalKioskWalkin/ModalKioskWalkin";
import FooterWelcom from "@/core/FooterWelcom";

export default {
	name: 'KioskWelcom',
	inject: {
		cameraService: 'cameraService',
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName']),
		...mapGetters('config',['weWelcomUrl'])
	},
	components:{
		modalScannerQr: ModalScannerQR,
		ModalKioskWalkin,
		FooterWelcom
	},
	created() {
		this.cameraService.sourceComponent = 'Kiosk';
		console.log('Se ha creado el componente Kiosk y hemos modificado el sourceComponent de cameraService')
	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {

	}
}
</script>

<template src="./copilotCardReservation.html"></template>

<script>
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import MenuWewelcom from "@/views/menu/Menu";
import {mapGetters} from "vuex";
import store from "@/store";
import {groupItemsBySkuAndChoices, hideAllModals, hideOffcanvas, printChoices, showOffcanvas} from "@/utils";
import TableIcon from "@/core/icons/TableIcon";
import * as bootstrap from 'bootstrap'
import CopilotCardSelectTable from "@/components/copilotCardSelectTable/CopilotCardSelectTable";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import DismissButton from "@/core/DismissButton";
import SittingIcon from "@/core/icons/SittingIcon";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'CopilotCardReservation',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		cartSessionRepository: 'cartSessionRepository',
		dateFormatterService: 'dateFormatterService',
		cameraService: 'cameraService',
	},
	emits: ['open-details'],
	props: {
		reservation: {},
		serviceName: String,
	},
	components: {
		'menu-wewelcom': MenuWewelcom,
		'generator-qr': GeneratorQr,
		'orders-product-card': OrdersProductCard,
		'table-icon': TableIcon,
		CopilotCardSelectTable,
		ChefHatIcon,
		DismissButton,
		SittingIcon,
		SpinnerIcon
	},
	data() {
		return {
			timeFormate: '',
			buttonTextOrder: 'Comanda anotada',
			finishReservationTextButton: 'Pedir cuenta',
			reactivateReservationTextButton: 'Reactivar reserva',
			promotionDiscountDrink: '',
			promotionDiscountFood: '',
			elapsedTime: '',
			isOpenReserve: false,
			paxTuUpdate: null,
			viewMode: 'unified',

			isWalkin: false,
			isSitting: false,
			isReserve: false,

			viewListUsers: true,
			pdfUrl: null
		}
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		...mapGetters('config', ['wePassUrl', 'weWelcomUrl']),
		...mapGetters('copilot', ['pendingOrders', 'tables', 'allOrderProducts', 'reservations', 'selectedRole','notifications','showTicketRequestNotifications']),
		localTables() {
			return this.tables.filter(table => table.name !== this.reservation.table);
		},
		cartSessionOrders() {
			return this.reservation.cartSession ? this.reservation.cartSession.productOrders : [];
		},
		paxUpdateDisabled() {
			return this.reservation.finishedDateTime != null;
		},
		processedCartSessionOrders() {
			return this.cartSessionOrders
				.filter(order => order.cartSessionStatus > 1)
		},
		isReservationRequest() {
			if (this.pendingOrders.length === 0 || !this.reservation.cartSession || this.reservation.cartSession.productOrders.length === 0) {
				console.log('No hay pedidos pendientes o cartSession no definido');
				return false;
			}

			const match = this.reservation.cartSession.productOrders.some(order => {
				return order.cartSessionOrderId === this.pendingOrders[0].cartSessionOrderId;
			});
			return match;
		},

		invitationText() {
			const users = this.reservation.reservationUsers;
			const reviews = this.reservation.userReviews;

			if (!users) {
				return '';
			}

			const sortedUsers = users.slice().sort((a, b) => {
				return a.type === 1 && b.type !== 1 ? -1 : b.type === 1 && a.type !== 1 ? 1 : 0;
			});

			return sortedUsers.map((diner, index, array) => {
				const userReview = reviews.find(review => review.userId === diner.userId);

				let text = '';
				if (userReview) {
					text += `<span class="mx-1 fw-bold"><i class="fas fa-star me-1" style="color: var(--bs-secondary)"></i>${userReview.score}</span>`;
				}

				if (diner.type === 1) {
					text += ` <b class="text-uppercase">${diner.name}</b>`;
				} else {
					text += diner.name;
				}

				text += index !== array.length - 1 ? ', ' : '.';

				return text;
			}).join('');
		},
		showTicketButton(){
			return this.resourceId === "wewelcom";
		},
		isReservationStatusStart() {
			return this.reservation.menuAccessEnabled === 1;
		},

		isReservationCancelled() {
			return this.reservation.status === -1;
		},
		isReservationFinish() {
			return this.reservation.status === 7;
		},
		historicUnification() {
			const groupedItems = groupItemsBySkuAndChoices(this.processedCartSessionOrders);
			console.log('antes de arrayProducts:', groupedItems);

			const arrayProducts = Object.values(groupedItems).map(item => ({
				productSku: item.productSku,
				name: item.name,
				quantity: item.count,
				category: item.category,
				pictureGeneral: item.pictureGeneral,
				pictureMhd: item.pictureMhd,
				units: item.units > 1 ? item.units : null,
				type: item.type,
				isVisible: item.isVisible,
				choices: item.choices.map(choice => ({
					...choice,
					quantity: choice.quantity || 1
				})),
				choicesKey: item.choicesKey
			}));

			console.log('arrayProducts:', arrayProducts);

			return arrayProducts.filter(product => product.isVisible);
		},
		isNoShow() {
			return this.reservation.status === -4
		},

		hasDubbing() {
			const currentReservation = this.reservation
			const endDate = new Date(this.reservation.endDateTime);
			const table = this.reservation.table;

			return this.reservations.some(other => {
				if (!other.finishedDateTime && !currentReservation.finishedDateTime && other.table === table && other.id !== currentReservation.id) {
					const startDate = new Date(other.startDateTime);
					const timeDifference = (startDate - endDate) / (1000 * 60);
					return timeDifference <= 30 && timeDifference > 0;
				}
				return false;
			});
		},
		reservationReview() {
			const reviews = this.reservation.userReviews;
			if (!reviews || reviews.length === 0) {
				return null;
			}
			const totalScore = reviews.reduce((sum, review) => sum + review.score, 0);

			const averageScore = totalScore / reviews.length;

			if (Number.isInteger(averageScore)) {
				return averageScore;
			}

			return Math.round(averageScore * 10) / 10;
		}
	},
	beforeMount() {
	},
	created() {
		this.cameraService.sourceComponent = 'Copilot';
		this.setReservationStatus()
	},
	mounted() {
		document.addEventListener('buttonTextOrderUpdated', () => {
			this.buttonTextOrder = 'Comanda anotada';
		});
		this.promotionDiscountDrink = this.calculateDiscountPercentage(this.reservation.promotion ? this.reservation.promotion.discount : null);
		this.promotionDiscountFood = this.calculateDiscountPercentage();

		this.updateElapsedTime();
		this.initializeTooltips();

		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);

		console.log({reserva: this.reservation})
		const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
		popoverTriggerList.forEach(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
	},
	methods: {
		setReservationStatus() {
			if (this.reservation.walkIn == null || this.reservation.walkIn === 0) {
				this.isReserve = true;
			} else if (this.reservation.walkIn === 1) {
				this.isWalkin = true;
			} else if (this.reservation.walkIn === 2) {
				this.isSitting = true;
			}
		},
		openCollapse(elementId) {
			let collapseElement = document.getElementById('collapse' + elementId);
			if (collapseElement) {
				let iconArrow = document.getElementById('iconArrow' + elementId)
				let containerElement = document.getElementById('containerArrow' + elementId)

				let collapseInstance = bootstrap.Collapse.getInstance(collapseElement);
				if (!collapseInstance) {
					collapseInstance = new bootstrap.Collapse(collapseElement, {
						toggle: false
					});
				}
				if (collapseElement.classList.contains('show')) {
					collapseInstance.hide();
					iconArrow.classList.remove('fa-rotate-180');
					containerElement.classList.remove('active');
					this.viewListUsers = true

				} else {
					collapseInstance.show();
					iconArrow.classList.add('fa-rotate-180')
					containerElement.classList.add('active');
					this.viewListUsers = false
				}
			}
		},
		groupedItems(order) {
			return groupItemsBySkuAndChoices([order]);
		},
		printChoices(choices) {
			return printChoices(choices)
		},
		getLinkShareMenuQr(reservation) {
			return this.wePassUrl + `/invitacion?wid=${reservation.id}&menu=1`;
		},
		async handleProcessOrder(cartSessionOrderId) {
			this.buttonTextOrder = `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`
			const resultProcessOrder = await this.cartSessionRepository.processOrder(cartSessionOrderId, this.selectedRole);

			if (resultProcessOrder.result === 0) {
				console.log('Orden: ', cartSessionOrderId, ' procesada');
				console.log(this.cartSessionOrders)
			}
		},
		async handlePrintAccount(){
			console.log("En la venue "+this.resourceId+" Vamos a imprimir el ticket de "+ this.reservation.id);

			let openTab = true;

			if (openTab){
				window.open(this.weWelcomUrl+"/world/api/reservation/ticket/"+this.reservation.id, '_blank');

			} else {
				try {
					console.log("Fetching PDF for reservation " + this.reservation.id);

					// Fetch the PDF as a Blob
					const response = await fetch(`${this.weWelcomUrl}/world/api/reservation/ticket/${this.reservation.id}`, {
						method: 'GET',
						headers: {
							'Accept': 'application/pdf',
						}
					});

					if (response.ok) {
						// Convert response into a Blob (PDF)
						const blob = await response.blob();

						// Create a local object URL for the Blob
						this.pdfUrl = URL.createObjectURL(blob);
					} else {
						console.error('Error fetching PDF');
					}
				} catch (error) {
					console.error('Error:', error);
				}
			}
		},
		arrayBufferToBase64(buffer) {
			let binary = '';
			const bytes = new Uint8Array(buffer);
			const len = bytes.byteLength;
			for (let i = 0; i < len; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			return window.btoa(binary);
		},
		async handelFinishReservation(noShow) {
			try {
				this.finishReservationTextButton = `Pidiendo cuenta <i class="fas fa-spinner fa-spin"></i>`
				hideAllModals();

				const resultFinishReservation = await this.reservationRepository.finishReservation(this.reservation.id, noShow)
				await store.dispatch('menu/requestAccount', {reservationInfo: this.reservation})

				if (resultFinishReservation.result === 0) {
					// store.commit("copilot/setToast", {
					// 	toast: {
					// 		toastMessage: "Se ha finalizad la reserva correctamente",
					// 		showToast: true,
					// 		idToast: "correctlyToast"
					// 	}
					// });
				}
			} catch (e) {
				console.log(e)
			}

		},
		async handelReactivateReservation() {
			try {
				this.reactivateReservationTextButton = `Reactivando la reserva <i class="fas fa-spinner fa-spin"></i>`
				const resultReactivateReservation = await this.reservationRepository.reactivateReservation(this.reservation.id)
				if (resultReactivateReservation.result === 0) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: "Se ha reactivado la reserva correctamente",
							showToast: true,
							idToast: "correctlyToast"
						}
					});
				}
				this.reactivateReservationTextButton = `Reactivar reserva`
			} catch (e) {
				console.log(e)
			}

		},

		updateElapsedTime() {
			if (!this.pendingOrders.length || !this.pendingOrders[0].createdDateTime) {
				return;
			}
			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.pendingOrders[0].createdDateTime)
		},
		calculateDiscountPercentage(discount) {
			if (discount !== null && discount !== 0 && discount !== '') {
				return `-${discount / 100}%`;
			}
			return null;
		},
		reservationStatus(reservation) {
			if (reservation.status === -1) {
				return 'cancelled';
			} else if (reservation.status === -4) {
				return 'no-show'
			} else if (reservation.status === 7) {
				return 'process-pay'
			} else if (reservation.status === 10) {
				return 'finish';
			} else if (reservation.menuAccessEnabled === 1) {
				return 'started';
			} else if (reservation.startDate !== null) {
				return 'standar'
			}
			return '';
		},
		openComanderMenu(event) {
			event.stopPropagation();
			hideAllModals();
			const collapseElement = document.getElementById('offcanvasCopilotCommander');
			if (collapseElement) {
				const collapseInstance = new bootstrap.Offcanvas(collapseElement, {
					toggle: false
				});
				collapseInstance.show();
			}
			console.log('Vamos a llamar a setear el commanderReservation le pasamos', this.reservation);
			store.commit('copilot/setCommanderReservation', {commanderReservation: this.reservation});
		},
		openQRInvitation(id, event) {
			if (event) {
				event.stopPropagation();
			}
			const collapseElement = document.getElementById('modalQr' + id);
			if (collapseElement) {
				const collapseInstance = new bootstrap.Modal(collapseElement, {
					toggle: false
				});
				collapseInstance.show();
			}
		},
		openOffcanvasHistoricAccount(id) {
			if (event) {
				event.stopPropagation();
			}
			showOffcanvas('offcanvas-historic-account-' + id)
		},
		initializeTooltips() {
			const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
			tooltipTriggerList.forEach(tooltipTriggerEl => {
				new bootstrap.Tooltip(tooltipTriggerEl);
			});
		},

		handleOptionClick(option, id) {
			this.isMenuOpen = false;
			if (option === 'camera') {
				this.cameraService.showModalCameraScan();
				console.log('camara', id)
				this.$store.dispatch('copilot/getIdReservation', id);
			}
		},

		openModalUpdatePax(id) {
			if (event) {
				event.stopPropagation();
			}

			showOffcanvas(id)
		},

		async updatePax() {
			event.preventDefault()
			const resultUpdatePax = await this.reservationRepository.updatePax({
				reservationWid: this.reservation.id,
				pax: this.paxTuUpdate
			});
			console.log(resultUpdatePax);
			if (resultUpdatePax.result === 0) {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Se han actualizado los comensales correctamente.',
						showToast: true,
						idToast: "correctlyToast"
					}
				});
				this.paxTuUpdate = null;
				hideOffcanvas('offcanvasUpdatePax-' + this.reservation.id)
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error actualizado los comensales correctamente.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
			}
		},
	}
}
</script>

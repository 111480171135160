<template>
	<svg style="width: 21px ;!important;height: 21px !important;" width="20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" xml:space="preserve">
  <path d="M44.7 46.3c-2.1-13.7 17.6-17.8 20.8-3.9l5.4 26.8H91c5.8 0 8.9 4.7 8.9 9v36.4c0 8.9-12.6 8.8-12.6-.2V86.2H61.6c-6 0-9.7-4.1-10.6-8.8zm9.4-16c6.5 0 11.8-5.2 11.9-11.8C66 12 60.7 6.7 54.1 6.7c-6.5 0-11.8 5.2-11.8 11.7 0 6.6 5.2 11.9 11.8 11.9"/>
		<path d="M28.4 60.6c-1.4-7.6 8.6-9.4 10-1.8l4.4 23.9c1 5 4.6 9.2 9.8 10.8 1.6.5 3.3.5 4.8.6l14.5.1c7.7 0 7.7 10.1-.1 10.1l-15.2-.1c-2.3 0-4.7-.3-7-1-9-2.7-15.3-10.1-16.9-18.7z"/>
</svg>
</template>

<script>
export default {
	name: 'SittingIcon',
	props: {
		fillColor: String
	}
}
</script>

<div v-if="!isCopilotRoute" class="product-card-client" :id="product.sku" @click="openInformationProduct(product.sku)" :data-bs-target="'#modal_' + product.sku">
	<div class="product-card-body">
		<img class="product-card-img" v-if="showPictureMenu !== null" :src="pictureMenu" alt="..."/>
		<div class="product-card-info">
			<div class="product-card-data">
				<div class="product-card-title">{{ product.name }}</div>
				<div class="product-card-price" v-if="product.price > 0">
					{{ formatFloat(product.price) }}
				</div>
				<p class="product-card-text" v-html="product.description"></p>
			</div>
		</div>
		<div class="product-card-btns">
			<div class="btns">
				<button v-if="matchingProductUnits.cartItemCount !== 0 && !product.productOptions" @click="subtractUnit('modalRemoveProduct_'+product.sku )" class="btn btn-primary btn-circle">
					<i class="fas" :class="matchingProductUnits.cartItemCount === 1 ? 'fa-trash' : 'fa-minus'"></i>
				</button>
				<div v-if="matchingProductUnits.cartItemCount !== 0 && !product.productOptions" class="product-card-unit">
					{{ matchingProductUnits.cartItemCount }} ud
				</div>
				<button v-if="isValidReservation" @click="hasProductOptions ? openInformationProduct(product.sku) : sendProductToCart($event)" :disabled="!isValidReservation" class="btn btn-primary btn-circle">
					<i class="fas fa-plus"></i>
				</button>
				<i v-else class="fa-solid fa-circle-info"></i>
			</div>
		</div>
	</div>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas_' + product.sku" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body ">
			<img v-if="showPictureMenu !== null" class="food-img" :src="pictureMenu" alt="">
			<div class="wrapper">
				<h5 class="offcanvas-title " id="offcanvasBottomLabel">{{ product.name }}</h5>
				<div class="food-desc" v-html="product.description"></div>
				<div v-if="product.allergens && product.allergens.length > 0" class="food-allergens">
					<img class="allergens-img" v-for="allergen in product.allergens" :key="allergen.id" :src="getImageAllergenUrl(allergen)" :alt="allergen.name">
				</div>
				<div v-else>
					<p>Consulta con el personal el listado de alérgenos.</p>
				</div>
				<div v-for="(productOption, index) in product.productOptions" :key="`product_option_${product.sku}-${productOption.id}`">
					<hr class="vr-horizontal">
					<div class="extras-container">
						<div class="extras-header">
							<div class="extras-title">
								<span v-if="product.productOptions.length > 1">{{ index + 1 }} de {{ product.productOptions.length }}: </span>{{ productOption.description }}
							</div>
							<div class="extras-subtitle">
								{{ productOption.max === 1 ? "Elige una opción" : productOption.max === productOption.min ? `Elige hasta ${productOption.max} opciones` : `Elige hasta ${productOption.max} opciones` }}
							</div>
						</div>
						<div class="extras-products-list">
							<menu-product-card-options :stackable="productOption.stackable || 0" :product="product" :options="productOption.options" :max="productOption.max" :min="productOption.min" @update-options="updateOptions(productOption.id, $event)" :ref="`product_option_card_${product.sku}-${productOption.id}`"></menu-product-card-options>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="offcanvas-footer" v-if="isValidReservation">
			<button type="button" class="btn btn-primary w-100" :id="'buttonModalAdd' + product.sku" @click="sendProductToCart" :disabled="!isValidReservation || !isOptionsWithinRange">Añadir</button>
		</div>
	</div>
	<div class="modal" :id="'modalRemoveProduct_'+product.sku " tabindex="-1">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4">Eliminar Producto del Carrito</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<p>¿Estás seguro de que deseas eliminar {{ product.name }} del carrito?</p>
				</div>
				<div class="modal-footer">
					<DismissButton :on-click="closeModal" content-btn-text="No, mantener producto"/>
					<button type="button" class="btn btn-danger" @click="confirmSubtractUnit">Eliminar</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div v-else class="col-3 p-0">
	<div class="product-card-employee product-card-employee-commander" :id="product.sku" @click="hasProductOptions ? openInformationProduct(product.sku) : sendProductToCart($event)" :data-bs-target="'#modal_' + product.sku">
		<picture v-if="showPictureMenu !== null" class="product-card-img">
			<img :src="pictureMenu" alt="..."/>
		</picture>
		<div class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<h5 class="product-card-title">{{ product.name }}</h5>
				</div>
			</div>
			<div class="product-card-btns">
				<div class="btns justify-content-end">
					<button v-if="matchingProductUnits.cartItemCount !== 0 && !product.productOptions.length" @click="subtractUnit('modalRemoveProduct_'+product.sku )" class="btn p-1 btn-primary btn-square btn-sm">
						<i class="fas" :class="matchingProductUnits.cartItemCount === 1 ? 'fa-trash' : 'fa-minus'"></i>
					</button>
					<div v-if="matchingProductUnits.cartItemCount !== 0 && !product.productOptions.length" class="badge badge-primary" style="flex:1;">
						{{ matchingProductUnits.cartItemCount }}
						<span v-if="isCopilotRoute">ud</span>
					</div>
					<button v-if="matchingProductUnits.cartItemCount !== 0 && !product.productOptions.length" @click="hasProductOptions ? openInformationProduct(product.sku) : sendProductToCart($event)" :disabled="!isValidReservation" class="btn p-1 btn-secondary btn-square btn-sm ">
						<i class="fas fa-plus"></i>
					</button>
					<button v-if="matchingProductUnits.cartItemCount === 0 && product.productOptions " class="btn btn-primary btn-square btn-sm me-2 p-0"><i class="fas fa-plus"></i></button>
				</div>
			</div>
		</div>
	</div>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas_' + product.sku" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body ">
			<img v-if="showPictureMenu !== null" class="food-img" :src="pictureMenu" alt="">
			<div class="wrapper">
				<h5 class="offcanvas-title " id="offcanvasBottomLabel">{{ product.name }}</h5>

				<div v-if="product.allergens && product.allergens.length > 0" class="food-allergens">
					<img class="allergens-img" v-for="allergen in product.allergens" :key="allergen.id" :src="getImageAllergenUrl(allergen)" :alt="allergen.name">
				</div>
				<div v-else>
					<p>Consulta con el personal el listado de alérgenos.</p>
				</div>
				<div v-for="(productOption, index) in product.productOptions" :key="`product_option_${product.sku}-${productOption.id}`">
					<hr class="vr-horizontal">
					<div class="extras-container">
						<div class="extras-header">
							<div class="extras-title">
								<span v-if="product.productOptions.length > 1">{{ index + 1 }} de {{ product.productOptions.length }}: </span>{{ productOption.description }}
							</div>
							<div class="extras-subtitle">
								{{ productOption.max === 1 ? "Elige una opción" : productOption.max === productOption.min ? `Elige hasta ${productOption.max} opciones` : `Elige hasta ${productOption.max} opciones` }}
							</div>
						</div>
						<div class="extras-products-list">
							<menu-product-card-options :stackable="productOption.stackable || 0" :product="product" :options="productOption.options" :max="productOption.max" :min="productOption.min" @update-options="updateOptions(productOption.id, $event)" :ref="`product_option_card_${product.sku}-${productOption.id}`"></menu-product-card-options>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="offcanvas-footer">
			<button type="button" class="btn btn-primary w-100" :id="'buttonModalAdd' + product.sku" @click="sendProductToCart" :disabled="!isValidReservation || !isOptionsWithinRange">Añadir</button>
		</div>
	</div>
	<div class="modal" :id="'modalRemoveProduct_'+product.sku " tabindex="-1">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4">Eliminar Producto del Carrito</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<p>¿Estás seguro de que deseas eliminar {{ product.name }} del carrito?</p>
				</div>
				<div class="modal-footer">
					<DismissButton :on-click="closeModal" content-btn-text="No, mantener producto"/>
					<button type="button" class="btn btn-danger" @click="confirmSubtractUnit">Eliminar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<template>

	<div data-bs-theme="elcapirotedegranada">

		<nav class="navbar navbar-custom">
			<div class="container-fluid"><a class="navbar-brand" href="#">
				<img class="logo" src="https://wewelcom.io/world/img/elcapirotedegranada/logo/logo.png" alt=""> </a>
				<button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="offcanvas offcanvas-primary offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
					<div class="offcanvas-header">
						<!--					<h5 class="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>-->
						<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
					</div>
					<div class="offcanvas-body">
						<ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
							<li class="nav-item">
								<a class="nav-link active" aria-current="page" href="#reserve">Hacer una reserva</a>
							</li>
							<li class="nav-item"><a class="nav-link" href="#galery">Galería</a>
							</li>
							<!-- <li class="nav-item"><a class="nav-link" href="#about">Sobre Nosotros</a>
							</li> -->
							<li class="nav-item"><a class="nav-link" href="#contact">Contacto</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
		<header class="header-container header-xl">
			<div class="header-content" style="backgroundImage:url('https://wewelcom.io/world/img/elcapirotedegranada/header/header.jpg')">
				<img class="logo" src="https://wewelcom.io/world/img/elcapirotedegranada/logo/logo.png" alt="">
				<a href="/reservar " target="_blank" class="btn btn-secondary z-1">Hacer una reserva</a>
			</div>
		</header>
		<section id="galery">
			<div class="container">
				<div class="section-header">
					<h2>Galería</h2>
				</div>
				<div class="section-body">
					<h3>Nuestro espacio</h3>
					<div class="content-carousel">
						<div id="carouselExampleIndicatorsSpace" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
							<div class="carousel-indicators">
								<button type="button" data-bs-target="#carouselExampleIndicatorsSpace" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/1.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsSpace" data-bs-slide-to="1" aria-label="Slide 2">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/2.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsSpace" data-bs-slide-to="2" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/3.jpg" class=" w-100" alt="...">
								</button>
							</div>
							<div class="carousel-inner">
								<div class="carousel-item img-normal active">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/1.jpg" class=" w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/2.jpg" class=" w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestroespacio/3.jpg" class=" w-100" alt="...">
								</div>
							</div>
							<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsSpace" data-bs-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span>
							</button>
							<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsSpace" data-bs-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span>
							</button>
						</div>
					</div>
				</div>
				<div class="section-body">
					<h3>CAPIROTE VIBES </h3>
					<div class="content-carousel">
						<div id="carouselExampleIndicatorsCapirotevibes" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
							<div class="carousel-indicators">
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
									<img src="https://wewelcom.wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/1.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="1" aria-label="Slide 2">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/2.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="2" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/3.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="3" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/4.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="4" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/5.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="5" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/6.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="6" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/7.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="7" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/8.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="8" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/9.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="9" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/10.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="10" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/11.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="11" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/12.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="12" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/13.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="13" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/14.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="14" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/15.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="15" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/16.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="16" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/17.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="17" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/18.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="18" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/19.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide-to="19" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/20.jpg" class=" w-100" alt="...">
								</button>
							</div>
							<div class="carousel-inner">
								<div class="carousel-item img-normal active">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/1.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/2.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/3.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/4.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/5.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/6.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/7.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/8.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/9.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/10.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/11.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/12.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/13.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/14.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/15.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/16.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/17.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/18.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/19.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/capirotevibes/20.jpg" class="d-block w-100" alt="...">
								</div>
							</div>
							<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span>
							</button>
							<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsCapirotevibes" data-bs-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span>
							</button>
						</div>
					</div>
				</div>
				<div class="section-body">
					<h3>
						NUESTROS PLATOS </h3>
					<div class="content-carousel">
						<div id="carouselExampleIndicatorsOurDishes" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
							<div class="carousel-indicators">
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/1.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="1" aria-label="Slide 2">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/2.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="2" aria-label="Slide 3">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/3.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="3" aria-label="Slide 4">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/4.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="4" aria-label="Slide 5">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/5.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="5" aria-label="Slide 6">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/6.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="6" aria-label="Slide 7">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/7.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="7" aria-label="Slide 8">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/8.jpg" class=" w-100" alt="...">
								</button>
								<button type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide-to="8" aria-label="Slide 9">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/9.jpg" class=" w-100" alt="...">
								</button>
							</div>
							<div class="carousel-inner">
								<div class="carousel-item img-normal active">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/1.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/2.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/3.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/4.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/5.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/6.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/7.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/8.jpg" class="d-block w-100" alt="...">
								</div>
								<div class="carousel-item img-normal">
									<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/nuestrosplatos/9.jpg" class="d-block w-100" alt="...">
								</div>
							</div>
							<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span>
							</button>
							<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsOurDishes" data-bs-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span>
							</button>
						</div>
					</div>
				</div>
				<div class="section-footer">
					<a href="https://elcapirotedegranada.wewelcom.io/menu" class="btn btn-secondary mb-3"> Carta Online</a>
				</div>
			</div>
		</section>
		<section id="reserve" class="section-primary">
			<div class="container">
				<div class="row flex-row-reverse">
					<div class="col-md-6 content-img-about">
						<img src="https://elcapirotedegranada.wewelcom.io/world/img/elcapirotedegranada/header/header.jpg" class="img-w-m" alt="...">
					</div>
					<div class="col-md-6">
						<div class="section-header">
							<h2>sobre nosotros</h2>
						</div>
						<div class="section-body">
							<p>Accede a nuestra amplia carta de comidas y bebidas, reserva tu mesa</p>
						</div>
						<div class="section-footer">
							<a href="/reservar" target="_blank" class="btn btn-secondary">Hacer una reserva</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="services">
			<div class="container">
				<div class="section-header">
					<h2>Nuestros servicios</h2>
				</div>
				<div class="section-body">
					<div class="row row-cols-3 row-cols-md-6 text-center row-services">
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/aire.png" class="" alt="...">
							</div>
							<div>
								Aire acondicionado
							</div>
						</div>
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/terraza.png" class="" alt="...">
							</div>
							<div>
								Terraza
							</div>
						</div>
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/eventos.png" class="" alt="...">
							</div>
							<div>
								Eventos privados
							</div>
						</div>
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/llevar.png" class="" alt="...">
							</div>
							<div>
								Para llevar
							</div>
						</div>
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/wifi.png" class="" alt="...">
							</div>
							<div>
								Wifi gratis
							</div>
						</div>
						<div class="">
							<div>
								<img src="https://wewelcom.io/world/img/elcapirotedegranada/landing/mascotas.png" class="" alt="...">
							</div>
							<div>
								Se permiten mascotas
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section-primary">
			<div class="container">
				<div class="section-header">
					<h2>
						Opciones de pago </h2>
				</div>
				<div class="section-body">
					<div class="row row-cols-auto justify-content-center row-services">
						<div class=""><i class="fab fa-cc-amex"></i>
							<div>
								American express
							</div>
						</div>
						<div class=""><i class="fas fa-money-bill"></i>
							<div>
								En efectivo
							</div>
						</div>
						<div class=""><i class="fas fa-wallet"></i>
							<div>
								Contactless
							</div>
						</div>
						<div class=""><i class="fab fa-cc-mastercard"></i>
							<div>
								Mastercard
							</div>
						</div>
						<div class=""><i class="fas fa-ticket-alt"></i>
							<div>
								Ticket Restaurant
							</div>
						</div>
						<div class=""><i class="fab fa-cc-visa"></i>
							<div>
								VISA
							</div>
						</div>
						<div class=""><i class="fas fa-credit-card"></i>
							<div>
								Tarjeta de débito
							</div>
						</div>
						<div class=""><i class="fab fa-apple-pay"></i>
							<div>
								Apple Pay
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="container">
				<div class="section-header">
					<h2>Horario de apertura</h2>
				</div>
				<div class="section-body">
					<ul class="list-group">
						<li class="list-group-item"><b>
							Lunes - Miércoles </b>
							07:45-00:00
						</li>
						<li class="list-group-item"><b> Jueves </b>
							07:45-01:00
						</li>
						<li class="list-group-item"><b> Viernes </b>
							07:45-02:30
						</li>
						<li class="list-group-item"><b> Sábado </b>
							08:00-02:30
						</li>
						<li class="list-group-item"><b> Domingo </b>
							Estamos cerrados
						</li>
					</ul>
				</div>
				<!--				<div class="section-body">-->
				<!--					<div>-->
				<!--						<div class="row">-->
				<!--							<div class="col-6">-->
				<!--								<b> Lunes - Miércoles-->
				<!--								</b>-->
				<!--							</div>-->
				<!--							<div class="col-6">-->
				<!--								07:45-00:00-->
				<!--							</div>-->
				<!--						</div>-->
				<!--						<div class="row">-->
				<!--							<div class="col-6">-->
				<!--								<b> Jueves-->
				<!--								</b>-->
				<!--							</div>-->
				<!--							<div class="col-6">-->
				<!--								07:45-01:00-->
				<!--							</div>-->
				<!--						</div>-->
				<!--						<div class="row">-->
				<!--							<div class="col-6">-->
				<!--								<b> Viernes-->
				<!--								</b>-->
				<!--							</div>-->
				<!--							<div class="col-6">-->
				<!--								07:45-02:30-->
				<!--							</div>-->
				<!--						</div>-->
				<!--						<div class="row">-->
				<!--							<div class="col-6">-->
				<!--								<b> Sábado-->
				<!--								</b>-->
				<!--							</div>-->
				<!--							<div class="col-6">-->
				<!--								08:00-02:30-->
				<!--							</div>-->
				<!--						</div>-->
				<!--						<div class="row">-->
				<!--							<div class="col-6">-->
				<!--								<b> Domingo-->
				<!--								</b>-->
				<!--							</div>-->
				<!--							<div class="col-6">-->
				<!--								Estamos cerrados-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</div>-->
			</div>
		</section>
		<section class="section-secondary">
			<div class="row row-column-gap text-center justify-content-center">
				<div class="col-md-auto">
					<div class="footer-body">
						<i class="fas fa-phone"></i>
						+34 915457800
						<br>
						<i class="fas fa-envelope"></i>
						tarambanaig@gmail.com
						<br>
						<i class="fas fa-home"></i>
						CL ALONSO CANO 38 <br>
						28003 MADRID <br>
						España
					</div>
				</div>
				<div class="col-md-auto align-content-center">
					<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalGoogleMaps">
						Mostrar mapa
					</button>
				</div>
			</div>
		</section>
		<!--	<footer id="contact" class="footer-secondary">-->
		<!--				<div class="footer-footer">-->
		<!--					<div class="row text-center row-column-gap">-->
		<!--						<div class="col-12">-->
		<!--							<div class="row row-cols-auto justify-content-center"><a data-bs-toggle="modal" data-bs-target="#legal-terms-modal" class="legal-warning" target="_blank" rel="noopener noreferrer">-->
		<!--								Aviso legal </a><a href="https://website.dish.co/privacywebsite/data?id=1293544&standalone=TRUE&lang=es&modaldisplay=true" class="legal-warning" target="_blank" rel="noopener noreferrer">-->
		<!--								Política de privacidad</a>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--						<div class="col-12">-->
		<!--							<p>Diseñado por <a href="https://wewelcom.io/" class="legal-warning">WEWELCOM</a>. Todos los derechos reservados</p>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</div>-->
		<!--	</footer>-->
		<footer-welcom></footer-welcom>
		<!-- Modal -->
		<div class="modal fade" id="legal-terms-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5" id="exampleModalLabel">Aviso legal</h1>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<ul class="list-group">
							<li class="list-group-item">
								<div class="fw-bold">Nombre</div>
								<p>El capirote de</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Apellido</div>
								<p>Granada</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Dirección postal</div>
								<p>CL ALONSO CANO 38</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Código postal</div>
								<p>28003</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">
									Localidad
								</div>
								<p>España</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Número de teléfono</div>
								<p>+34 915457800</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Dirección de correo electrónico</div>
								<p>tarambanaig@gmail.com</p>
							</li>
							<li class="list-group-item">
								<div class="fw-bold">Número de identificación fiscal</div>
								<p>B44933349</p>
							</li>
						</ul>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Salir</button>
					</div>
				</div>
			</div>
		</div>
		<div id="modalGoogleMaps" aria-hidden="true" aria-labelledby="modalGoogleMap" class="modal fade" tabindex="-1">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-body">
						<div class="map-container">
							<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.5734554796904!2d-3.6975018999999993!3d40.440443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4228f12de026c3%3A0x924af511492194d7!2sC.%20de%20Alonso%20Cano%2C%2038%2C%20Chamber%C3%AD%2C%2028003%20Madrid!5e0!3m2!1ses!2ses!4v1716995799893!5m2!1ses!2ses' width="600" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Salir</button>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
import * as bootstrap from 'bootstrap';
import FooterWelcom from "@/core/FooterWelcom";

export default {
	name: 'HomePage',
	components: {FooterWelcom},
	mounted() {
		const carousels = [
			'#carouselExampleIndicatorsSpace',
			'#carouselExampleIndicatorsCapirotevibes',
			'#carouselExampleIndicatorsOurDishes'
		];

		carousels.forEach(selector => {
			new bootstrap.Carousel(document.querySelector(selector), {
				pause: false
			});
		});
	}
}
</script>

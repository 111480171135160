import {DateTime} from 'luxon';
import {reservationRepository} from "@/service/repository/reservationRepository";
import {copilotRepository} from "@/service/repository/copilotRepository";
const state = {
	selectedDate: DateTime.now().toISODate(),
	isLoadingGetReservations: false,
	reservations: [],
	weekReservations: [],
	tables: [],
	serviceEvents: [],

};

const getters = {
	selectedDate: state => {
		return state.selectedDate;
	},
	isLoadingGetReservations: state => {
		return state.isLoadingGetReservations;
	},
	reservations: state => {
		return state.reservations.slice().sort((a, b) => {
			return new Date(a.startDateTime) - new Date(b.startDateTime);
		});
	},
	weekReservations: state => {
		return state.weekReservations
	},
	tables: state => {
		return state.tables;
	},
	serviceEvents: state => {
		return state.serviceEvents
	},
};

const mutations = {
	setSelectedDate(state, payload) {
		state.selectedDate = payload.selectedDate;
	},
	setReservations(state, payload) {
		state.reservations = payload.reservations;
		state.isLoadingGetReservations = false;
	},
	setWeekReservations(state, payload) {
		state.weekReservations = payload.reservations;
	},
	setTables(state, payload) {
		state.tables = payload.tables;
	},
	setServiceEvents(state, payload) {
		state.serviceEvents = payload.serviceEvents;
	},

};

const actions = {
	async updateReservations({commit}) {
		try {
			commit('setIsLoadingGetReservations', {isLoadingGetReservations: true});
			console.log('La fecha desde', state.selectedDate)
			const resultGetByDay = await reservationRepository.getReservationsByDay(state.selectedDate);
			//
			// if (resultGetByDay === 401) {
			// 	return window.location.href = origin + '/login';
			// }
			//
			// if (resultGetByDay === 403) {
			// 	return alert('No estas autorizado para acceder a copilot');
			// }

			commit('setReservations', {reservations: resultGetByDay.reservations});
			commit('setServiceEvents', {serviceEvents: resultGetByDay.serviceEvents});

		} catch (e) {
			commit('setIsLoadingGetReservations', {isLoadingGetReservations: false});
			console.error(e)
		}
	},
	async getTables({commit}) {
		try {
			const rommsAndTablesResult = await copilotRepository.getTables();
			let rooms = rommsAndTablesResult.rooms;

			if (rooms.length >= 0) {
				console.log("Las rooms llegan como array")
			} else {
				rooms = Object.values(rooms);
			}
			const tables = rooms.flatMap(room => room.tables.map(table => ({
					...table,
					room: room.name
				}))
			);
			console.log('Las mesas: ', tables)
			if (rommsAndTablesResult.result === 0) {
				commit('setTables', {tables: tables});
			}
		} catch (error) {
			console.error("Failed to fetch venue menus", error);
		}
	},
	// async updateReservationsForWeek({ commit }) {
	// 	try {
	// 		console.log('Llamada a updateReservationsForWeek');
	// 		commit('setIsLoadingGetReservations', { isLoadingGetReservations: true });
	// 		console.log('La fecha desde', state.selectedDate);
	//
	// 		const resultGetByWeek = await reservationRepository.getReservationsByWeek(state.selectedDate);
	//
	// 		commit('setWeekReservations', { reservationsByWeek: resultGetByWeek.reservations });
	// 		commit('setIsLoadingGetReservations', { isLoadingGetReservations: false });
	//
	// 	} catch (e) {
	// 		console.error(e);
	// 		commit('setIsLoadingGetReservations', { isLoadingGetReservations: false });
	// 	}
	// }
	async updateReservationsForWeek({ commit, state }) {
		try {
			console.log('Llamada a updateReservationsForWeek');
			console.log('La fecha desde', state.selectedDate);

			const selectedDate = new Date(state.selectedDate);
			const startDate = new Date(selectedDate);
			startDate.setDate(startDate.getDate() - startDate.getDay() + 1); // Comienza el lunes

			const promises = [];
			for (let i = 0; i < 7; i++) {
				const date = new Date(startDate);
				date.setDate(startDate.getDate() + i);
				const isoDate = date.toISOString().split('T')[0];
				promises.push(reservationRepository.getReservationsByDay(isoDate));
			}

			const results = await Promise.all(promises);
			const reservations = [];
			results.forEach(result => {
				reservations.push(...result.reservations);
			});

			commit('setWeekReservations', { reservations });

		} catch (e) {
			console.error(e);
		}
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};

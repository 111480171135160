<template>
	<router-view></router-view>
	<toast-welcom></toast-welcom>
	<ModalNotificationWelcom></ModalNotificationWelcom>
</template>

<script>

import ToastWelcom from "@/components/toastWelcom/ToastWelcom";
import ModalNotificationWelcom from "@/components/modalNotification/ModalNotification";


export default {
	name: 'App',
	components: {ModalNotificationWelcom, ToastWelcom},
	data() {
		return {
			showExitModal: false,
			preventExit: true
		};
	},
	methods: {
	},
	mounted() {
		/*window.addEventListener("beforeunload", (event) => {
			event.preventDefault();
			event.returnValue = "";
			return "";
		});*/
	},
	beforeUnmount() {
	}
}
</script>


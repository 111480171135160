<template>
	<div id="offcanvas-order-drink" aria-hidden="true" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4"><i class="fa-solid fa-wine-glass"></i> Bebidas pendientes</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<div v-for="reservation in sortedReservationsWithProducts" :key="'copilot-offcanvas-hybrid-role-reservation-'+reservation.id">
				<copilot-offcanvas-hybrid-role-reservation :reservation="reservation"></copilot-offcanvas-hybrid-role-reservation>
			</div>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
</template>

<script>

import DismissButton from "@/core/DismissButton";
import {mapGetters} from "vuex";
import CopilotOffcanvasHybridRoleReservation
	from "@/components/copilotOffcanvasHybridRoleReservation/CopilotOffcanvasHybridRoleReservation";
export default {
	inject: {
		productOrderRepository: 'productOrderRepository',
	},
	components: {CopilotOffcanvasHybridRoleReservation, DismissButton},
	props: {},
	data() {
		return {}
	},
	computed:{
		...mapGetters('copilot', ['reservationsWithProducts', 'availableRoles', 'selectedRole']),
		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => !reservation.finishedDateTime)
				.slice()
				.sort((a, b) => {
					if (a.cartsession && !b.cartsession) {
						return -1;
					} else if (!a.cartsession && b.cartsession) {
						return 1;
					} else {
						return 0;
					}
				});
		},
	},
	watch: {
	},
	beforeMount() {
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<div id="modal-check-inbox" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="alertModalLabel"
	 style="display: none;" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title"><i class="fas fa-exclamation-circle"></i> Valida tu email.</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="alert alert-info">
					<b><i class="fa fa-spinner fa-pulse"></i>&nbsp;&nbsp;Revisa tu bandeja de entrada...</b>
				</div>

				<p><span id="modal-check-inbox-message-top">  <b>Por favor, revisa tu bandeja de
				  entrada y haz clic en el enlace que
				  te hemos enviado para validar tu
				  e-mail.</b> </span></p>
				<p>
					Las notificaciones sobre el estado
					de tu reserva serán enviadas a esa
					dirección:
				</p>
				<p><b>{{ mailConfirmSave }}</b></p>
			</div>
			<div class="modal-footer">
				<DismissButton/>
			</div>
		</div>
	</div>
</div>

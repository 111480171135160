import {configRepository} from "@/service/repository/configRepository";

const state = {
    websocketUrl: null,
    websocketPort: null,
	wePassUrl: null,
	weWelcomUrl: null,
	wsConnectionStatus: 3
};

const getters = {
	websocketUrl(state) {
		return state.websocketUrl;
	},
	websocketPort(state) {
		return state.websocketPort;
	},
	weWelcomUrl(state) {
		return state.weWelcomUrl;
	},
	wePassUrl(state) {
		return state.wePassUrl;
	},
	wsConnectionStatus(state) {
		return state.wsConnectionStatus;
	},
	isProductionEnvironment(state){
		return state.weWelcomUrl === "https://wewelcom.io";
	}
};

const mutations = {
	setWsConnectionStatus(state, payload){
		state.wsConnectionStatus = payload.wsConnectionStatus;
	},
	initConfig(state, payload){
		console.log("Estableciendo la configuración del servidor", payload)
		state.websocketUrl = payload.websocket_url;
		state.websocketPort = payload.websocket_port;
		state.wePassUrl = payload.wepass_url;
		state.weWelcomUrl = payload.wewelcom_url;
	},
};

const actions = {
	async loadSystemConfig({ commit }) {
		try {
			const config = await configRepository.loadSystemConfig();
			commit('initConfig', config);
		} catch (error) {
			console.error("Failed to fetch system config", error);
		}
	},
}

export default {
    namespaced: true,
    state,
    mutations,
	actions,
	getters
};

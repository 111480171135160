const state = {
	dataNavigationWarningModal: null
};

const getters = {
	dataNavigationWarningModal(state){
		return state.dataNavigationWarningModal
	}
};

const mutations = {
	setDataNavigationWarningModal(state, payload){
		state.dataNavigationWarningModal = payload.dataNavigationWarningModal
	}
};

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

<div id="welcom-login" class="content-center-justify">

	<HeaderComponent :url-restaurant="weWelcomUrl" text-title="Login" :subTitle="businessName" :image-restaurant="resourceId"/>

	<div class="container" v-if="!isLogged">
		<section>
			<h2><i class="fas fa-sign-in-alt"></i> Iniciar sesión</h2>
			<form class="row row-column-gap row-margin-x form-w">
				<div class="col-12 col-6">
					<div class="input-group">
						<span class="input-group-text prepend-icon"><i class="fa fa-user"></i></span>
						<input :ref="`signup-email`" id="signup-email" v-model="signupEmail" name="email" type="email"
							   class="form-control" placeholder="Email:" required="true">
					</div>
				</div>
				<div class="col-12 col-6">
					<div class="input-group">
						<span class="input-group-text prepend-icon"><i class="fas fa-unlock-alt"></i></span>
						<input :ref="`signup-password`" id="signup-password" v-model="signupPassword" name="password" type="password"
							   class="form-control" placeholder="Contraseña:" required="true">
					</div>
				</div>

				<div class="col-12">
					<div id="reservationResponseCollapse" v-if="showErrorMessageForm" class="alert alert-danger">
						{{errorMessage}}
					</div>
				</div>

				<div class="row-center">
					<button :disabled="false" class="btn btn-primary" @click="submitLogin"><span
						v-html="loginButtonContent"></span><SpinnerIcon v-if="isSpinnerIconLoading"/></button>
				</div>
			</form>
		</section>
	</div>

	<div v-else>
		<section>
			<h2><i class="fas fa-sign-in-alt"></i> Selecciona la pagina a la que acceder.</h2>
			<form class="form-check form-check-inline " >
				<div class="row-center " v-for="role in userRoles" :key="role">
					<input class="form-check-input" type="radio" :id="role" :value="role" v-model="selectedRole"/>
					<label class="form-check-label" :for="role">{{ roleNameResolver[role] }}</label>
				</div>

				<div class="row-center">
					<button :disabled="isDisabledButtonRedirect" class="btn btn-primary" @click="submitOnRedirect">Acceder</button>
				</div>
			</form>
		</section>

	</div>

	<footer-welcom></footer-welcom>

</div>

<template v-if="service && service.serviceReservations && service.serviceReservations.length">
	<!--  Contenedor del servicio  -->
	<section class="section-service collapsed" :class="{ 'open': isOpen(service.serviceName),'services-active': seatedReservations && seatedReservations.totalReservations.length !== 0}" :id="`parentCollapseServices${service.serviceName}`" data-bs-toggle="collapse" :href="`#childrenCollapseService${service.serviceName}`" role="button" aria-expanded="false" :aria-controls="`childrenCollapseService${service.serviceName}`">
		<div class="service-title" :class="{'active': seatedReservations && seatedReservations.totalReservations.length !== 0}">
			<div class="row">
				<div class="col-6">
					<h3>{{ service.serviceName }}</h3>
				</div>
				<div class="col-6">
					<div class="service-meta">
						<span>
							<span><i class="fas fa-user"></i> {{ serviceReservations?.totalPax || '0' }}</span>
							<div class="vr mx-2"></div>
							<span><i class="fas fa-calendar-day"></i> {{ serviceReservations?.totalReservations?.length || '0' }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="service-subtitle" v-if="seatedReservations && seatedReservations.totalReservations.length !== 0">
			<div class="row">
				<div class="col-6">
					<div class="service-meta">
						<span>Por venir</span>
						<span>
							<span><i class="fas fa-user"></i> {{ reservationsToArrive ? reservationsToArrive.totalPax : 0 }}</span>
							<div class="vr mx-1"></div>
							<span><i class="fas fa-calendar-day"></i> {{ reservationsToArrive ? reservationsToArrive.totalReservations.length : 0 }}</span>
						</span>
					</div>
				</div>
				<div class="col-6">
					<div class="service-meta">
						<span>Sentadas</span>
						<span>
							<span><i class="fas fa-user"></i> {{ seatedReservations ? seatedReservations.totalPax : 0 }}</span>
							<div class="vr mx-1"></div>
							<span><i class="fas fa-calendar-day"></i> {{ seatedReservations ? seatedReservations.totalReservations.length : 0 }}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!--  Contenido del collapse	-->
	<div class="collapse" data-bs-parent="#accordionAllServices" :id="`childrenCollapseService${service.serviceName}`">
		<!--		<div :class="{ 'show': isOpen(service.serviceName) }">-->
		<template v-if="currentReservations?.totalReservations?.length && isLoad">

			<copilot-card-reservation v-for="reservation in sortedActiveReservations" :key="'key-'+reservation.id" :service-name="service.serviceName" :reservation="reservation"></copilot-card-reservation>

			<!--  Informacion de del dia	-->
			<div class="service-reservation-footer">
				<div class="row g-0">
					<div class="col-6">
						<button class="finish collapsed btn btn-sm btn-light w-100"
								id="parentCollapseFinishReservations"
								data-bs-toggle="collapse"
								:href="`#childrenCollapseFinishReservations${service.serviceName}`"
								aria-expanded="false"
								aria-controls="childrenCollapseNoShowReservations">
							<span>Final..</span>
							<span> <span><i class="fas fa-user"></i> {{ finalizedReservations ? finalizedReservations.totalPax : 0 }}</span> <div class="vr mx-1"></div> <span><i class="fas fa-calendar-day"></i> {{ finalizedReservations ? finalizedReservations.totalReservations.length : 0 }}</span> </span>
						</button>
					</div>
					<div class="col-6">
						<button class="no-show collapsed btn btn-sm btn-light w-100"
								data-bs-toggle="collapse"
								:href="`#childrenCollapseNoShowReservations${service.serviceName}`"
								aria-expanded="false"
								aria-controls="childrenCollapseNoShowReservations">
							<span>No show</span>
							<span> <span><i class="fas fa-user "></i> {{ noShowReservations ? noShowReservations.totalPax : 0 }}</span> <div class="vr mx-1"></div> <span><i class="fas fa-calendar-day"></i> {{ noShowReservations ? noShowReservations.totalReservations.length : 0 }}</span> </span>
						</button>
					</div>
				</div>
			</div>

			<!--		Collapse no show		-->
			<div class="collapse" :data-bs-parent="`#childrenCollapseService${service.serviceName}`" :id="`childrenCollapseNoShowReservations${service.serviceName}`">
				<div class="mb-3 pb-3">
					<div v-if="sortedNoShowReservations.length">
						<copilot-card-reservation v-for="reservation in sortedNoShowReservations" :key="reservation.id" :service-name="service.serviceName" :reservation="reservation"></copilot-card-reservation>
					</div>
					<div class="text-center w-100 p-3" v-else>
						No hay reservas no show
					</div>
				</div>
			</div>

			<!--		Collapse finish		-->
			<div class="collapse " :data-bs-parent="`#childrenCollapseService${service.serviceName}`" :id="`childrenCollapseFinishReservations${service.serviceName}`">
				<div class="service-reservation-footer">
					<div class="container">
						<div class="row">
							<div class="col-6">
								<div class="service-meta">
									<span>Walk in</span>
									<span>
														<i class="fas fa-user"></i> {{ walkinReservations ? walkinReservations.totalPax : 0 }}
														<div class="vr mx-1"></div>
														<i class="fas fa-calendar-day"></i> {{ walkinReservations ? walkinReservations.totalReservations.length : 0 }}
													</span>
								</div>
							</div>
							<div class="col-6">
								<div class="service-meta">
									<span>Reservas</span>
									<span>
														<i class="fas fa-user"></i> {{ reserveReservation ? reserveReservation.totalPax : 0 }}
														<div class="vr mx-1"></div>
														<i class="fas fa-calendar-day"></i> {{ reserveReservation ? reserveReservation.totalReservations.length : 0 }}
													</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-3 pb-3">
					<div v-if="sortedFinishReservations.length">
						<copilot-card-reservation v-for="reservation in sortedFinishReservations" :key="reservation.id" :service-name="service.serviceName" :reservation="reservation"></copilot-card-reservation>
					</div>
					<div class="text-center w-100 p-3" v-else>
						no hay reservas finalizadas
					</div>
				</div>
			</div>
		</template>

		<!--  Si no hay reservas	-->
		<section v-else class="text-center container-section">
			<b>{{ emptyStateDescription }}</b>
		</section>
	</div>
	<!--	</div>-->
</template>

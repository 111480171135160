<template>
	<!-- Modal confirm Finish-->
	<div class="modal fade" id="modal-qr-google" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4">
						<GoogleIcon/> QR Google
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

				<div id="'modalQr' + reservation.id" class="content-center">
					<GeneratorQr :link="reviewGoogleMapsUrl"/>
				</div>

				<div class="text-center my-2">Muéstrale el QR al cliente, <span class="fw-bold">¡y anímalo a valorarnos en Google!</span></div>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from "bootstrap";

import {mapGetters} from "vuex";
import {useRoute} from "vue-router";
import {hideAllModals} from "@/utils";
import DismissButton from "@/core/DismissButton";
import GoogleIcon from "@/core/icons/googleIcon";
import GeneratorQr from "@/components/generatorQr/GeneratorQr";

export default {
	name: 'ModalQrGoogle',

	data() {
		return {}
	},
	watch: {},
	props: {},
	inject: {
		cameraService: 'cameraService',
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName','reviewGoogleMapsUrl']),

		isInKiosksRoute() {
			const route = useRoute();
			return route.path === '/kiosk';
		},
	},
	components: {
		GeneratorQr,
		GoogleIcon,
		DismissButton
	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {
		// showToast(toastId) {
		// 	const toastElement = document.getElementById(toastId);
		// 	const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
		// 	toastInstance.show();
		// },

		showModalAlert(modalId) {
			const modalCheckInbox = new bootstrap.Modal(document.getElementById(modalId), {
				backdrop: 'static',
				keyboard: false
			});
			modalCheckInbox.show();
		},
		openQRInvitation() {
			this.cameraService.stopCamera()

			console.log(this.reservationId)
			hideAllModals();
			const collapseElement = document.getElementById('modalQr' + this.reservationId);
			if (collapseElement) {
				const collapseInstance = new bootstrap.Modal(collapseElement, {
					toggle: false
				});
				collapseInstance.show();
			}
		},
		stopCameraScanner(){
			this.cameraService.stopCamera()
		}

	}
}
</script>
